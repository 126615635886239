// header.component.ts
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService, User } from '../../services/auth.service'; 
import { UserService } from '../../services/user.service';

declare let $: any;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'iconify-icon': any;
    }
  }
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isDarkMode = false;
  currentUser: User | null = null;
  userProfileImageUrl: string | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/public/login']);
  }

  ngOnInit() {
    this.authService.user.subscribe(user => {
      this.currentUser = user;
    });
   
    // Load saved theme preference
    const savedTheme = localStorage.getItem('theme');
    this.isDarkMode = savedTheme === 'dark';
    document.documentElement.setAttribute('data-theme', savedTheme || 'light');
    this.loadUserProfileImage();
  }

  ngAfterViewInit() {
    this.initializeSidebar();
  }

  private initializeSidebar(): void {
    // Initialize jQuery functionality when DOM is ready
    $(document).ready(() => {
      // Sidebar toggle
      $(".sidebar-toggle").on("click", (event: Event) => {
        const target = $(event.currentTarget);
        target.toggleClass("active");
        $(".sidebar").toggleClass("active");
        $(".dashboard-main").toggleClass("active");
      });

      // Mobile sidebar toggle
      $(".sidebar-mobile-toggle").on("click", (event: Event) => {
        $(".sidebar").addClass("sidebar-open");
        $("body").addClass("overlay-active");
      });

      // Close sidebar
      $(".sidebar-close-btn").on("click", (event: Event) => {
        $(".sidebar").removeClass("sidebar-open");
        $("body").removeClass("overlay-active");
      });
    });
  }

  toggleTheme() {
    this.isDarkMode = !this.isDarkMode;
    const newTheme = this.isDarkMode ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  }

  get themeIcon(): string {
    return this.isDarkMode ? 'ph:sun-bold' : 'ph:moon-bold';
  }

  loadUserProfileImage(): void {
    this.userService.getUserProfileImage().subscribe({
      next: (response: { profileImage: string }) => {
        this.userProfileImageUrl = response.profileImage;
      },
      error: (error) => {
        console.error('Error loading user profile image:', error);
        // Optionally handle the error, e.g., by setting a default image
      }
    });
  }
}