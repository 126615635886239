// src/app/interceptors/auth.interceptor.ts

import { inject } from '@angular/core';
import { 
  HttpRequest,
  HttpHandlerFn,
  HttpErrorResponse,
  HttpInterceptorFn
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  
  // Check if the current route is a registration route
  const isRegistrationRoute = router.url.includes('/register');
  
  // If it's a registration route, skip token check
  if (isRegistrationRoute) {
    console.log ('In the interceptor isRegistrationRoute true')
    console.log('In the interceptor Request:', request);
    return next(request);    
  }

  const token = authService.token;
  console.log('Token in the interceptor:', token);
  if (token) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if ([401, 403, 422].includes(error.status) && !request.url.includes('/auth/verify-password')) {
        authService.logout();
        router.navigate(['/public/login']);
      }
      return throwError(() => error);
    })
  );
};