// src/app/shared/company-details/company-details.component.ts

import { Component, OnInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HeaderComponent } from '../header/header.component';
import { countries, Country } from '../../shared/data/countries';
import { userInfo } from 'os';
import { CompanyService } from '../../services/company.service';


interface CompanySize {
  id: string;
  label: string;
  range: string;
}

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent
  ]
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
  companyForm: FormGroup;
  isSubmitting = false;
  errorMessage = '';
  private stateSub?: Subscription;
  maxTeamMembers = 10; // Enterprise plan limit
  showBillingForm = false; // State to control form visibility
  showTeamMemberForm = false; // State to control team member form visibility
  showCompanyForm = true; // State to control company form visibility
  countries: Country[] = countries;
  filteredCountries: Country[] = countries;
  filteredBillingCountries: Country[] = countries;


  // Update price constants
  private readonly MONTHLY_BASE_PRICE = 99;
  private readonly YEARLY_BASE_PRICE = 990;
  private readonly MONTHLY_ADDITIONAL_MEMBER_PRICE = 99;
  private readonly YEARLY_ADDITIONAL_MEMBER_PRICE = 990;
  
  // Add billing cycle property
  billingCycle: 'monthly' | 'yearly' = 'monthly';

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.companyForm = this.createForm();
    this.addTeamMember(); // Initialize with one empty team member
  }

  ngOnInit(): void {
    // Get billing cycle from query params
    this.route.queryParams.subscribe(params => {
      console.log('CompanyDetails: Received query params:', params);
      this.billingCycle = params['billingCycle'] || 'monthly';
      console.log('CompanyDetails: Set billing cycle to:', this.billingCycle);
    });

    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country: [''],
      state: [''],
      city: [''],
      website: ['', Validators.required],
      billingName: ['', Validators.required],
      billingAddress: ['', Validators.required],
      vatNumber: ['', Validators.required],
      billingCountry: ['', Validators.required],
      billingState: [''],
      billingCity: [''],
      teamMembers: this.fb.array([])
    });
  }

  ngOnDestroy(): void {
    this.stateSub?.unsubscribe();
  }

  onCountrySearch(event: Event, fieldName: 'country' | 'billingCountry'): void {
    const searchTerm = (event.target as HTMLInputElement).value.toLowerCase();
    
    if (fieldName === 'country') {
      this.filteredCountries = this.countries.filter(country =>
        country.name.toLowerCase().includes(searchTerm)
      );
    } else {
      this.filteredBillingCountries = this.countries.filter(country =>
        country.name.toLowerCase().includes(searchTerm)
      );
    }
  }

  private createForm(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      size: ['', Validators.required],
      industry: ['', Validators.required],
      teamMembers: this.fb.array([])
    });
  }

  get teamMembers() {
    return this.companyForm.get('teamMembers') as FormArray;
  }

  addTeamMember(email: string = '', name: string = '', surname: string = '', title: string = '') {
    if (this.teamMembers.length < this.maxTeamMembers) {
      const memberGroup = this.fb.group({
        email: [email, [Validators.required, Validators.email]],
        name: [name, [Validators.required, Validators.minLength(2)]],
        surname: [surname, [Validators.required, Validators.minLength(2)]],
        title: [title, Validators.required],
      });
      this.teamMembers.push(memberGroup);
    }
  }

  removeTeamMember(index: number) {
    this.teamMembers.removeAt(index);
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.companyForm.get(fieldName);
    return !!(control && control.invalid && (control.dirty || control.touched));
  }

  getErrorMessage(fieldName: string): string {
    const control = this.companyForm.get(fieldName);
    if (control && control.errors) {
      if (control.errors['required']) {
        return `${fieldName === 'billingCountry' ? 'Billing Country' : fieldName} is required`;
      }
      if (control.errors['email']) return 'Please enter a valid email address';
      if (control.errors['minlength']) return `${fieldName} must be at least ${control.errors['minlength'].requiredLength} characters`;
      if (control.errors['pattern']) return 'Please enter a valid website URL';
    }
    return '';
  }

  isTeamMemberEmailInvalid(index: number): boolean {
    const email = this.teamMembers.at(index).get('email');
    return email ? (email.invalid && (email.dirty || email.touched)) : false;
  }

  isTeamMemberRoleInvalid(index: number): boolean {
    const role = this.teamMembers.at(index).get('role');
    return role ? (role.invalid && (role.dirty || role.touched)) : false;
  }

  resetForm(): void {
    this.companyForm.reset();
    while (this.teamMembers.length) {
      this.teamMembers.removeAt(0);
    }
    this.addTeamMember(); // Add one empty team member
  }

  goBack(): void {
    this.router.navigate(['/register/verify']);
  }

  onSubmit(): void {
    if (this.companyForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.errorMessage = '';

      const companyDetails = {
        name: this.companyForm.value.name,
        size: this.companyForm.value.size,
        industry: this.companyForm.value.industry,
        userEmails: this.teamMembers.value.map((member: any) => ({
          email: sessionStorage.getItem,
          name: member.name,
          surname: member.surname,
          title: member.title,
          role: member.role
        }))
      };

      // Handle company details submission logic
      console.log('Company Details Submitted:', companyDetails);

      // Show the team member form
      this.showTeamMemberForm = true;
    } else {
      // Show validation errors
      Object.keys(this.companyForm.controls).forEach(key => {
        const control = this.companyForm.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
    }
  }

  isFirstFormValid(): boolean {
    const nameValid = this.companyForm.get('name')!.valid;
    const emailValid = this.companyForm.get('email')!.valid;
    const cityValid = this.companyForm.get('city')!.valid;
    const websiteValid = this.companyForm.get('website')!.valid;


    return nameValid && emailValid && cityValid && websiteValid;
  }

  isSecondFormValid(): boolean {
    const billingNameValid = this.companyForm.get('billingName')!.valid;
    const billingAddressValid = this.companyForm.get('billingAddress')!.valid;
    const vatNumberValid = this.companyForm.get('vatNumber')!.valid;
    const billingCountryValid = this.companyForm.get('billingCountry')!.valid;


    return billingNameValid && billingAddressValid && vatNumberValid && billingCountryValid;
  }

  onNextButtonClick(): void {
    console.log('Next button clicked');
    if (this.isFirstFormValid()) {
      console.log('First form is valid, proceeding to billing form');
      this.showBillingForm = true;
      this.showCompanyForm = false;
    } else {
      console.log('First form is invalid, marking all as touched');
      this.companyForm.markAllAsTouched();
    }
  }

  onSaveButtonClick(): void {
    console.log('Save button clicked');
    if (this.isSecondFormValid()) {
      console.log('Second form is valid, proceeding to team member form');
      this.showTeamMemberForm = true;
      this.showBillingForm = false;
    } else {
      console.log('Second form is invalid, marking all as touched');
      this.companyForm.markAllAsTouched();
    }
  }

  showCompanyFormSection(): void {
    this.showCompanyForm = true;
    this.showBillingForm = false;
    this.showTeamMemberForm = false;
  }

  showBillingFormSection(): void {
    this.showBillingForm = true;
    this.showCompanyForm = false;
    this.showTeamMemberForm = false;
  }

  calculateTotalPrice(): number {
    const basePrice = this.billingCycle === 'monthly' ? this.MONTHLY_BASE_PRICE : this.YEARLY_BASE_PRICE;
    const additionalMemberPrice = this.billingCycle === 'monthly' ? 
      this.MONTHLY_ADDITIONAL_MEMBER_PRICE : 
      this.YEARLY_ADDITIONAL_MEMBER_PRICE;
    
    // Calculate additional members (if any exist)
    const additionalMembers = Math.max(0, this.teamMembers.length - 1);
    const totalPrice = basePrice + (additionalMembers * additionalMemberPrice);
    
    return totalPrice;
  }

  proceedToPayment(): void {
    if (this.companyForm.valid) {
      try {
        console.log('CompanyDetails: Starting payment with billing cycle:', this.billingCycle);
        const companyDetails = {
          company: {
            name: this.companyForm.get('name')?.value,
            email: this.companyForm.get('email')?.value,
            website: this.companyForm.get('website')?.value,
            country: this.companyForm.get('country')?.value,
            city: this.companyForm.get('city')?.value,
            state: this.companyForm.get('state')?.value
          },
          billing: {
            name: this.companyForm.get('billingName')?.value,
            address: this.companyForm.get('billingAddress')?.value,
            country: this.companyForm.get('billingCountry')?.value,
            city: this.companyForm.get('billingCity')?.value,
            state: this.companyForm.get('billingState')?.value,
            postalCode: this.companyForm.get('postalCode')?.value,
            vatNumber: this.companyForm.get('vatNumber')?.value
          },
          teamMembers: this.teamMembers.value || [] // Make team members optional
        };

        sessionStorage.setItem('companyDetails', JSON.stringify(companyDetails));
        
        const queryParams = {
          plan: 'enterprise',
          billingCycle: this.billingCycle,
          teamMembers: this.teamMembers.length
        };
        
        console.log('CompanyDetails: Navigating to payment with params:', queryParams);
        
        this.router.navigate(['/app/payment'], { queryParams });
      } catch (error) {
        console.error('Error in proceedToPayment:', error);
      }
    } else {
      this.markFormGroupTouched(this.companyForm);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  submitForm(formData: any) {
    if (this.formIsValid(formData)) {
      this.errorMessage = ''; // Clear previous errors
      this.isSubmitting = true;
    } else {
      this.errorMessage = 'Form is invalid';
    }
  }

  formIsValid(formData: any): boolean {
    // Implement your form validation logic here
    return true; // Placeholder
  }
}