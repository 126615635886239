// src/app/integrations/jira-callback.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of, throwError } from 'rxjs';


interface AuthResponse {
  success: boolean;
  message?: string;
  error?: string;
  cloudId?: string;
  accessToken?: string;
}

@Component({
  selector: 'app-jira-callback',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="d-flex min-vh-100 align-items-center justify-content-center bg-light p-4">
      <div class="card shadow-sm w-100" >
        <div class="card-body text-center p-4">
          <h4 class="mb-4">{{ message }}</h4>
          @if (error) {
            <div class="alert alert-danger mb-4">{{ error }}</div>
          }
          @if (isLoading) {
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Processing...</span>
            </div>
          }
        </div>
      </div>
    </div>
  `
})
export class JiraCallbackComponent implements OnInit, OnDestroy {
  message = 'Completing Authentication...';
  error = '';
  isLoading = true;
  private destroy$ = new Subject<void>();
  private readonly REDIRECT_DELAY = 3000;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.processAuthCallback();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private processAuthCallback(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: params => {
          const { code, state } = params;

          if (!this.validateCallback(code, state)) {
            return;
          }

          this.exchangeCodeForToken(code);
        },
        error: error => {
          console.error('Error processing callback params:', error);
          this.handleError('Failed to process authentication callback');
        }
      });
  }

  private validateCallback(code?: string, state?: string): boolean {
    if (!code) {
      this.handleError('Authentication code not received');
      return false;
    }

    const savedState = localStorage.getItem('jiraAuthState');
    if (state !== savedState) {
      this.handleError('Invalid state parameter');
      return false;
    }

    return true;
  }

  private exchangeCodeForToken(code: string): void {
    this.callAuthEndpoint(code)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (response) => this.handleAuthSuccess(response),
        error: (error) => this.handleAuthError(error)
      });
  }

  private callAuthEndpoint(code: string): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(
      `${environment.apiUrl}/api/jira/auth/callback`,
      { code }
    ).pipe(
      catchError(this.handleHttpError)
    );
  }

  private handleAuthSuccess(response: AuthResponse): void {
    if (!response?.success) {
      this.handleError(response?.error || 'Authentication failed');
      return;
    }

    this.message = 'Authentication Successful!';

    // Handle popup scenario
    if (window.opener && !window.opener.closed) {
      try {
        // Post message to parent window
        window.opener.postMessage({
          type: 'jira_auth_success',
          cloudId: response.cloudId,
          accessToken: response.accessToken
        }, window.location.origin);

        // Close popup after a short delay
        setTimeout(() => window.close(), 1000);
      } catch (error) {
        console.error('Error posting message to opener:', error);
        this.cleanupAndRedirect();
      }
    } else {
      // Handle redirect scenario
      this.cleanupAndRedirect();
    }
  }

  private handleAuthError(error: HttpErrorResponse): void {
    console.error('Authentication error:', error);
    this.handleError('Failed to complete authentication');
  }

  private handleHttpError = (error: HttpErrorResponse): Observable<never> => {
    let errorMessage = 'An error occurred during authentication';
    
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.error?.message || error.message;
    }

    return throwError(() => new Error(errorMessage));
  };

  private handleError(message: string): void {
    this.error = message;
    this.message = 'Authentication Failed';
    this.isLoading = false;
    
    if (window.opener) {
      setTimeout(() => window.close(), this.REDIRECT_DELAY);
    } else {
      setTimeout(() => this.cleanupAndRedirect(), this.REDIRECT_DELAY);
    }
  }

  private cleanupAndRedirect(): void {
    // Clean up stored state
    localStorage.removeItem('jiraAuthState');
    const returnUrl = localStorage.getItem('jiraReturnUrl');
    localStorage.removeItem('jiraReturnUrl');

    // Redirect
    this.router.navigate([returnUrl || '/integrations']).then(() => {
      console.log('Redirected to:', returnUrl || '/integrations');
    }).catch(error => {
      console.error('Navigation error:', error);
    });
  }
}