// src/app/guards/auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log('AuthGuard: Checking route', state.url);

    // Skip check for public routes
    if (state.url.includes('/public')) {
      console.log('AuthGuard: Registration route detected, skipping check');
      return true;
    }

    const user = this.authService.userValue;
    
    if (user) {
      console.log('AuthGuard: User is authenticated, allowing access');
      return true;
    }

    console.log('AuthGuard: User not authenticated, redirecting to login');
    this.router.navigate(['/public/login'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
}