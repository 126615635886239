<button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
  <iconify-icon icon="ph:dots-three-outline-vertical-fill"></iconify-icon>
</button>

<mat-menu #menu="matMenu" class="custom-menu">
  <div (click)="$event.stopPropagation()">
    <!-- Copy option -->
    <button mat-menu-item (click)="handleCopy()">
      <iconify-icon icon="ph:copy"></iconify-icon>
      Copy
    </button>

    <!-- Rename section -->
    <div *ngIf="!isRenaming">
      <button mat-menu-item (click)="toggleRename()">
        <iconify-icon icon="ph:pencil-simple-line"></iconify-icon>
        Rename
      </button>
    </div>

    <div *ngIf="isRenaming" class="p-2">
      <input 
        #renameInput
        [(ngModel)]="newTitle"
        class="form-control mb-2"
        (keyup.enter)="confirmRename()"
        (keyup.esc)="cancelRename()"
      >
      <div class="d-flex justify-content-end gap-2">
        <button mat-button (click)="cancelRename()">Cancel</button>
        <button mat-button color="primary" (click)="confirmRename()">OK</button>
      </div>
    </div>

    <!-- Delete option -->
    <button mat-menu-item class="text-danger" (click)="handleDelete()">
      <iconify-icon icon="ph:trash"></iconify-icon>
      Delete
    </button>
  </div>
</mat-menu>