// src/app/scrum-team-agent/scrum-team-agent.component.ts


import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { HeaderComponent } from '../shared/header/header.component';
import { ChatHistoryService } from '../services/chat-history.service';
import { ChatService } from '../services/chat.service';
import { TextFormatterService } from '../services/text-formatter.service';
import { D3DiagramService } from '../services/d3-diagram.service';
import { SafeHtml } from '@angular/platform-browser';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { forkJoin } from 'rxjs';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { ChangeDetectorRef } from '@angular/core';
import { TeamMetrics, DevOpsMetrics, MetricsContext, DEFAULT_TEAM_METRICS, DEFAULT_DEVOPS_METRICS } from '../interfaces/metrics.interface';
import { JiraService } from '../services/jira.service';
import { SprintInfo } from '../interfaces/sprint.interface';
import { ChatMenuComponent } from '../shared/chat-menu/chat-menu.component';
import { VelocityTagsComponent } from '../shared/velocity-tags/velocity-tags.component';
import { AuthService, User } from '../services/auth.service';
import { AuthorizationService } from '../services/authorization.service';
import { FooterComponent } from '../shared/footer/footer.component';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';
import { 
  ChatMessage, 
  ChatResponse, 
  ChatSession, 
  GroupedSessions, 
  RenameEvent,
  CausalLoopData,
  CausalLoopResponse,
  Node,
  Link
} from '../interfaces/chat.interface';
import { JiraConnectResponse, JiraBoardResponse, JiraBoard } from '../interfaces/jira.interfaces';
import { VelocityResponse } from '../interfaces/jira.interfaces';

export interface Board {
  id: number;
  name: string;
  type: string;
  // Add other board properties as needed
}

export interface BoardResponse {
  success: boolean;
  boards: Board[];
  error?: string;
}

type ExportNotificationType = 'success' | 'error';

@Component({
  selector: 'app-scrum-team-agent',
  templateUrl: './scrum-team-agent.component.html',
  styleUrls: ['./scrum-team-agent.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, HeaderComponent, ChatMenuComponent, VelocityTagsComponent, FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      state('true', style({
        maxHeight: '1000px', // Adjust this based on your content
        opacity: 1,
        visibility: 'visible'
      })),
      state('false', style({
        maxHeight: '0px',
        opacity: 0,
        visibility: 'hidden'
      })),
      transition('true => false', [animate('300ms ease-out')]),
      transition('false => true', [animate('300ms ease-in')])
    ])
  ]
})
export class ScrumTeamAgentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('diagramContainer') diagramContainer!: ElementRef;
  boards: JiraBoard[] = [];
  
  // Component Properties
  isTeamMetricsPanelOpen: boolean = false;
  isDevOpsMetricsPanelOpen: boolean = false;
  sidebarOpen = false;
  canShowDifferentApproach: boolean = false;
  lastUserMessage: string = '';
  lastAssistantMessage: string = '';
  messages: ChatMessage[] = [];
  newMessage: string = '';
  isLoading: boolean = false;
  chatSessions: ChatSession[] = [];
  currentChat: ChatSession | null = null;
  groupedSessions: GroupedSessions = {
    today: [],
    yesterday: [],
    older: []
  };
  causalLoopData: CausalLoopData | null = null;
  isLoadingDiagram: boolean = false;
  isExporting: boolean = false;
  exportType: string = '';
  userProfileImageUrl: string | null = null;

// properties for metrics
teamMetrics: TeamMetrics = DEFAULT_TEAM_METRICS;
devOpsMetrics: DevOpsMetrics = DEFAULT_DEVOPS_METRICS;

  selectedBoardId?: string;
  sprints: SprintInfo[] = [];
  currentSprint?: SprintInfo;
  isJiraConnected: boolean = false;
  jiraConnectionError: string = '';

  // Constants
  private readonly NOTIFICATION_DURATION = 3000;
  private readonly EXPORT_SCALE = 2;
  private readonly PDF_FORMAT = 'a4';
  private readonly apiUrl = environment.apiUrl;
  canAccess = false;
  featureUsageCount = 0;
  featureLimit?: number;
  needsReconnect: boolean = false;

  // Add new property for projects
  jiraProjects: any[] = [];
  selectedProject?: string;
  projectBoards: JiraBoard[] = [];
  selectedBoard?: string;
  boardMetrics: VelocityResponse | null = null;
  isLoadingMetrics = false;

  // Add new properties
  isJiraProjectsPanelOpen: boolean = false;
  username: string = '';

  isBoardMetricsPanelOpen: boolean = true;

  constructor(
    private chatService: ChatService,
    private chatHistoryService: ChatHistoryService,
    private textFormatter: TextFormatterService,
    private d3Service: D3DiagramService,
    private cdr: ChangeDetectorRef,
    private jiraService: JiraService,
    private elementRef: ElementRef,
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private userService: UserService
  ) {}

  navigateToUpgrade(): void {
    const currentUserType = this.authService.getUserType();
    this.router.navigate(['/upgrade-plan'], {
      queryParams: {
        current: currentUserType,
        returnUrl: this.router.url
      }
    });
  }

  ngOnInit(): void {
    console.log('Initializing ScrumTeamAgent component...');
    
    // First check if user is authenticated
    if (!this.authService.isAuthenticated()) {
        console.log('User not authenticated, redirecting to login');
        this.router.navigate(['/login']);
        return;
    }

    // Load permissions
    this.authorizationService.loadUserPermissions().subscribe({
        next: () => {
            console.log('Permissions loaded successfully');
            const userType = this.authService.getUserType();
            console.log('Current user type:', userType);

            if (userType) {
                this.canAccess = this.authorizationService.canAccessFeature('scrum-team-agent');
                console.log('Can access scrum-team-agent:', this.canAccess);

                if (this.canAccess) {
                    this.loadChatHistory();
                    this.isExporting = false;
                    this.checkJiraConnection();
                    this.featureLimit = this.authorizationService.getFeatureLimit('scrum-team-agent');
                } else {
                    console.warn('User does not have access to scrum-team-agent');
                }
            } else {
                console.error('No user type found');
                this.router.navigate(['/login']);
            }
        },
        error: (err) => {
            console.error('Failed to load permissions:', err);
            this.router.navigate(['/login']);
        }
    });
    this.loadUserProfileImage();
    this.loadUserName();
}
  useFeature() {
    if (this.featureLimit && this.featureUsageCount >= this.featureLimit) {
      this.router.navigate(['/upgrade-plan']);
      return;
    }
    // Feature implementation
    this.featureUsageCount++;
  }
  private checkJiraConnection(): void {
    console.log('Checking Jira connection...');
    this.jiraConnectionError = ''; // Clear error message when checking connection
    this.jiraService.checkConnection().subscribe({
      next: (response) => {
        this.isJiraConnected = response.success && response.isConnected;
        if (this.isJiraConnected) {
          console.log('Jira connected, loading projects...');
          this.loadJiraProjects();
        } else {
          console.log('Jira not connected');
          // Don't set error message when not connected - it's a valid state
          this.jiraProjects = [];
          this.selectedBoardId = undefined;
          this.sprints = [];
          this.currentSprint = undefined;
        }
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error('Error checking Jira connection:', error);
        this.isJiraConnected = false;
        this.jiraConnectionError = error.error?.message || 'Unable to connect to Jira';
        this.cdr.detectChanges();
      }
    });
  }

  private loadJiraProjects(): void {
    if (!this.isJiraConnected) {
      console.log('Jira not connected, skipping project load');
      return;
    }

    console.log('Loading Jira projects...');
    this.jiraService.getProjects().subscribe({
      next: (response) => {
        if (response.success) {
          this.jiraProjects = response.projects;
          this.jiraConnectionError = '';
          console.log('Loaded Jira projects:', this.jiraProjects);
        } else {
          console.warn('Failed to load Jira projects:', response.error);
          this.jiraConnectionError = response.error || 'Failed to load projects';
        }
      },
      error: (error) => {
        console.error('Error loading Jira projects:', error);
        this.jiraConnectionError = error.error?.error || 'Failed to load projects';
      }
    });
  }

  disconnectJira(): void {
    console.log('Attempting to disconnect from Jira...');
    this.jiraService.disconnect().subscribe({
      next: (response) => {
        if (response.success) {
          // Reset all Jira-related state
          this.isJiraConnected = false;
          this.jiraProjects = [];
          this.selectedBoardId = undefined;
          this.sprints = [];
          this.currentSprint = undefined;
          this.jiraConnectionError = '';
          this.needsReconnect = false;
          
          // Force UI update
          this.cdr.detectChanges();
          console.log('Successfully disconnected from Jira');
        } else {
          this.jiraConnectionError = response.error || 'Failed to disconnect from Jira';
          console.error('Failed to disconnect from Jira:', response.error);
          this.cdr.detectChanges();
        }
      },
      error: (error) => {
        console.error('Error disconnecting from Jira:', error);
        this.jiraConnectionError = error.error?.error || 'Failed to disconnect from Jira';
        this.cdr.detectChanges();
      },
      complete: () => {
        // Optionally refresh the connection status after a short delay
        setTimeout(() => {
          this.checkJiraConnection();
        }, 100);
      }
    });
  }

  private showSuccessMessage(message: string): void {
    this.jiraConnectionError = '';
  }

  ngAfterViewInit(): void {
    if (this.causalLoopData) {
      this.renderDiagram();
    }
  }

  ngOnDestroy(): void {
    this.cleanup();
  }

  toggleSidebar(): void {
    this.sidebarOpen = !this.sidebarOpen;
  }

  loadChatHistory(): void {
    if (!this.authorizationService.hasAccessToChatHistory()) {
      return;
    }
  
    this.chatHistoryService.getChatSessions().subscribe({
      next: (sessions: ChatSession[]) => {
        this.chatSessions = sessions;
        this.groupChatsByDate(sessions);
      },
      error: (error) => {
        console.error('Error loading chat history:', error);
      }
    });
  }

loadChat(sessionId: number): void {
  console.log(`Loading chat session ${sessionId}`);
  const session = this.chatSessions.find(s => s.id === sessionId);
  
  if (!session) {
    console.error(`Session ${sessionId} not found`);
    return;
  }

  this.currentChat = session;
  console.log('Current chat set to:', this.currentChat);
  
  // Load both chat messages and causal loop diagram
  forkJoin({
    messages: this.chatService.getChatMessages(sessionId),
    diagram: this.chatService.getCausalLoopDiagram(sessionId)
  }).subscribe({
    next: (response) => {
      if (response.messages.success && response.messages.messages) {
        this.messages = response.messages.messages.map(msg => ({
          role: msg.role,
          content: msg.content,
          timestamp: new Date(msg.timestamp),
          formattedContent: this.textFormatter.formatToHTML(msg.content)
        }));
      }
      
      if (response.diagram.success && response.diagram.data) {
        this.causalLoopData = response.diagram.data;
        this.cdr.detectChanges();
        setTimeout(() => {
          if (this.diagramContainer) {
            this.renderDiagram();
          }
        });
      }
    },
    error: (error) => {
      console.error('Error loading chat:', error);
      this.messages = [];
      this.causalLoopData = null;
    }
  });
}

  startNewChat(): void {
    this.messages = [];
    this.currentChat = null;
    this.newMessage = '';
    this.causalLoopData = null;
    this.resetDiagram();
    this.canShowDifferentApproach = false;
    this.lastUserMessage = '';
    this.lastAssistantMessage = '';
    this.resetMetrics();  // Reset metrics when starting new chat
    this.isTeamMetricsPanelOpen = false;
    this.isDevOpsMetricsPanelOpen = false;
  }

  private groupChatsByDate(sessions: ChatSession[]): void {
    const today = new Date().toDateString();
    const yesterday = new Date(Date.now() - 86400000).toDateString();

    this.groupedSessions = {
      today: [],
      yesterday: [],
      older: []
    };

    sessions.forEach(session => {
      const sessionDate = new Date(session.created_at).toDateString();
      if (sessionDate === today) {
        this.groupedSessions.today.push(session);
      } else if (sessionDate === yesterday) {
        this.groupedSessions.yesterday.push(session);
      } else {
        this.groupedSessions.older.push(session);
      }
    });
  }

  sendMessage(): void {
    if (!this.newMessage.trim() || this.isLoading) return;

    this.lastUserMessage = this.newMessage;
    const userMessage: ChatMessage = {
        role: 'user',
        content: this.newMessage,
        timestamp: new Date(),
        formattedContent: this.textFormatter.formatToHTML(this.newMessage)
    };

    // Prepare metrics context
 // Create a partial metrics object with only defined values
 const teamMetricsData: Partial<TeamMetrics> = {};
    
 // Now we can safely access teamMetrics properties
 if (this.teamMetrics.sprintVelocities.length > 0) {
     teamMetricsData.sprintVelocities = [...this.teamMetrics.sprintVelocities];
 }
 if (this.teamMetrics.teamSize !== undefined) {
     teamMetricsData.teamSize = this.teamMetrics.teamSize;
 }
 if (this.teamMetrics.sprintEvents && this.teamMetrics.sprintEvents.length > 0) {
     teamMetricsData.sprintEvents = [...this.teamMetrics.sprintEvents];
 }
 if (this.teamMetrics.changingTeamMember !== undefined) {
     teamMetricsData.changingTeamMember = this.teamMetrics.changingTeamMember;
 }
 if (this.teamMetrics.teamSatisfaction !== undefined) {
     teamMetricsData.teamSatisfaction = this.teamMetrics.teamSatisfaction;
 }
 if (this.teamMetrics.defectDensity !== undefined) {
     teamMetricsData.defectDensity = this.teamMetrics.defectDensity;
 }

 const metricsContext: MetricsContext = {
     teamMetrics: Object.keys(teamMetricsData).length > 0 ? teamMetricsData as TeamMetrics : undefined,
     devOpsMetrics: Object.keys(this.devOpsMetrics).length > 0 ? this.devOpsMetrics : undefined
 };

 // Remove undefined properties
 if (!metricsContext.teamMetrics) {
     delete metricsContext.teamMetrics;
 }
 if (!metricsContext.devOpsMetrics) {
     delete metricsContext.devOpsMetrics;
 }

    this.messages.push(userMessage);
    const messageToSend = this.newMessage;
    this.newMessage = '';
    this.isLoading = true;
    this.isLoadingDiagram = true;

    this.chatService.sendMessageWithDiagram(
        messageToSend,
        this.currentChat?.id,
        this.currentChat?.title || messageToSend.substring(0, 30),
        metricsContext
    ).subscribe({
        next: ([chatResponse, diagramResponse]) => {
            this.handleChatResponse(chatResponse, messageToSend);
            if (diagramResponse.success && diagramResponse.data) {
                this.handleDiagramResponse(diagramResponse);
                
                // Save diagram if session exists
                if (this.currentChat?.id) {
                    this.chatService.saveCausalLoopDiagram(
                        this.currentChat.id,
                        diagramResponse.data
                    ).subscribe({
                        next: (response) => {
                            if (response.success) {
                                console.log('Diagram saved successfully');
                            } else {
                                console.error('Failed to save diagram:', response.error);
                            }
                        },
                        error: (error) => {
                            console.error('Error saving diagram:', error);
                        }
                    });
                }
            }
            if (chatResponse.success && chatResponse.message) {
                this.lastAssistantMessage = chatResponse.message;
                this.canShowDifferentApproach = true;
            }
        },
        error: (error) => {
            console.error('API Error:', error);
            this.handleErrorResponse();
        },
        complete: () => {
            this.isLoading = false;
            this.isLoadingDiagram = false;
        }
    });
}
scrumEvents: string[] = ["Sprint Planning", "Daily Scrum", "Sprint Review", "Sprint Retrospective"];
isDropdownOpen = false;
  
  resetMetrics(): void {
    this.teamMetrics = DEFAULT_TEAM_METRICS;
    this.devOpsMetrics = DEFAULT_DEVOPS_METRICS;
  }

  getDifferentApproach(): void {
    if (!this.lastUserMessage || !this.lastAssistantMessage || this.isLoading) return;
    
    this.isLoading = true;

    // Include metrics context in different approach request
    const metricsContext: MetricsContext = {
      teamMetrics: Object.fromEntries(
        Object.entries(this.teamMetrics).filter(([_, value]) => value !== undefined)
      ) as TeamMetrics,
      devOpsMetrics: Object.fromEntries(
        Object.entries(this.devOpsMetrics).filter(([_, value]) => value !== undefined)
      ) as DevOpsMetrics
    };
    
    this.chatService.sendMessage(
      this.lastUserMessage,
      'different_approach_agent',
      this.currentChat?.id,
      this.lastAssistantMessage,
      metricsContext  // Include the metrics context here
    ).subscribe({
      next: (response) => {
        if (response.success && response.message) {
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: response.message,
            timestamp: new Date(),
            formattedContent: this.textFormatter.formatToHTML(response.message)
          };
          this.messages.push(assistantMessage);
          this.canShowDifferentApproach = false;
        }
      },
      error: (error) => {
        console.error('API Error:', error);
        this.handleErrorResponse();
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  private renderDiagram(): void {
    if (!this.diagramContainer || !this.causalLoopData) {
      console.error('Diagram container or data not found');
      return;
    }

    try {
      this.d3Service.renderDiagram(this.causalLoopData, 'causal-loop-diagram');
    } catch (error) {
      console.error('Error rendering diagram:', error);
      this.showExportNotification('error', 'Failed to render diagram');
    }
  }

  refreshDiagram(): void {
    if (this.causalLoopData && !this.isLoadingDiagram) {
      this.renderDiagram();
    }
  }

  private resetDiagram(): void {
    this.d3Service.cleanup('causal-loop-diagram');
    this.causalLoopData = null;
  }

  async exportAsPDF(): Promise<void> {
    if (!this.diagramContainer || !this.causalLoopData) {
      console.error('Missing required data for PDF export');
      return;
    }

    try {
      this.isExporting = true;
      this.exportType = 'PDF';

      const canvas = await html2canvas(this.diagramContainer.nativeElement, {
        scale: this.EXPORT_SCALE,
        backgroundColor: '#ffffff',
        logging: true,
        useCORS: true,
        allowTaint: true
      });

      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: this.PDF_FORMAT
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        0,
        0,
        pdfWidth,
        pdfHeight
      );

      if (this.causalLoopData?.title) {
        pdf.setFontSize(16);
        pdf.text(this.causalLoopData.title, 20, 20);
      }

      pdf.setFontSize(10);
      pdf.text(`Generated: ${new Date().toLocaleString()}`, 20, pdfHeight - 10);

      pdf.save(`causal-loop-${Date.now()}.pdf`);
      
      this.showExportNotification('success', 'PDF exported successfully!');
    } catch (error) {
      console.error('Error exporting PDF:', error);
      this.showExportNotification('error', 'Failed to export PDF');
    } finally {
      this.isExporting = false;
      this.exportType = '';
    }
  }

  async exportAsImage(): Promise<void> {
    if (!this.diagramContainer) {
      console.error('Diagram container not found');
      return;
    }

    try {
      this.isExporting = true;
      this.exportType = 'Image';

      const canvas = await html2canvas(this.diagramContainer.nativeElement, {
        scale: this.EXPORT_SCALE,
        backgroundColor: '#ffffff',
        logging: true,
        useCORS: true,
        allowTaint: true
      });

      const link = document.createElement('a');
      link.download = `causal-loop-${Date.now()}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
      
      this.showExportNotification('success', 'Image exported successfully!');
    } catch (error) {
      console.error('Error exporting image:', error);
      this.showExportNotification('error', 'Failed to export image');
    } finally {
      this.isExporting = false;
      this.exportType = '';
    }
  }

  private handleChatResponse(response: ChatResponse, originalMessage: string): void {
    if (response.success && response.message) {
      const assistantMessage: ChatMessage = {
        role: 'assistant',
        content: response.message,
        timestamp: new Date(),
        formattedContent: this.textFormatter.formatToHTML(response.message)
      };
      this.messages.push(assistantMessage);
      
      if (response.session_id && !this.currentChat) {
        this.currentChat = {
          id: response.session_id,
          title: originalMessage.substring(0, 30),
          created_at: new Date().toISOString(),
          agent_type: 'scrum_team_agent',
          last_message: originalMessage
        };
        this.loadChatHistory();
      }
    }
  }

  private handleDiagramResponse(response: CausalLoopResponse): void {
    if (response.success && response.data) {
      this.causalLoopData = response.data;
      this.isExporting = false;
      this.cdr.detectChanges();
      
      setTimeout(() => {
        if (this.diagramContainer) {
          this.renderDiagram();
        }
      });
    } else if (response.error) {
      console.error('Causal loop diagram error:', response.error);
    }
  }

  private handleErrorResponse(): void {
    const errorMessage: ChatMessage = {
      role: 'assistant',
      content: 'Sorry, I encountered an error. Please try again.',
      timestamp: new Date(),
      formattedContent: this.textFormatter.formatToHTML(
        'Sorry, I encountered an error. Please try again.'
      )
    };
    this.messages.push(errorMessage);
  }

  // Utility Methods
  private showExportNotification(type: ExportNotificationType, message: string): void {
    console.log('Showing notification:', type, message);
    const notification = document.createElement('div');
    notification.className = `export-notification ${type}`;
    notification.textContent = message;
    document.body.appendChild(notification);

    setTimeout(() => {
        notification.remove();
    }, this.NOTIFICATION_DURATION);
}

  getDisplayMessage(session: ChatSession): string {
    const baseMessage = session.last_message || 'New Chat';
    return baseMessage.length > 50 ? `${baseMessage.slice(0, 50)}...` : baseMessage;
  }

  getChatTitle(chat: ChatSession | null): string {
    if (!chat) return 'New Chat';
    return chat.title || chat.last_message || 'Chat';
  }

  formatDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }

  private cleanup(): void {
    this.d3Service.cleanup('causal-loop-diagram');
  }

  checkExportButtonVisibility(): boolean {
    console.log('Checking visibility:', {
        hasData: !!this.causalLoopData,
        isNotExporting: !this.isExporting
    });
    return !!this.causalLoopData && !this.isExporting;
}
hasMetricsData(): boolean {
  const hasTeamMetrics = Object.values(this.teamMetrics).some(value => value !== undefined);
  const hasDevOpsMetrics = Object.values(this.devOpsMetrics).some(value => value !== undefined);
  return hasTeamMetrics || hasDevOpsMetrics;
}
// Add these methods:
toggleTeamMetricsPanel(): void {
  this.isTeamMetricsPanelOpen = !this.isTeamMetricsPanelOpen;
}

toggleDevOpsMetricsPanel(): void {
  this.isDevOpsMetricsPanelOpen = !this.isDevOpsMetricsPanelOpen;
}

deleteSession(sessionId: number): void {
  this.chatService.deleteChatSession(sessionId).subscribe({
      next: (response) => {
          if (response.success) {
              // If current chat is deleted, start a new chat
              if (this.currentChat?.id === sessionId) {
                  this.startNewChat();
              }
              // Refresh chat history
              this.loadChatHistory();
          }
      },
      error: (error) => {
          console.error('Error deleting session:', error);
          // You might want to show an error message to the user
      }
  });
}

handleRename(event: RenameEvent): void {
  const { sessionId, newTitle } = event;
  console.log('Rename event received:', { sessionId, newTitle });
  this.renameSession(sessionId, newTitle);
}

renameSession(sessionId: number, newTitle: string): void {
  console.log('Starting rename session:', { sessionId, newTitle });
  
  if (!sessionId || !newTitle?.trim()) {
    console.error('Invalid rename parameters:', { sessionId, newTitle });
    return;
  }

  this.chatService.renameChatSession(sessionId, newTitle)
    .subscribe({
      next: (response) => {
        console.log('Rename response:', response);
        if (response.success) {
          if (this.currentChat && this.currentChat.id === sessionId) {
            this.currentChat = {
              ...this.currentChat,
              title: newTitle
            };
          }
          this.loadChatHistory();
        }
      },
      error: (error) => console.error('Rename error:', error)
    });
}

copySession(sessionId: number): void {
  this.chatService.getSessionDataForCopy(sessionId).subscribe({
    next: (response) => {
      if (response.success && response.data) {
        // Start new chat (clear current session)
        this.startNewChat();
        
        // Set metrics if they exist
        response.data.metrics.forEach(metric => {
          if (metric.metrics_type === 'scrum') {
            this.teamMetrics = metric.metrics_data;
            this.isTeamMetricsPanelOpen = true;  // Open the panel to show copied metrics
          } else if (metric.metrics_type === 'devops') {
            this.devOpsMetrics = metric.metrics_data;
            this.isDevOpsMetricsPanelOpen = true;  // Open the panel to show copied metrics
          }
        });
        
        // Set the message in the input field
        this.newMessage = response.data.message;
        
        // Optionally scroll to the input area
        this.scrollToMessageInput();
        
        // Notify user
        this.showNotification('Content copied! Review and submit when ready.');
      }
    },
    error: (error) => {
      console.error('Error copying session:', error);
      this.showNotification('Failed to copy content', 'error');
    }
  });
}

private scrollToMessageInput(): void {
  // Add this method if you want to scroll to the input area after copying
  setTimeout(() => {
    const inputElement = document.querySelector('.message-input');
    inputElement?.scrollIntoView({ behavior: 'smooth' });
  }, 100);
}

private showNotification(message: string, type: 'success' | 'error' = 'success'): void {
  // Implement this method based on your notification system
  console.log(`${type}: ${message}`);
  // For example:
  // this.notificationService.show(message, type);
}


toggleDropdown() {
  this.isDropdownOpen = !this.isDropdownOpen;
}

closeDropdown() {
  this.isDropdownOpen = false;
}

isEventSelected(event: string): boolean {
  return this.teamMetrics.sprintEvents?.includes(event) || false;
}

toggleEvent(event: string) {
  if (!this.teamMetrics.sprintEvents) {
    this.teamMetrics.sprintEvents = [];
  }
  
  const index = this.teamMetrics.sprintEvents.indexOf(event);
  if (index === -1) {
    this.teamMetrics.sprintEvents.push(event);
  } else {
    this.teamMetrics.sprintEvents.splice(index, 1);
  }
}

getSelectedEventsText(): string {
  if (!this.teamMetrics.sprintEvents || this.teamMetrics.sprintEvents.length === 0) {
    return 'Select Sprint Events';
  }
  if (this.teamMetrics.sprintEvents.length === 1) {
    return this.teamMetrics.sprintEvents[0];
  }
  return `${this.teamMetrics.sprintEvents.length} events selected`;
}

@HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
  if (!this.elementRef.nativeElement.contains(event.target)) {
    this.closeDropdown();
  }
}

// Add method to handle velocity updates
updateSprintVelocities(velocities: number[]) {
  console.log('Updating velocities:', velocities); // Debug log
  this.teamMetrics.sprintVelocities = [...velocities]; // Create new array reference
  // Force change detection if needed
  this.cdr.detectChanges();
}

loadUserProfileImage(): void {
  this.userService.getUserProfileImage().subscribe({
    next: (response: { profileImage: string }) => {
      this.userProfileImageUrl = response.profileImage;
    },
    error: (error) => {
      console.error('Error loading user profile image:', error);
      // Optionally handle the error, e.g., by setting a default image
    }
  });
}

onProjectSelect(event: any): void {
  const projectId = event.target.value;
  if (projectId) {
    // Find the selected project to get its key
    const selectedProject = this.jiraProjects.find(p => p.id === projectId);
    if (selectedProject) {
      this.loadProjectBoards(selectedProject.key);
    }
  } else {
    this.projectBoards = [];
    this.selectedBoard = undefined;
  }
}

private loadProjectBoards(projectKey: string): void {
  this.jiraService.getBoardsByProject(projectKey).subscribe({
    next: (response) => {
      if (response.success) {
        this.projectBoards = response.boards;
      } else {
        console.error('Failed to load project boards:', response.error);
      }
    },
    error: (error) => {
      console.error('Error loading project boards:', error);
    }
  });
}

onBoardSelect(event: any): void {
  const boardId = event.target.value;
  if (boardId && this.selectedProject) {
    this.selectedBoard = boardId;
    this.loadBoardMetrics(boardId);
  }
}

private loadBoardMetrics(boardId: string): void {
  console.log('Loading metrics for board:', boardId); // Debug log
  
  if (!this.selectedProject) {
    console.error('No project selected');
    return;
  }

  this.isLoadingMetrics = true;
  this.jiraService.getBoardMetrics(boardId, this.selectedProject).subscribe({
    next: (response) => {
      console.log('Received metrics response:', response); // Debug log
      if (response.success) {
        this.boardMetrics = response;
        console.log('Updated boardMetrics:', this.boardMetrics); // Debug log
        
        // Update team metrics with the new data
        if (response.teamMemberCount) {
          this.teamMetrics.teamSize = response.teamMemberCount;
        }
        if (response.velocityData.length > 0) {
          this.teamMetrics.sprintVelocities = response.velocityData
            .map(sprint => sprint.metrics.velocity)
            .filter(velocity => velocity !== undefined);
        }
      } else {
        console.error('Failed to load board metrics:', response.error);
      }
    },
    error: (error) => {
      console.error('Error loading board metrics:', error);
    },
    complete: () => {
      this.isLoadingMetrics = false;
      console.log('Metrics loading completed'); // Debug log
    }
  });
}

// Add new method to load username
private loadUserName(): void {
  this.authService.user.subscribe((user: User | null) => {
    this.username = user?.name || 'there';
  }); 
}

// Add new toggle method for Jira Projects panel
toggleJiraProjectsPanel(): void {
  this.isJiraProjectsPanelOpen = !this.isJiraProjectsPanelOpen;
}

toggleBoardMetricsPanel(): void {
  this.isBoardMetricsPanelOpen = !this.isBoardMetricsPanelOpen;
}

}