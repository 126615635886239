<div class="dashboard-main">
    <app-header></app-header>  
        
    <div class="container p-24">
        <div class="d-flex flex-wrap align-items-center gap-3 mb-24">
            <h6 class="fw-semibold mb-0">Welcome to your new VDF AI {{planType | titlecase}} Plan</h6>
        </div>
        <p class="text-secondary-light mb-8">Your Order Reference: "{{orderId}}"</p>
        <div>
            <p class="text-secondary-light mb-16">Your Payment Confirmed with Amount: {{amount | currency}}</p>
            <h6 class="text-primary-light">Your comprehensive guide to making the most of your upgraded features</h6>
        </div>
        <nav class="navigation bg-white rounded-8 p-16 mb-24">
            <h6 class="text-primary-light mb-16">Quick Navigation</h6>
            <ul class="list-style">
                <li class="mb-8">
                    <a (click)="scrollToSection('getting-started')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Getting Started
                    </a>
                </li>
                <li class="mb-8">
                    <a (click)="scrollToSection('key-features')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Key Features
                    </a>
                </li>
                <li class="mb-8">
                    <a (click)="scrollToSection('jira-integration')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Jira Integration
                    </a>
                </li>
                <li class="mb-8">
                    <a (click)="scrollToSection('causal-loops')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Causal Loop Diagrams
                    </a>
                </li>
                <li class="mb-8">
                    <a (click)="scrollToSection('metrics-tracking')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Metrics Tracking
                    </a>
                </li>
                <li class="mb-8">
                    <a (click)="scrollToSection('team-collaboration')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Team Collaboration
                    </a>
                </li>
                <li>
                    <a (click)="scrollToSection('best-practices')" class="text-secondary-light hover-text-primary cursor-pointer">
                        Best Practices
                    </a>
                </li>
            </ul>
        </nav>

        <section id="getting-started" class="card rounded-8 p-24 mb-24">
            <div class="mb-24"> 
                <div class="row gy-4">
                    <div class="col-xxl-4 col-sm-6">
                        <div class="card h-100 radius-12">
                            <img src="assets/images/card-component/card-img2.png" class="card-img-top" alt="">
                            <div class="card-body p-16 text-center">
                                <h5 class="card-title text-lg text-primary-light
                                 mb-6">Enhanced Analytics</h5>
                                 <ul>
                                    <li class="text-secondary-light mb-8">Advanced sprint metrics</li>
                                    <li class="text-secondary-light mb-8">Team performance insights</li>
                                    <li class="text-secondary-light mb-8">Custom reporting dashboards</li>
                                </ul>
                                <button class="btn btn-primary-600 px-12 py-10 d-inline-flex align-items-center gap-2"> 
                                    <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-sm-6">
                        <div class="card h-100 radius-12">
                            <img src="assets/images/card-component/card-img3.png" class="card-img-top" alt="">
                            <div class="card-body p-16 text-center">
                                <h5 class="card-title text-lg text-primary-light
                                 mb-6">Collaboration Tool</h5>
                                 <ul>
                                    <li class="text-secondary-light mb-8">Multi-user access</li>
                                    <li class="text-secondary-light mb-8">Team workspaces</li>
                                    <li class="text-secondary-light mb-8">Shared insights</li>
                                </ul>
                                <button class="btn btn-primary-600 px-12 py-10 d-inline-flex align-items-center gap-2"> 
                                    <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-sm-6">
                        <div class="card h-100 radius-12">
                            <img src="assets/images/card-component/card-img4.png" class="card-img-top" alt="">
                            <div class="card-body p-16 text-center">
                                <h5 class="card-title text-lg text-primary-light
                                 mb-6">Integration Capabilities</h5>
                                 <ul>
                                    <li class="text-secondary-light mb-8">Jira synchronization</li>
                                    <li class="text-secondary-light mb-8">Real-time data updates</li>
                                    <li class="text-secondary-light mb-8">Custom API access</li>
                                </ul>
                                <button class="btn btn-primary-600 px-12 py-10 d-inline-flex align-items-center gap-2"> 
                                    <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="key-features" class="card rounded-8 p-24 mb-24">
            <h5>Key Features Overview</h5>
            <h6>Professional Plan Features</h6>
            
            <div class="card-header">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table basic-border-table mb-0">
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Description</th>
                                    <th>Limit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Causal Loop Diagrams</td>
                                    <td>Create and analyze system dynamics</td>
                                    <td>20 per month</td>
                                </tr>
                                <tr>
                                    <td>Different Approaches</td>
                                    <td>Alternative solution suggestions</td>
                                    <td>50 per month</td>
                                </tr>
                                <tr>
                                    <td>Jira Integration</td>
                                    <td>Full access to Jira connectivity</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>Team Support</td>
                                    <td>Collaborative features</td>
                                    <td>Up to 10 users</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>

        <section id="jira-integration" class="card rounded-8 p-24 mb-24">
            <h5>Jira Integration</h5>
            <p class="card-text text-neutral-600 mb-16">Connect VDF AI with your Jira workspace to synchronize data and enhance your Agile workflow:</p>
            <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                
                <div class="d-flex flex-shrink-0 w-170-px h-100">
                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                </div>
                <div class="card-body p-16 flex-grow-1">
                    <h5 class="card-title text-lg text-primary-light mb-6">Setup Instructions</h5>
                    
                        <ol>
                            <li>Navigate to the Integrations page</li>
                            <li>Click on "Connect" under the Jira section</li>
                            <li>Authorize VDF AI in your Atlassian account</li>
                            <li>Select the boards you want to sync</li>
                        </ol>
                    <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                        Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                    </a>
                </div>
            </div>
            
            

            <div class="tip">
                <strong>Pro Tip:</strong> After connecting Jira, VDF AI will automatically pull in your sprint metrics and team data to provide more accurate insights and recommendations.
            </div>
        </section>

        <section id="causal-loops" class="card rounded-8 p-24 mb-24">
            <h5>Causal Loop Diagrams</h5>
            <p>Create sophisticated system dynamics visualizations to understand complex relationships in your Agile processes:</p>
             <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                
                <div class="d-flex flex-shrink-0 w-170-px h-100">
                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                </div>
                <div class="card-body p-16 flex-grow-1">
                    <h5 class="card-title text-lg text-primary-light mb-6">Features</h5>
                    
                        <ol>
                            <li>Interactive diagram creation</li>
                        <li>Export capabilities (PDF, PNG)</li>
                        <li>Custom relationship mapping</li>
                        <li>Team sharing and collaboration</li>
                        </ol>
                        <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                            Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                        </a>
                </div>
            </div>
            
            

            <div class="tip">
                <strong>Best Practice:</strong> Use causal loop diagrams to identify systemic issues and improvement opportunities in your sprint processes.
            </div>

        </section>

        <section id="metrics-tracking" class="card rounded-8 p-24 mb-24">
            <h5 class="text-primary-light mb-16">Metrics Tracking</h5>
            <p class="text-secondary-light mb-24">Monitor and analyze key performance indicators to enhance team efficiency and project outcomes.</p>
            
            <h6 class="text-primary-light mb-16">Available Metrics</h6>
            <div class="feature-grid">
                <div class="card height-equal" style="min-height: 200px;">
                    <div class="card-header">
                        <h6 class="text-primary-light mb-0">Team Metrics</h6>
                    </div>
                    <div class="card-body">
                        <ul class="list-style">
                            <li class="text-secondary-light mb-8">Sprint Velocity: Measure the amount of work completed in a sprint.</li>
                            <li class="text-secondary-light mb-8">Team Capacity: Evaluate the team's workload capacity.</li>
                            <li class="text-secondary-light mb-8">Sprint Burndown: Track the remaining work in a sprint.</li>
                            <li class="text-secondary-light mb-8">Task Completion Rate: Assess the rate of task completion.</li>
                            <li class="text-secondary-light mb-8">Team Satisfaction: Gauge team morale and satisfaction.</li>
                            <li class="text-secondary-light mb-8">Defect Density: Identify the number of defects per unit of work.</li>
                        </ul>
                    </div>
                </div>
                <div class="card height-equal" style="min-height: 200px;">
                    <div class="card-header">
                        <h6 class="text-primary-light mb-0">DevOps Metrics</h6>
                    </div>
                    <div class="card-body">
                        <ul class="list-style">
                            <li class="text-secondary-light mb-8">Deployment Frequency: Frequency of successful deployments.</li>
                            <li class="text-secondary-light mb-8">Lead Time: Time taken from code commit to production.</li>
                            <li class="text-secondary-light mb-8">Change Failure Rate: Percentage of changes that fail.</li>
                            <li class="text-secondary-light mb-8">Mean Time to Recover: Time to recover from a failure.</li>
                            <li class="text-secondary-light mb-8">Automation Rate: Extent of automated processes.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section id="team-collaboration" class="card rounded-8 p-24 mb-24">
            <h5>Team Collaboration</h5>
            <p>Maximize team efficiency with collaborative features:</p>
             <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                
                <div class="d-flex flex-shrink-0 w-170-px h-100">
                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                </div>
                <div class="card-body p-16 flex-grow-1">
                    <h5 class="card-title text-lg text-primary-light mb-6">Features</h5>
                    
                        <ol>
                            <li>Shared dashboards and reports</li>
                            <li>Team-wide insights and recommendations</li>
                <li>Collaborative problem-solving sessions</li>
                <li>Custom role-based access controls</li>
                        </ol>
                        <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                            Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                        </a>
                </div>
            </div>
        </section>

        <section id="best-practices" class="card rounded-8 p-24 mb-24">
            <h5>Best Practices</h5>
            <p>Getting the Most from VDF AI</p>
             <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                
                <div class="d-flex flex-shrink-0 w-170-px h-100">
                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                </div>
                <div class="card-body p-16 flex-grow-1">
                    <h5 class="card-title text-lg text-primary-light mb-6">Features</h5>
                    
                        <ol>
                            <li><strong>Regular Updates:</strong> Keep your Jira integration synchronized for accurate insights</li>
                            <li><strong>Team Onboarding:</strong> Ensure all team members complete their profiles and set preferences</li>
                            <li><strong>Metrics Review:</strong> Schedule regular reviews of your team's metrics and insights</li>
                            <li><strong>Feedback Loop:</strong> Use the Different Approaches feature to explore alternative solutions</li>
                            <li><strong>Documentation:</strong> Maintain detailed sprint notes and decision records</li>
                        </ol>
                        <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                            Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                        </a>
                </div>
            </div>
            
            <div class="tip">
                <strong>Support:</strong> Need help? Contact our premium support team at support&#64;vdf.ai for priority assistance.
            </div>
            
        </section>
    </div>
    <footer class="d-footer">
        <div class="row align-items-center justify-content-between">
            <div class="col-auto">
                <p class="mb-0">© 2024 VDF AI. All Rights Reserved.</p>
            </div>
            <div class="col-auto"></div>
        </div>
    </footer>
</div>

    