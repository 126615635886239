// src/app/registration/components/registration-success/registration-success.component.ts

import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { RegistrationStateService } from '../../services/registration-state.service';
import { AuthService } from '../../../services/auth.service';
import { UserType } from '../../../interfaces/user.interface';

@Component({
  selector: 'app-registration-success',
  standalone: true,
  imports: [CommonModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <section class="auth bg-base d-flex flex-wrap">
      <div class="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div class="max-w-464-px mx-auto w-100 text-center">
          <div class="mb-32">
            <a routerLink="/" class="mb-40 max-w-290-px d-inline-block">
              <img src="assets/images/logo.png" alt="VDF.AI">
            </a>
            
            <!-- Success Animation -->
            <div class="success-animation mb-24">
              <img 
                src="assets/images/gif/success-img3.gif" 
                alt="Success" 
                class="gif-image"
                style="max-width: 200px;">
            </div>

            <h4 class="mb-12">Registration Successful!</h4>
            
            <!-- Plan-specific message -->
            <p class="mb-32 text-secondary-light text-lg">
              {{ getSuccessMessage() }}
            </p>

            <!-- Next steps -->
            <div class="next-steps bg-neutral-50 p-24 radius-12 text-left mb-32">
              <h6 class="mb-16">Next Steps:</h6>
              <ul class="list-unstyled">
                <li class="mb-12 d-flex align-items-start gap-3" *ngFor="let step of getNextSteps()">
                  <iconify-icon 
                    icon="fluent:checkmark-circle-12-regular" 
                    class="text-success-600 flex-shrink-0 mt-1">
                  </iconify-icon>
                  <span>{{ step }}</span>
                </li>
              </ul>
            </div>

            <!-- Action buttons -->
            <div class="d-grid gap-3">
              <button 
                (click)="goToDashboard()"
                class="btn btn-primary text-white w-100">
                Go to Dashboard
              </button>
              
              <button 
                *ngIf="showSetupButton"
                (click)="goToSetup()"
                class="btn btn-outline-primary w-100">
                Complete Setup
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  `
})
export class RegistrationSuccessComponent implements OnInit {
  userType: UserType = 'free';
  showSetupButton = false;

  constructor(
    private router: Router,
    private registrationState: RegistrationStateService
  ) {}

  ngOnInit() {
    // Clear registration state and redirect to login
    setTimeout(() => {
      this.registrationState.resetState();
      this.router.navigate(['/public/login']);
    }, 3000); // Redirect after 3 seconds
  }

  getSuccessMessage(): string {
    switch (this.userType) {
      case 'professional':
        return 'Welcome to VDF.AI Professional! Your account is ready to use with advanced features and priority support.';
      case 'enterprise':
        return 'Welcome to VDF.AI Enterprise! Your organization account is ready for setup and team configuration.';
      default:
        return 'Welcome to VDF.AI! Your account is ready to use.';
    }
  }

  getNextSteps(): string[] {
    const commonSteps = [
      'Complete your profile information',
      'Explore the dashboard features'
    ];

    switch (this.userType) {
      case 'professional':
        return [
          ...commonSteps,
          'Configure your project settings',
          'Set up integrations'
        ];
      case 'enterprise':
        return [
          ...commonSteps,
          'Set up your organization profile',
          'Invite team members',
          'Configure SSO and security settings'
        ];
      default:
        return [
          ...commonSteps,
          'Try out our core features'
        ];
    }
  }

  goToDashboard(): void {
    this.router.navigate(['/app/dashboard']);
  }

  goToSetup(): void {
    if (this.userType === 'enterprise') {
      this.router.navigate(['/public/company-details']);
    }
  }
}