<!-- src/app/upgrade-plan/upgrade-plan.component.html -->
<app-sidebar></app-sidebar>    
<div class="dashboard-main">
            <app-header></app-header>
            <div class="container mt-50 mb-50">
            <div class="card h-100 p-0 radius-12 overflow-hidden">
                <div class="card-body p-40">
                    <div class="row justify-content-center">
                        <div class="col-xxl-10">
                            <div *ngIf="availablePlans.length > 0; else enterpriseMessage">
                            <div class="text-center">   
                                <h4 class="mb-16">Upgrade Your Plan</h4>
                                <p class="mb-0 text-lg text-secondary-light">Choose the best plan for your needs.</p>
                            </div>
                            <div >
                                <ul class="nav nav-pills button-tab mt-32 mb-32 pricing-tab justify-content-center" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link px-24 py-10 text-md rounded-pill text-secondary-light fw-medium active" id="pills-monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-monthly" type="button" role="tab" aria-controls="pills-monthly" aria-selected="true" (click)="onTabChange($event)">
                                        Monthly
                                      </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link px-24 py-10 text-md rounded-pill text-secondary-light fw-medium" id="pills-yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-yearly" type="button" role="tab" aria-controls="pills-yearly" aria-selected="false" (click)="onTabChange($event)">
                                        Yearly
                                      </button>
                                    </li>
                                </ul>
                             </div>
                                <div class="tab-content mt-0" id="pills-tabContent">
                                    <!-- Monthly Plans -->
                                    <div class="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
                                        <div class="row gy-4">
                                            <div *ngIf="availablePlans.includes('professional')" class="col-md-6 col-sm-12 pricing-plan-wrapper">
                                                <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-primary-600 text-white">
                                                    <span class="bg-white bg-opacity-25 text-white radius-24 py-8 px-24 text-sm position-absolute end-0 top-0 z-1 rounded-start-top-0 rounded-end-bottom-0">Popular</span>
                                                    <div class="d-flex align-items-center gap-16">
                                                        <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                            <img src="assets/images/pricing/price-icon2.png" alt="">
                                                        </span>
                                                        <div class="">
                                                            <span class="fw-medium text-md text-white">For Individuals & Growing Teams</span>
                                                            <h6 class="mb-0 text-white">Professional</h6>
                                                        </div>
                                                    </div>
                                                    <p class="mt-16 mb-0 text-white mb-28">For growing teams and organizations ready for full integration with VDF AI. Includes advanced features and priority support.</p>
                                                    <h3 class="mb-24 text-white">$29 <span class="fw-medium text-md text-white">/monthly</span></h3>
                                                    <span class="mb-20 fw-medium">What's included</span>
                                                    <ul>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">Everything in Free Trial</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">Limitless Usage</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">DevOps Metrics Evaluation</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">Priority Support</span>
                                                        </li>
                                                    </ul>
                                                    <button (click)="upgradeToPro()" type="button" class="bg-white text-primary-600 text-white text-center border border-white text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">
                                                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                                        {{ currentPlan === 'professional' ? 'Current Plan' : 'Upgrade to Pro' }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="availablePlans.includes('enterprise')" class="col-md-6 col-sm-12 pricing-plan-wrapper">
                                                <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                                                    <div class="d-flex align-items-center gap-16">
                                                        <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                            <img src="assets/images/pricing/price-icon5.png" alt="">
                                                        </span>
                                                        <div class="">
                                                            <span class="fw-medium text-md text-secondary-light">For big companies</span>
                                                            <h6 class="mb-0">Enterprise</h6>
                                                        </div>
                                                    </div>
                                                    <p class="mt-16 mb-0 text-secondary-light mb-28">Ideal for organizations needing advanced features and integrations. Includes dedicated support and custom training.</p>
                                                    <h3 class="mb-24">$99 <span class="fw-medium text-md text-secondary-light">/monthly</span></h3>
                                                    <span class="mb-20 fw-medium">What's included</span>
                                                    <ul>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Everything in Professional Plan</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Enterprise Integrations (Azure, Jira, Git)</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Assessment Document Analysis</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Dedicated Account Manager</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Custom Training and Onboarding</span>
                                                        </li>
                                                    </ul>
                                                    <button (click)="upgradeToEnterprise()" type="button" class="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">
                                                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                                        {{ currentPlan === 'enterprise' ? 'Current Plan' : 'Upgrade to Enterprise' }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Yearly Plans -->
                                    <div class="tab-pane fade" id="pills-yearly" role="tabpanel" aria-labelledby="pills-yearly-tab">
                                        <div class="row gy-4">
                                            <div *ngIf="availablePlans.includes('professional')" class="col-md-6 col-sm-12 pricing-plan-wrapper">
                                                <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-primary-600 text-white">
                                                    <span class="bg-white bg-opacity-25 text-white radius-24 py-8 px-24 text-sm position-absolute end-0 top-0 z-1 rounded-start-top-0 rounded-end-bottom-0">Popular</span>
                                                    <div class="d-flex align-items-center gap-16">
                                                        <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                            <img src="assets/images/pricing/price-icon2.png" alt="">
                                                        </span>
                                                        <div class="">
                                                            <span class="fw-medium text-md text-white">For Individuals & Growing Teams</span>
                                                            <h6 class="mb-0 text-white">Professional</h6>
                                                        </div>
                                                    </div>
                                                    <p class="mt-16 mb-0 text-white mb-28">For growing teams and organizations ready for full integration with VDF AI. Includes advanced features and priority support.</p>
                                                    <h3 class="mb-24 text-white">$290 <span class="fw-medium text-md text-white">/yearly</span></h3>
                                                    <span class="mb-20 fw-medium">What's included</span>
                                                    <ul>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">Everything in Free Trial</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">Limitless Usage</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">DevOps Metrics Evaluation</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                            <span class="text-white text-lg">Priority Support</span>
                                                        </li>
                                                    </ul>
                                                    <button (click)="upgradeToPro()" type="button" class="bg-white text-primary-600 text-white text-center border border-white text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">
                                                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                                        {{ currentPlan === 'professional' ? 'Current Plan' : 'Upgrade to Pro' }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="availablePlans.includes('enterprise')" class="col-md-6 col-sm-12 pricing-plan-wrapper">
                                                <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                                                    <div class="d-flex align-items-center gap-16">
                                                        <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                            <img src="assets/images/pricing/price-icon5.png" alt="">
                                                        </span>
                                                        <div class="">
                                                            <span class="fw-medium text-md text-secondary-light">For big companies</span>
                                                            <h6 class="mb-0">Enterprise</h6>
                                                        </div>
                                                    </div>
                                                    <p class="mt-16 mb-0 text-secondary-light mb-28">Ideal for organizations needing advanced features and integrations. Includes dedicated support and custom training.</p>
                                                    <h3 class="mb-24">$990 <span class="fw-medium text-md text-secondary-light">/yearly</span></h3>
                                                    <span class="mb-20 fw-medium">What's included</span>
                                                    <ul>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Everything in Professional Plan</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Enterprise Integrations (Azure, Jira, Git)</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Assessment Document Analysis</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16 mb-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Dedicated Account Manager</span>
                                                        </li>
                                                        <li class="d-flex align-items-center gap-16">
                                                            <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                            <span class="text-secondary-light text-lg">Custom Training and Onboarding</span>
                                                        </li>
                                                    </ul>
                                                    <button (click)="upgradeToEnterprise()" type="button" class="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">
                                                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                                        {{ currentPlan === 'enterprise' ? 'Current Plan' : 'Upgrade to Enterprise' }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #enterpriseMessage>
                               
                                <div class="dashboard">
                                    
                                    
                                    <div class="container p-24">
                                        <h5>Your Package is currently Enterprise</h5>
                                    <p>You are enjoying all the features available.</p>
                                
                                        <section id="getting-started" class="card rounded-8 p-24 mb-24">
                                            <div class="mb-24"> 
                                                <div class="row gy-4">
                                                    <div class="col-xxl-4 col-sm-6">
                                                        <div class="card h-100 radius-12">
                                                            <img src="assets/images/card-component/card-img2.png" class="card-img-top" alt="">
                                                            <div class="card-body p-16 text-center">
                                                                <h5 class="card-title text-lg text-primary-light
                                                                 mb-6">Enhanced Analytics</h5>
                                                                 <ul>
                                                                    <li class="text-secondary-light mb-8">Advanced sprint metrics</li>
                                                                    <li class="text-secondary-light mb-8">Team performance insights</li>
                                                                    <li class="text-secondary-light mb-8">Custom reporting dashboards</li>
                                                                </ul>
                                                                <button class="btn btn-primary-600 px-12 py-10 d-inline-flex align-items-center gap-2"> 
                                                                    <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-4 col-sm-6">
                                                        <div class="card h-100 radius-12">
                                                            <img src="assets/images/card-component/card-img3.png" class="card-img-top" alt="">
                                                            <div class="card-body p-16 text-center">
                                                                <h5 class="card-title text-lg text-primary-light
                                                                 mb-6">Collaboration Tool</h5>
                                                                 <ul>
                                                                    <li class="text-secondary-light mb-8">Multi-user access</li>
                                                                    <li class="text-secondary-light mb-8">Team workspaces</li>
                                                                    <li class="text-secondary-light mb-8">Shared insights</li>
                                                                </ul>
                                                                <button class="btn btn-primary-600 px-12 py-10 d-inline-flex align-items-center gap-2"> 
                                                                    <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-4 col-sm-6">
                                                        <div class="card h-100 radius-12">
                                                            <img src="assets/images/card-component/card-img4.png" class="card-img-top" alt="">
                                                            <div class="card-body p-16 text-center">
                                                                <h5 class="card-title text-lg text-primary-light
                                                                 mb-6">Integration Capabilities</h5>
                                                                 <ul>
                                                                    <li class="text-secondary-light mb-8">Jira synchronization</li>
                                                                    <li class="text-secondary-light mb-8">Real-time data updates</li>
                                                                    <li class="text-secondary-light mb-8">Custom API access</li>
                                                                </ul>
                                                                <button class="btn btn-primary-600 px-12 py-10 d-inline-flex align-items-center gap-2"> 
                                                                    <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                
                                        <section id="key-features" class="card rounded-8 p-24 mb-24">
                                            <h5>Key Features Overview</h5>
                                            <h6>Professional Plan Features</h6>
                                            
                                            <div class="card-header">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table basic-border-table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Feature</th>
                                                                    <th>Description</th>
                                                                    <th>Limit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Causal Loop Diagrams</td>
                                                                    <td>Create and analyze system dynamics</td>
                                                                    <td>20 per month</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Different Approaches</td>
                                                                    <td>Alternative solution suggestions</td>
                                                                    <td>50 per month</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Jira Integration</td>
                                                                    <td>Full access to Jira connectivity</td>
                                                                    <td>Unlimited</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Team Support</td>
                                                                    <td>Collaborative features</td>
                                                                    <td>Up to 10 users</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                
                                        <section id="jira-integration" class="card rounded-8 p-24 mb-24">
                                            <h5>Jira Integration</h5>
                                            <p class="card-text text-neutral-600 mb-16">Connect VDF AI with your Jira workspace to synchronize data and enhance your Agile workflow:</p>
                                            <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                                                
                                                <div class="d-flex flex-shrink-0 w-170-px h-100">
                                                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                                                </div>
                                                <div class="card-body p-16 flex-grow-1">
                                                    <h5 class="card-title text-lg text-primary-light mb-6">Setup Instructions</h5>
                                                    
                                                        <ol>
                                                            <li>Navigate to the Integrations page</li>
                                                            <li>Click on "Connect" under the Jira section</li>
                                                            <li>Authorize VDF AI in your Atlassian account</li>
                                                            <li>Select the boards you want to sync</li>
                                                        </ol>
                                                    <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                                                        Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            
                                
                                            <div class="tip">
                                                <strong>Pro Tip:</strong> After connecting Jira, VDF AI will automatically pull in your sprint metrics and team data to provide more accurate insights and recommendations.
                                            </div>
                                        </section>
                                
                                        <section id="causal-loops" class="card rounded-8 p-24 mb-24">
                                            <h5>Causal Loop Diagrams</h5>
                                            <p>Create sophisticated system dynamics visualizations to understand complex relationships in your Agile processes:</p>
                                             <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                                                
                                                <div class="d-flex flex-shrink-0 w-170-px h-100">
                                                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                                                </div>
                                                <div class="card-body p-16 flex-grow-1">
                                                    <h5 class="card-title text-lg text-primary-light mb-6">Features</h5>
                                                    
                                                        <ol>
                                                            <li>Interactive diagram creation</li>
                                                        <li>Export capabilities (PDF, PNG)</li>
                                                        <li>Custom relationship mapping</li>
                                                        <li>Team sharing and collaboration</li>
                                                        </ol>
                                                        <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                                                            Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                        </a>
                                                </div>
                                            </div>
                                            
                                            
                                
                                            <div class="tip">
                                                <strong>Best Practice:</strong> Use causal loop diagrams to identify systemic issues and improvement opportunities in your sprint processes.
                                            </div>
                                
                                        </section>
                                
                                        <section id="metrics-tracking" class="card rounded-8 p-24 mb-24">
                                            <h5 class="text-primary-light mb-16">Metrics Tracking</h5>
                                            <p class="text-secondary-light mb-24">Monitor and analyze key performance indicators to enhance team efficiency and project outcomes.</p>
                                            
                                            <h6 class="text-primary-light mb-16">Available Metrics</h6>
                                            <div class="feature-grid">
                                                <div class="card height-equal" style="min-height: 200px;">
                                                    <div class="card-header">
                                                        <h6 class="text-primary-light mb-0">Team Metrics</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <ul class="list-style">
                                                            <li class="text-secondary-light mb-8">Sprint Velocity: Measure the amount of work completed in a sprint.</li>
                                                            <li class="text-secondary-light mb-8">Team Capacity: Evaluate the team's workload capacity.</li>
                                                            <li class="text-secondary-light mb-8">Sprint Burndown: Track the remaining work in a sprint.</li>
                                                            <li class="text-secondary-light mb-8">Task Completion Rate: Assess the rate of task completion.</li>
                                                            <li class="text-secondary-light mb-8">Team Satisfaction: Gauge team morale and satisfaction.</li>
                                                            <li class="text-secondary-light mb-8">Defect Density: Identify the number of defects per unit of work.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="card height-equal" style="min-height: 200px;">
                                                    <div class="card-header">
                                                        <h6 class="text-primary-light mb-0">DevOps Metrics</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <ul class="list-style">
                                                            <li class="text-secondary-light mb-8">Deployment Frequency: Frequency of successful deployments.</li>
                                                            <li class="text-secondary-light mb-8">Lead Time: Time taken from code commit to production.</li>
                                                            <li class="text-secondary-light mb-8">Change Failure Rate: Percentage of changes that fail.</li>
                                                            <li class="text-secondary-light mb-8">Mean Time to Recover: Time to recover from a failure.</li>
                                                            <li class="text-secondary-light mb-8">Automation Rate: Extent of automated processes.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                
                                        <section id="team-collaboration" class="card rounded-8 p-24 mb-24">
                                            <h5>Team Collaboration</h5>
                                            <p>Maximize team efficiency with collaborative features:</p>
                                             <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                                                
                                                <div class="d-flex flex-shrink-0 w-170-px h-100">
                                                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                                                </div>
                                                <div class="card-body p-16 flex-grow-1">
                                                    <h5 class="card-title text-lg text-primary-light mb-6">Features</h5>
                                                    
                                                        <ol>
                                                            <li>Shared dashboards and reports</li>
                                                            <li>Team-wide insights and recommendations</li>
                                                <li>Collaborative problem-solving sessions</li>
                                                <li>Custom role-based access controls</li>
                                                        </ol>
                                                        <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                                                            Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                        </a>
                                                </div>
                                            </div>
                                        </section>
                                
                                        <section id="best-practices" class="card rounded-8 p-24 mb-24">
                                            <h5>Best Practices</h5>
                                            <p>Getting the Most from VDF AI</p>
                                             <div class="card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row">
                                                
                                                <div class="d-flex flex-shrink-0 w-170-px h-100">
                                                    <img src="assets/images/card-component/horizontal-card-img3.png" class="h-100 w-100 object-fit-cover" alt="">
                                                </div>
                                                <div class="card-body p-16 flex-grow-1">
                                                    <h5 class="card-title text-lg text-primary-light mb-6">Features</h5>
                                                    
                                                        <ol>
                                                            <li><strong>Regular Updates:</strong> Keep your Jira integration synchronized for accurate insights</li>
                                                            <li><strong>Team Onboarding:</strong> Ensure all team members complete their profiles and set preferences</li>
                                                            <li><strong>Metrics Review:</strong> Schedule regular reviews of your team's metrics and insights</li>
                                                            <li><strong>Feedback Loop:</strong> Use the Different Approaches feature to explore alternative solutions</li>
                                                            <li><strong>Documentation:</strong> Maintain detailed sprint notes and decision records</li>
                                                        </ol>
                                                        <a href="javascript:void(0)" class="btn text-primary-600 hover-text-primary p-0 d-inline-flex align-items-center gap-2"> 
                                                            Read More <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                                        </a>
                                                </div>
                                            </div>
                                            
                                            <div class="tip">
                                                <strong>Support:</strong> Need help? Contact our premium support team at support&#64;vdf.ai for priority assistance.
                                            </div>
                                            
                                        </section>
                                    </div>
                                </div>
                                
                            </ng-template>  
                        </div>
                    </div>
                </div>
                
                </div>
            </div>
            <app-footer></app-footer> 
        </div>
        
