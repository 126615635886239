<body>

    <section class="auth bg-base d-flex flex-wrap">  
        <div class="auth-left d-lg-block d-none">
            <div class="d-flex align-items-center flex-column h-100 justify-content-center">
              <img src="https://imagedelivery.net/soJx4BiMTBvAFlSCQpzu3A/b2ee7753-3ba0-4fae-8642-a614920ccc00/public" alt="">
            </div>
        </div>
        <div class="auth-right py-32 px-24 d-flex flex-column justify-content-center">
            <div class="max-w-464-px mx-auto w-100">
                <div>
                    <a routerLink="/" class="mb-40 max-w-290-px">
                        <img src="/assets/images/scrum/theme/logo/light_168x44.png" alt="">
                      </a>
                    <h4 class="mb-12">Reset Password</h4>
                    <p class="mb-32 text-secondary-light text-lg">Enter your new password below to reset your password.</p>
                </div>
                <form (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="newPassword" class="form-label">New Password</label>
                        <input type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword" class="form-control h-56-px bg-neutral-50 radius-12" placeholder="Enter New Password" required>
                    </div>
                    <div class="form-group mt-16">
                        <label for="confirmPassword" class="form-label">Confirm Password</label>
                        <input type="password" id="confirmPassword" [(ngModel)]="confirmPassword" name="confirmPassword" class="form-control h-56-px bg-neutral-50 radius-12" placeholder="Confirm New Password" required>
                    </div>
                    <button type="submit" class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32">Reset Password</button>
    
                    <div class="text-center">
                        <a href="/public/login" class="text-primary-600 fw-bold mt-24">Back to Sign In</a>
                    </div>
                    
                    <div class="mt-120 text-center text-sm">
                        <p class="mb-0">Already have an account? <a href="/public/login" class="text-primary-600 fw-semibold">Sign In</a></p>
                    </div>
                </form>
                <div *ngIf="message" class="alert alert-success mt-16">{{ message }}</div>
                <div *ngIf="error" class="alert alert-danger mt-16">{{ error }}</div>
            </div>
        </div>
    </section>
    
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog modal-dialog-centered">
            <div class="modal-content radius-16 bg-base">
            <div class="modal-body p-40 text-center">
                <div class="mb-32">
                    <img src="/assets/images/scrum/theme/logo/light_168x44.png" alt="">
                </div>
                <h6 class="mb-12">Password Reset Successful</h6>
                <p class="text-secondary-light text-sm mb-0">Your password has been reset successfully. You can now log in with your new password.</p>
                <button type="button" class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>
    
    <!-- jQuery library js -->
    <script src="assets/js/lib/jquery-3.7.1.min.js"></script>
    <!-- Bootstrap js -->
    <script src="assets/js/lib/bootstrap.bundle.min.js"></script>
    <!-- Apex Chart js -->
    <!-- <script src="assets/js/lib/apexcharts.min.js"></script> -->
    <script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
    <!-- Data Table js -->
    <script src="assets/js/lib/dataTables.min.js"></script>
    <!-- Iconify Font js -->
    <script src="assets/js/lib/iconify-icon.min.js"></script>
    <!-- jQuery UI js -->
    <script src="assets/js/lib/jquery-ui.min.js"></script>
    <!-- Vector Map js -->
    <script src="assets/js/lib/jquery-jvectormap-2.0.5.min.js"></script>
    <script src="assets/js/lib/jquery-jvectormap-world-mill-en.js"></script>
    <!-- Popup js -->
    <script src="assets/js/lib/magnifc-popup.min.js"></script>
    <!-- Slick Slider js -->
    <script src="assets/js/lib/slick.min.js"></script>
    <!-- prism js -->
    <script src="assets/js/lib/prism.js"></script>
    <!-- file upload js -->
    <script src="assets/js/lib/file-upload.js"></script>
    <!-- audioplayer -->
    <script src="assets/js/lib/audioplayer.js"></script>
    
    <!-- main js -->
    <script src="assets/js/app.js"></script>
    
</body>
