// src/app/services/text-formatter.service.ts
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TextFormatterService {
  constructor(private sanitizer: DomSanitizer) {}

  formatToHTML(text: string): SafeHtml {
    if (!text) return this.sanitizer.bypassSecurityTrustHtml('');

    // Replace line breaks and sections with proper HTML tags
    let formattedHtml = text
      // Handle headers with proper styling
      .replace(/### (.*?)(\n|$)/g, '<h5 class="text-xl font-semibold text-primary mb-3">$1</h5>')
      .replace(/## (.*?)(\n|$)/g, '<h5 class="text-xl font-semibold text-primary mb-3">$1</h5>')
      .replace(/# (.*?)(\n|$)/g, '<h5 class="text-xl font-semibold text-primary mb-3">$1</h5>')
      
      // Handle bold and italic text
      .replace(/\*\*(.*?)\*\*/g, '<strong class="text-primary font-semibold">$1</strong>')
      .replace(/\*(.*?)\*/g, '<em>$1</em>')
      
      // Handle bullet points
      .replace(/^- (.*?)$/gm, '<li class="bullet-point mb-2">$1</li>')
      .replace(/^• (.*?)$/gm, '<li class="bullet-point mb-2">$1</li>')
      
      // Wrap bullet points in ul
      .replace(/(<li class="bullet-point.*?<\/li>\n?)+/g, '<ul class="bullet-list mb-4">$&</ul>')
      
      // Handle line breaks
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
      
      // Remove any remaining # characters
      .replace(/#/g, '');

    return this.sanitizer.bypassSecurityTrustHtml(formattedHtml);
  }
}