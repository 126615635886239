// src/app/guards/no-auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log('NoAuthGuard: Checking route', state.url);
    
    // Allow all registration routes regardless of auth status
    if (state.url.includes('/public/register')) {
      console.log('NoAuthGuard: Registration route detected, allowing access');
      return true;
    }

    const user = this.authService.userValue;
    
    // If user is logged in and trying to access public routes, redirect to dashboard
    if (user) {
      console.log('NoAuthGuard: User is logged in, redirecting to dashboard');
      this.router.navigate(['/app/dashboard']);
      return false;
    }

    console.log('NoAuthGuard: Access granted to public route');
    return true;
  }
}