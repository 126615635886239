// src/app/app.component.ts
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-public',
  standalone: true,
  templateUrl: './public-app.component.html',
  imports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PublicAppComponent implements OnInit {
  ngOnInit() {
        // Make sure iconify is loaded
        if (!(window as any).Iconify) {
          console.warn('Iconify not loaded');
    }
  }
}

