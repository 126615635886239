import { AuthService } from '../../services/auth.service';
import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Declare bootstrap to use its modal functionality
declare var bootstrap: any;

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FormsModule],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  email: string = '';
  message: string = '';
  error: string = '';
  isLoading: boolean = false;

  constructor(private authService: AuthService) {}

  onSubmit() {
    if (!this.email) {
      this.error = 'Please enter your email address';
      return;
    }

    this.isLoading = true;
    this.authService.requestPasswordReset(this.email).subscribe({
      next: (response) => {
        this.message = response.message;
        this.isLoading = false;
        // Show the modal on success
        const modalElement = document.getElementById('exampleModal');
        if (modalElement) {
          const modal = new bootstrap.Modal(modalElement);
          modal.show();
        }
      },
      error: (err) => {
        this.error = err.error.message || 'Failed to send reset email';
        this.isLoading = false;
      }
    });
  }
}
