<app-sidebar></app-sidebar>    
<div class="dashboard-main">
    <app-header></app-header>
    <div class="container mt-50 mb-50">
<div class="terms-conditions">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Terms and Conditions</h5>
            <p class="f-m-light mt-1">Last Updated: 06.09.2024</p>
          </div>
          <div class="card-body">
            <h6>1. Definitions</h6>
            <ul>
              <li><strong>1.1 “VDF AI”</strong> refers to VDF AI, the company providing the Platform, and any subsidiaries, affiliates, officers, agents, or employees.</li>
              <li><strong>1.2 “User”</strong> refers to any individual or entity who accesses or uses the Platform.</li>
              <li><strong>1.3 “Free Member”</strong> refers to an individual user who registers to use the Platform without payment, subject to predefined daily usage limits.</li>
              <li><strong>1.4 “Pro Member”</strong> refers to an individual user who subscribes to the Platform with a monthly or yearly payment plan.</li>
              <li><strong>1.5 “Enterprise Member”</strong> refers to a company or organization that purchases a license for its employees to use the Platform, granting access to additional features and functionalities.</li>
              <li><strong>1.6 “Services”</strong> refers to all functionalities, features, and content provided on or through the Platform.</li>
              <li><strong>1.7 “Input”</strong> refers to any data, text, or other materials that you provide to the Platform for the purpose of receiving Services.</li>
              <li><strong>1.8 “Output”</strong> refers to any data, text, or other materials that are generated by the Platform in response to your Input.</li>
              <li><strong>1.9 “Content”</strong> refers collectively to both Input and Output.</li>
            </ul>

            <h6>2. Acceptance of Terms</h6>
            <ul>
              <li><strong>2.1</strong> By accessing, using, or registering with the Platform, you acknowledge that you have read, understood, and agree to comply with these Terms. If you are entering into this agreement on behalf of an entity, you represent that you have the authority to bind that entity to these Terms.</li>
              <li><strong>2.2</strong> These Terms apply globally, regardless of the location from which the Platform is accessed or used.</li>
            </ul>

            <h6>3. Changes to the Terms</h6>
            <ul>
              <li><strong>3.1</strong> VDF AI reserves the right to modify these Terms at any time. Changes will be effective when posted on the Platform or notified by email or other means. Your continued use of the Platform after changes have been posted will constitute your acceptance of the revised Terms.</li>
            </ul>

            <h6>4. Membership and Access</h6>
            <ul>
              <li><strong>4.1 Free Membership:</strong> Free Members may use the Platform within predefined daily usage limits. Registration is required, but no payment is necessary.</li>
              <li><strong>4.2 Pro Membership:</strong> Pro Members must pay a subscription fee, either monthly or yearly. Pro Members have access to additional features not available to Free Members.</li>
              <li><strong>4.3 Enterprise Membership:</strong> Enterprise Members are organizations that purchase licenses for their employees. This membership provides access to further enhanced features, functionalities, and administrative tools for managing employee access.</li>
            </ul>

            <h6>5. Registration and Account Security</h6>
            <ul>
              <li><strong>5.1</strong> To use certain features of the Platform, you must create an account. You agree to provide accurate, current, and complete information during registration and to update such information to keep it accurate, current, and complete.</li>
              <li><strong>5.2</strong> You are responsible for maintaining the confidentiality of your account credentials and for any activities conducted under your account.</li>
              <li><strong>5.3</strong> You must immediately notify VDF AI of any unauthorized use of your account or any other security breach.</li>
            </ul>

            <h6>6. Payments and Billing</h6>
            <ul>
              <li><strong>6.1 Pro Membership Payments:</strong> All payments for Pro Membership must be made in advance, either monthly or yearly. You authorize VDF AI to charge the applicable subscription fees to the payment method you provide.</li>
              <li><strong>6.2 Enterprise Membership Payments:</strong> Payments for Enterprise Memberships are governed by separate agreements between VDF AI and the Enterprise Member. Terms may vary depending on the number of licenses, features required, and other factors.</li>
              <li><strong>6.3</strong> All payments are non-refundable, except as required by applicable law.</li>
              <li><strong>6.4</strong> VDF AI reserves the right to change subscription fees or introduce new fees at any time, with prior notice to users.</li>
            </ul>

            <h6>7. User Obligations and Prohibited Activities</h6>
            <ul>
              <li><strong>7.1</strong> You agree to use the Platform only for lawful purposes and in accordance with these Terms.</li>
              <li><strong>7.2</strong> You agree not to:</li>
              <ul>
                <li>Use the Platform in any manner that could disable, overburden, damage, or impair the Platform or interfere with any other party’s use.</li>
                <li>Attempt to gain unauthorized access to the Platform or related systems or networks.</li>
                <li>Use the Platform to transmit any illegal, harmful, threatening, defamatory, or otherwise objectionable content.</li>
                <li>Reverse engineer, decompile, or disassemble any part of the Platform.</li>
                <li>Resell or sublicense any of the Platform’s features to third parties without authorization.</li>
              </ul>
            </ul>

            <h6>8. Content</h6>
            <ul>
              <li><strong>8.1 Your Content:</strong> You may provide Input to the Services (“Input”) and receive Output from the Services based on the Input (“Output”). Input and Output are collectively referred to as “Content.” You are responsible for all Content, including ensuring that it does not violate any applicable law or these Terms. You represent and warrant that you have all rights, licenses, and permissions necessary to provide Input to our Services.</li>
              <li><strong>8.2 Ownership of Content:</strong> As between you and VDF AI, and to the extent permitted by applicable law, you (a) retain ownership rights in your Input, and (b) own the Output. VDF AI hereby assigns to you all its right, title, and interest, if any, in and to the Output.</li>
              <li><strong>8.3 Similarity of Content:</strong> Due to the nature of our Services and artificial intelligence generally, Output may not be unique, and other users may receive similar Output from our Services. Our assignment above does not extend to other users’ Output or any Third Party Output.</li>
              <li><strong>8.4 Our Use of Content:</strong> VDF AI may use Content to provide, maintain, develop, and improve our Services, comply with applicable law, enforce our terms and policies, and keep our Services safe.</li>
              <li><strong>8.5 Opt-Out:</strong> If you do not want VDF AI to use your Content to train our models, you can opt out by following the instructions provided in our Help Center. Please note that opting out may limit the ability of our Services to better address your specific use case.</li>
            </ul>

            <h6>9. Accuracy and Use of Services</h6>
            <ul>
              <li><strong>9.1</strong> Artificial intelligence and machine learning are rapidly evolving fields. VDF AI is constantly working to improve the accuracy, reliability, safety, and overall quality of its Services. However, due to the probabilistic nature of machine learning, the use of our Services may, in some situations, result in Output that does not accurately reflect real people, places, or facts.</li>
              <li><strong>9.2 User Acknowledgement:</strong> When you use our Services, you understand and agree that:</li>
              <ul>
                <li>Output may not always be accurate. You should not rely on Output from our Services as a sole source of truth or factual information, or as a substitute for professional advice.</li>
                <li>You must evaluate Output for accuracy and appropriateness for your use case, including using human review as necessary, before using or sharing Output from the Services.</li>
                <li>You must not use any Output relating to a person for any purpose that could have a legal or material impact on that person, such as making credit, educational, employment, housing, insurance, legal, medical, or other important decisions about them.</li>
                <li>Our Services may provide incomplete, incorrect, or offensive Output that does not represent VDF AI’s views. If Output references any third-party products or services, it does not imply that the third party endorses or is affiliated with VDF AI.</li>
              </ul>
            </ul>

            <h6>10. Data Privacy and Security</h6>
            <ul>
              <li><strong>10.1</strong> VDF AI is committed to protecting your privacy and will handle your personal data in accordance with our Privacy Policy.</li>
              <li><strong>10.2</strong> You consent to the collection, use, and disclosure of your personal data as described in the Privacy Policy.</li>
              <li><strong>10.3</strong> While VDF AI takes reasonable measures to protect your data, it cannot guarantee absolute security. You are responsible for safeguarding your account credentials.</li>
            </ul>

            <h6>11. Disclaimer of Warranties</h6>
            <ul>
              <li><strong>11.1</strong> The Platform is provided on an “as-is” and “as-available” basis. VDF AI makes no representations or warranties of any kind, express or implied, regarding the Platform, including but not limited to its accuracy, completeness, or reliability.</li>
              <li><strong>11.2</strong> VDF AI disclaims any warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>
            </ul>

            <h6>12. Limitation of Liability</h6>
            <ul>
              <li><strong>12.1</strong> To the fullest extent permitted by law, VDF AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, or goodwill.</li>
              <li><strong>12.2</strong> VDF AI’s total liability to you for any claim arising out of or relating to these Terms or your use of the Platform shall not exceed the amount paid by you to VDF AI in the 12 months preceding the claim.</li>
            </ul>

            <h6>13. Indemnification</h6>
            <ul>
              <li><strong>13.1</strong> You agree to indemnify, defend, and hold harmless VDF AI, its affiliates, officers, agents, and employees from any claim, demand, loss, or damage, including reasonable attorney fees, arising out of or related to your use of the Platform or breach of these Terms.</li>
            </ul>

            <h6>14. Governing Law and Dispute Resolution</h6>
            <ul>
              <li><strong>14.1</strong> These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</li>
              <li><strong>14.2</strong> Any disputes arising out of or relating to these Terms or the use of the Platform shall be resolved through binding arbitration in [City, Country], except that either party may seek injunctive relief in any court of competent jurisdiction.</li>
            </ul>

            <h6>15. Termination</h6>
            <ul>
              <li><strong>15.1</strong> VDF AI may suspend or terminate your access to the Platform at any time, without notice, for any reason, including but not limited to a breach of these Terms.</li>
              <li><strong>15.2</strong> You may terminate your account at any time by discontinuing the use of the Platform. Upon termination, all licenses granted under these Terms will immediately terminate.</li>
            </ul>

            <h6>16. Miscellaneous</h6>
            <ul>
              <li><strong>16.1</strong> These Terms constitute the entire agreement between you and VDF AI concerning the use of the Platform and supersede any prior agreements.</li>
              <li><strong>16.2</strong> If any provision of these Terms is found to be unenforceable or invalid, the remaining provisions shall remain in full force and effect.</li>
              <li><strong>16.3</strong> The failure of VDF AI to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.</li>
              <li><strong>16.4</strong> You may not assign or transfer any of your rights or obligations under these Terms without VDF AI’s prior written consent. VDF AI may assign or transfer its rights and obligations without restriction.</li>
            </ul>

            <h6>17. Contact Information</h6>
            <p>If you have any questions about these Terms, please contact us at info&#64;vdf.ai</p>

            <p>This revised document now includes the necessary provisions related to content ownership, usage, and accuracy, which are essential for protecting both the users and VDF AI. It’s advisable to have this document reviewed by a qualified legal professional to ensure it fully complies with applicable laws and regulations globally.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<app-footer></app-footer> 
</div>
