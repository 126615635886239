// src/app/services/d3-diagram.service.ts

import { Injectable } from '@angular/core';
import { CausalLoopData } from './chat.service';
import * as d3 from 'd3';

interface Node extends d3.SimulationNodeDatum {
  id: string;
  x?: number;
  y?: number;
  fx?: number | null;
  fy?: number | null;
}

interface Relation {
  from: string;
  to: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class D3DiagramService {
  private width = 800;
  private height = 500;

  renderDiagram(data: CausalLoopData, elementId: string): void {
    console.log('Rendering diagram with data:', data);

    // Clear previous content
    const container = d3.select(`#${elementId}`);
    container.selectAll('*').remove();

    // Create nodes from variables
    const nodes: Node[] = data.diagram.variables.map(v => ({
      id: v,
    }));

    // Create links from relations
    const links = data.diagram.relations.map(relation => ({
      source: nodes.find(n => n.id === relation.from)!,
      target: nodes.find(n => n.id === relation.to)!,
      type: relation.type
    }));

    // Create SVG
    const svg = container
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('viewBox', [-this.width/2, -this.height/2, this.width, this.height])
    .style('background-color', '#ffffff') // Add white background
    .style('width', '100%')
    .style('height', '100%');

    // Create arrow markers
    const defs = svg.append('defs');
    
    // Positive arrow marker
    defs.append('marker')
      .attr('id', 'arrow-positive')
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 30)
      .attr('refY', 0)
      .attr('markerWidth', 8)
      .attr('markerHeight', 8)
      .attr('orient', 'auto')
      .append('path')
      .attr('fill', '#22c55e')
      .attr('d', 'M0,-5L10,0L0,5');

    // Negative arrow marker
    defs.append('marker')
      .attr('id', 'arrow-negative')
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 30)
      .attr('refY', 0)
      .attr('markerWidth', 8)
      .attr('markerHeight', 8)
      .attr('orient', 'auto')
      .append('path')
      .attr('fill', '#ef4444')
      .attr('d', 'M0,-5L10,0L0,5');

    svg.append('rect')
      .attr('x', -this.width/2)
      .attr('y', -this.height/2)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'transparent');

    // Create zoom behavior
    const zoom = d3.zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.1, 4])
      .on('zoom', (event) => g.attr('transform', event.transform.toString()));

    svg.call(zoom);

    // Create main group for zoom/pan
    const g = svg.append('g');

    // Create links
    const link = g.append('g')
      .selectAll('path')
      .data(links)
      .join('path')
      .attr('stroke', d => d.type === 'positive' ? '#22c55e' : '#ef4444')
      .attr('stroke-width', 2)
      .attr('fill', 'none')
      .attr('marker-end', d => `url(#arrow-${d.type})`);

    // Create nodes
    const node = g.append('g')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .attr('class', 'node');

    // Add circles to nodes
    node.append('circle')
      .attr('r', 20)
      .attr('fill', 'white')
      .attr('stroke', '#374151')
      .attr('stroke-width', 2);

    // Add text background for better readability
    node.append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('fill', '#374151')
      .attr('font-size', '12px')
      .attr('font-weight', '500')
      .attr('stroke', 'white')
      .attr('stroke-width', '3px')
      .attr('stroke-linejoin', 'round')
      .text(d => d.id);

    // Add actual text
    node.append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('fill', '#374151')
      .attr('font-size', '12px')
      .attr('font-weight', '500')
      .text(d => d.id);

    // Create force simulation
    const simulation = d3.forceSimulation<Node>(nodes)
      .force('link', d3.forceLink<Node, any>(links)
        .id(d => d.id)
        .distance(150))
      .force('charge', d3.forceManyBody().strength(-1000))
      .force('center', d3.forceCenter(0, 0))
      .force('collision', d3.forceCollide().radius(50));

    // Add drag behavior
    node.call(this.drag(simulation) as any);

    // Update positions on tick
    simulation.on('tick', () => {
      link.attr('d', (d: any) => {
        const dx = d.target.x - d.source.x;
        const dy = d.target.y - d.source.y;
        const dr = Math.sqrt(dx * dx + dy * dy);
        return `
          M${d.source.x},${d.source.y}
          A${dr},${dr} 0 0,1 ${d.target.x},${d.target.y}
        `;
      });

      node.attr('transform', (d: any) => `translate(${d.x},${d.y})`);
    });
  }

  private drag(simulation: d3.Simulation<Node, undefined>) {
    function dragstarted(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    
    function dragged(event: any, d: any) {
      d.fx = event.x;
      d.fy = event.y;
    }
    
    function dragended(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
    
    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }

  cleanup(elementId: string): void {
    try {
      const container = d3.select(`#${elementId}`);
      if (!container.empty()) {
        container.selectAll('*').remove();
      }
    } catch (error) {
      console.error('Error cleaning up diagram:', error);
    }
  }
}