<!-- src/app/registration/components/company-details/company-details.component.html -->

<div class="dashboard-main">
  <app-header></app-header>
  <div class="dashboard-main-body">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-between gap-16 mb-24">
        <h6 class="fw-semibold mb-0">Upgrade to Enterprise Plan</h6>
        <ul class="d-flex align-items-center gap-2">
          <li class="fw-medium">
            <a routerLink="/dashboard" class="d-flex align-items-center gap-1 hover-text-primary">
              <iconify-icon icon="solar:home-smile-angle-outline" class="icon text-lg"></iconify-icon>
              Upgrade
            </a>
          </li>
          <li>-</li>
          <li class="fw-medium">Enterprise</li>
        </ul>
      </div>

      <!-- Company Information Form -->
      <div class="card h-100 p-0 radius-12 overflow-hidden" *ngIf="showCompanyForm">
        <div class="card-header">
          <h5 class="card-title mb-0">Company Information</h5>
        </div>
        <div class="card-body p-40">
          <form [formGroup]="companyForm">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="name" class="form-label fw-semibold text-primary-light text-sm mb-8">Full Name <span class="text-danger-600">*</span></label>
                  <input type="text" class="form-control radius-8" id="name" formControlName="name" placeholder="Enter Full Name">
                  <div *ngIf="companyForm.get('name')!.touched && companyForm.get('name')!.invalid" class="text-danger">
                    Full Name is required.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="email" class="form-label fw-semibold text-primary-light text-sm mb-8">Email <span class="text-danger-600">*</span></label>
                  <input type="email" class="form-control radius-8" id="email" formControlName="email" placeholder="Enter email address">
                  <div *ngIf="companyForm.get('email')!.touched && companyForm.get('email')!.invalid" class="text-danger">
                    Valid email is required.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="number" class="form-label fw-semibold text-primary-light text-sm mb-8">Phone Number</label>
                  <input type="email" class="form-control radius-8" id="number" placeholder="Enter phone number">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="Website" class="form-label fw-semibold text-primary-light text-sm mb-8">Website <span class="text-danger-600">*</span></label>
                  <input type="url" class="form-control radius-8" id="Website" formControlName="website" placeholder="Website URL">
                  <div *ngIf="companyForm.get('website')!.touched && companyForm.get('website')!.invalid" class="text-danger">
                    Valid website URL is required.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="country" class="form-label fw-semibold text-primary-light text-sm mb-8">
                    Country <span class="text-danger-600"></span>
                  </label>
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control radius-8"
                      [formControlName]="'country'"
                      (input)="onCountrySearch($event, 'country')"
                      [placeholder]="'Search country'"
                      [attr.list]="'countryList'"
                    >
                    <datalist id="countryList">
                      <option *ngFor="let country of filteredCountries" [value]="country.name">
                        {{country.name}}
                      </option>
                    </datalist>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="city" class="form-label fw-semibold text-primary-light text-sm mb-8">City <span class="text-danger-600"></span> </label>
                  <input
                    type="text"
                    id="city"
                    class="form-control radius-8"
                    formControlName="city"
                    placeholder="Enter city"
                  >
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="state" class="form-label fw-semibold text-primary-light text-sm mb-8">
                    State/Province
                  </label>
                  <input
                    type="text"
                    id="state"
                    class="form-control radius-8"
                    formControlName="state"
                    placeholder="Enter state or province"
                  >
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="zip" class="form-label fw-semibold text-primary-light text-sm mb-8"> Zip Code <span class="text-danger-600"></span></label>
                  <input type="text" class="form-control radius-8" id="zip" placeholder="Zip Code">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="mb-20">
                  <label for="address" class="form-label fw-semibold text-primary-light text-sm mb-8"> Address <span class="text-danger-600"></span></label>
                  <input type="text" class="form-control radius-8" id="address" placeholder="Enter Your Address">
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-24">
              <button type="button" [disabled]="!isFirstFormValid()" (click)="onNextButtonClick()" class="btn btn-primary text-md px-24 py-12 radius-8">Next</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Billing Information Form -->
      <div class="card h-100 p-0 radius-12 overflow-hidden" *ngIf="showBillingForm">
        <div class="card-header">
          <h5 class="card-title mb-0">Billing Information</h5>
        </div>
        <div class="card-body p-40">
          <form [formGroup]="companyForm">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingName" class="form-label fw-semibold text-primary-light text-sm mb-8">Billing Name <span class="text-danger-600">*</span></label>
                  <input type="text" class="form-control radius-8" id="billingName" formControlName="billingName" placeholder="Enter Billing Name">
                  <div *ngIf="companyForm.get('billingName')!.touched && companyForm.get('billingName')!.invalid" class="text-danger">
                    Billing Name is required.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingAddress" class="form-label fw-semibold text-primary-light text-sm mb-8">Billing Address <span class="text-danger-600">*</span></label>
                  <input type="text" class="form-control radius-8" id="billingAddress" formControlName="billingAddress" placeholder="Enter Billing Address">
                  <div *ngIf="companyForm.get('billingAddress')!.touched && companyForm.get('billingAddress')!.invalid" class="text-danger">
                    Billing Address is required.
                  </div>
                </div>
              </div>  
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingEmail" class="form-label fw-semibold text-primary-light text-sm mb-8">Billing Email</label>
                  <input type="email" class="form-control radius-8" id="billingEmail" placeholder="Enter Billing Email">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingPhone" class="form-label fw-semibold text-primary-light text-sm mb-8">Billing Phone</label>
                  <input type="tel" class="form-control radius-8" id="billingPhone" placeholder="Enter Billing Phone">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="vatNumber" class="form-label fw-semibold text-primary-light text-sm mb-8">VAT Number <span class="text-danger-600">*</span></label>
                  <input type="text" class="form-control radius-8" id="vatNumber" formControlName="vatNumber" placeholder="Enter VAT Number">
                  <div *ngIf="companyForm.get('vatNumber')!.touched && companyForm.get('vatNumber')!.invalid" class="text-danger">
                    VAT Number is required.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="zipCode" class="form-label fw-semibold text-primary-light text-sm mb-8">ZIP Code</label>
                  <input type="text" class="form-control radius-8" id="zipCode" placeholder="Enter ZIP Code">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingCountry" class="form-label fw-semibold text-primary-light text-sm mb-8">
                    Billing Country <span class="text-danger-600">*</span>
                  </label>
                  <div class="position-relative">
                    <input
                      type="text"
                      id="billingCountry"
                      class="form-control radius-8"
                      [formControlName]="'billingCountry'"
                      (input)="onCountrySearch($event, 'billingCountry')"
                      [placeholder]="'Search billing country'"
                      [attr.list]="'billingCountryList'"
                      [class.is-invalid]="isFieldInvalid('billingCountry')"
                    >
                    <datalist id="billingCountryList">
                      <option *ngFor="let country of filteredBillingCountries" [value]="country.name">
                        {{country.name}}
                      </option>
                    </datalist>
                    <div class="invalid-feedback" *ngIf="isFieldInvalid('billingCountry')">
                      {{ getErrorMessage('billingCountry') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingState" class="form-label fw-semibold text-primary-light text-sm mb-8">
                    Billing State/Province
                  </label>
                  <input
                    type="text"
                    id="billingState"
                    class="form-control radius-8"
                    formControlName="billingState"
                    placeholder="Enter billing state or province"
                  >
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-20">
                  <label for="billingCity" class="form-label fw-semibold text-primary-light text-sm mb-8">
                    Billing City
                  </label>
                  <input
                    type="text"
                    id="billingCity"
                    class="form-control radius-8"
                    formControlName="billingCity"
                    placeholder="Enter billing city"
                  >
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-24">
              <button type="button" class="btn btn-secondary text-md px-24 py-12 radius-8" (click)="showCompanyFormSection()">Back</button>
              <button type="button" [disabled]="!isSecondFormValid()" (click)="onSaveButtonClick()" class="btn btn-primary text-md px-24 py-12 radius-8">Save</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Team Member Form -->
      <div class="card h-100 p-0 radius-12 overflow-hidden" *ngIf="showTeamMemberForm">
        <div class="card-header">
          <h5 class="card-title mb-0">Add Team Members</h5>
        </div>
        <div class="card-body p-40">
          <form [formGroup]="companyForm">
            <div formArrayName="teamMembers">
              <div *ngFor="let member of teamMembers.controls; let i = index" [formGroupName]="i" class="row mb-3 align-items-center">
                <div class="col-sm-3">
                  <label for="email-{{i}}" class="form-label fw-semibold text-primary-light text-sm mb-8">Email</label>
                  <input type="email" class="form-control radius-8" id="email-{{i}}" formControlName="email" placeholder="Enter Team Member Email">
                </div>
                <div class="col-sm-3">
                  <label for="name-{{i}}" class="form-label fw-semibold text-primary-light text-sm mb-8">Name</label>
                  <input type="text" class="form-control radius-8" id="name-{{i}}" formControlName="name" placeholder="Enter Name">
                </div>
                <div class="col-sm-3">
                  <label for="surname-{{i}}" class="form-label fw-semibold text-primary-light text-sm mb-8">Surname</label>
                  <input type="text" class="form-control radius-8" id="surname-{{i}}" formControlName="surname" placeholder="Enter Surname">
                </div>
                <div class="col-sm-2">
                  <label for="title-{{i}}" class="form-label fw-semibold text-primary-light text-sm mb-8">Title</label>
                  <input type="text" class="form-control radius-8" id="title-{{i}}" formControlName="title" placeholder="Enter Title">
                </div>
                <div class="col-sm-1 d-flex justify-content-center custom-remove-member-btn">
                  <button type="button" class="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle" (click)="removeTeamMember(i)">
                    <iconify-icon icon="fluent:delete-24-regular" class="menu-icon"></iconify-icon>
                  </button>
                  
                    <button type="button" class="add-item-btn bg-success-focus bg-hover-success-200 text-success-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle" (click)="addTeamMember()">
                      <iconify-icon icon="fluent:add-24-regular" class="menu-icon"></iconify-icon>
                    </button>
                  
                </div>
              </div>
              <div *ngIf="teamMembers.length === 0" class="d-flex justify-content-center mt-3">
               
                  <button (click)="addTeamMember()" class="bg-success-focus text-success-600 border border-success-main px-24 py-4 radius-4 fw-medium text-sm">Add Team Member</button>
                
              </div>
 
            </div>
          </form>
          <div class="d-flex align-items-left justify-content-between mt-24">
            <div *ngIf="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
            <button type="button" class="btn btn-secondary text-md px-24 py-12 radius-8" (click)="showBillingFormSection()">Back</button>
            <button 
              type="button" 
              class="btn btn-primary text-md px-24 py-12 radius-8" 
              (click)="proceedToPayment()"
              [disabled]="!companyForm.valid">
              Proceed to Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>