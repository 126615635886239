export interface TeamMetrics {
    sprintVelocities: number[];
    teamSize?: number;
    sprintEvents?: string[];
    changingTeamMember?: number;
    teamSatisfaction?: number;
    defectDensity?: number;
}
  
export interface DevOpsMetrics {
    deploymentFrequency?: number;
    leadTime?: number;
    changeFailureRate?: number;
    meanTimeToRecover?: number;
    automationRate?: number;
}
  
export interface MetricsContext {
    teamMetrics?: Partial<TeamMetrics>;
    devOpsMetrics?: Partial<DevOpsMetrics>;
}

// Add default implementations
export const DEFAULT_TEAM_METRICS: TeamMetrics = {
    sprintVelocities: [],
    teamSize: undefined,
    sprintEvents: [],
    changingTeamMember: undefined,
    teamSatisfaction: undefined,
    defectDensity: undefined
};

export const DEFAULT_DEVOPS_METRICS: DevOpsMetrics = {
    deploymentFrequency: undefined,
    leadTime: undefined,
    changeFailureRate: undefined,
    meanTimeToRecover: undefined,
    automationRate: undefined
};