// src/app/services/subscription.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, catchError, throwError, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { 
  CreateOrderResponse, 
  PaymentVerificationResponse, 
  OrderData,
  BillingAddress,
  SubscriptionStatus,
  Feature,
  PaymentResponse,
  VerifyPaymentResponse,
  UpgradeResponse,
  PaymentMethodData,
  SubscriptionInfo,
  UserType,
  SubscriptionUpdateData,
  PaymentVerificationRequest,
} from '../interfaces/payment.interface';

export interface ExtendedOrderData extends OrderData {
  // Add any additional fields needed
}

interface CreateOrderRequest {
  amount: number;
  currency: string;
  plan: string;
  billingCycle: string;
  billing_address: {
    country: string;
    address: string;
    city: string;
    postal_code: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  private getHeaders(): HttpHeaders {
    const token = this.authService.token;
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }

  private getOptions(additionalHeaders: { [key: string]: string } = {}) {
    let headers = this.getHeaders();
    Object.entries(additionalHeaders).forEach(([key, value]) => {
      headers = headers.append(key, value);
    });

    return {
      headers,
      withCredentials: true
    };
  }


  /**
   * Upgrade user subscription plan
   * @param planType - new subscription type
   * @param billingCycle - billing cycle (monthly/yearly)
   * @param paymentInfo - payment information
   */
  upgradePlan(planType: string, billingCycle: string, paymentInfo: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/subscription/update`,
      { 
        plan: planType,
        billing_cycle: billingCycle,
        payment_id: paymentInfo.paymentId
      },
      this.getOptions()
    ).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Client error: ${error.error.message}`;
    } else {
      errorMessage = `Server error: ${error.status} - ${error.error?.message || error.statusText}`;
    }
    return throwError(() => new Error(errorMessage));
  }

  /**
   * Get current subscription status
   * @returns Observable of SubscriptionStatus
   */
  getSubscriptionStatus(): Observable<SubscriptionStatus> {
    return this.http.get<{ data: SubscriptionStatus, success: boolean }>(
      `${this.apiUrl}/subscription/status`,
      this.getOptions()
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Get list of available features based on current subscription
   * @returns Observable array of Feature
   */
  getAvailableFeatures(): Observable<Feature[]> {
    return this.http.get<Feature[]>(
      `${this.apiUrl}/subscription/features`,
      this.getOptions()
    );
  }

  /**
   * Create a new order
   * @param orderData - Order data for creating new subscription
   */
  createOrder(orderData: CreateOrderRequest): Observable<CreateOrderResponse> {
    return this.http.post<CreateOrderResponse>(
      `${this.apiUrl}/subscription/create-order`,
      orderData
    ).pipe(
      tap(response => {
        console.log('Creating order with data:', orderData);
      }),
      catchError(error => {
        console.error('Server error details:', error);
        return throwError(() => error);
      })
    );
  }

  createRegistrationOrder(orderData: CreateOrderRequest): Observable<CreateOrderResponse> {
    return this.http.post<CreateOrderResponse>(
      `${this.apiUrl}/subscription/create-registration_order`,
      orderData
    ).pipe(
      tap(response => {
        console.log('Creating order with data:', orderData);
      }),
      catchError(error => {
        console.error('Server error details:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Get payment status
   * @param orderId - The order ID to check
   * @returns Observable with payment status
   */
  getPaymentStatus(orderId: string): Observable<any> {
    console.log(`Calling payment status with orderId: ${orderId}`);
    
    return this.http.get<any>(
      `${this.apiUrl}/subscription/payment-status`,
      {
        ...this.getOptions(),
        params: { order_id: orderId }
      }
    );
  }


  /**
   * Cancel subscription
   * @param subscriptionId - ID of subscription to cancel
   */
  cancelSubscription(subscriptionId: string): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/cancel`,
      { subscriptionId },
      this.getOptions()
    );
  }

  /**
   * Update payment method
   * @param paymentMethodData - New payment method data
   */
  updatePaymentMethod(paymentMethodData: any): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/payment-method`,
      paymentMethodData,
      this.getOptions()
    );
  }

  /**
   * Get billing history
   * @param filters - Optional filters for billing history
   */
  getBillingHistory(filters?: any): Observable<any> {
    const queryParams = filters ? `?${new URLSearchParams(filters).toString()}` : '';
    return this.http.get<any>(
      `${this.apiUrl}/billing-history${queryParams}`,
      this.getOptions()
    );
  }

  /**
   * Update subscription
   */
  updateSubscription(data: SubscriptionUpdateData): Observable<any> {
    console.log('Updating subscription with data:', data);
    
    return this.http.post(
      `${this.apiUrl}/subscription/update`,
      data,
      this.getOptions()
    ).pipe(
      tap(response => console.log('Subscription update response:', response)),
      catchError(error => {
        console.error('Error updating subscription:', error);
        return throwError(() => new Error(`Server error: ${error.status} - ${error.statusText}`));
      })
    );
  }

  /**
   * Process payment for created order
   * @param orderId - The order ID from create-order response
   * @param paymentMethod - Payment method details
   */
  processOrderPayment(orderId: string, paymentMethod: PaymentMethodData): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>(
      `${this.apiUrl}/subscription/process-payment`,
      {
        order_id: orderId,
        payment_method: paymentMethod
      },
      this.getOptions()
    ).pipe(
      tap(response => console.log('Payment processing response:', response)),
      catchError(error => {
        console.error('Payment processing error:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Check subscription status and eligibility
   * Returns information about current subscription and upgrade eligibility
   */
  checkSubscriptionEligibility(): Observable<SubscriptionInfo> {
    return this.http.get<SubscriptionStatus>(
      `${this.apiUrl}/subscription/status`,
      this.getOptions()
    ).pipe(
      map(currentSub => {
        // Case 1: No subscription or inactive
        if (!currentSub || currentSub.status !== 'active') {
          return {
            userType: (currentSub?.userType || 'free') as UserType,
            status: 'inactive',
            canUpgrade: true
          };
        }

        // Case 2: Active enterprise subscription
        if (currentSub.userType === 'enterprise') {
          return {
            userType: 'enterprise' as UserType,
            status: 'active',
            message: 'You already have an active Enterprise subscription.',
            canUpgrade: false
          };
        }

        // Case 3: Active professional subscription
        if (currentSub.userType === 'professional') {
          return {
            userType: 'professional' as UserType,
            status: 'active',
            message: 'Your current Professional subscription will be deactivated upon upgrade.',
            canUpgrade: true
          };
        }

        // Case 4: Default case
        return {
            userType: currentSub.userType as UserType,
            status: currentSub.status,
            canUpgrade: true
        };
      }),
      catchError(error => {
        console.error('Error checking subscription eligibility:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Create order and process payment with subscription validation
   */
  createOrderAndProcessPayment(orderData: ExtendedOrderData, paymentMethod: PaymentMethodData): Observable<any> {
    return this.checkSubscriptionEligibility().pipe(
      switchMap(eligibility => {
        if (!eligibility.canUpgrade) {
          throw new Error(eligibility.message || 'Subscription upgrade not allowed');
        }

        // Transform ExtendedOrderData to OrderData
        const revolutOrderData: OrderData = {
          amount: orderData.amount,
          currency: orderData.currency,
          description: `Plan: ${orderData.plan}, Billing Cycle: ${orderData.billingCycle}`,
          billingAddress: orderData.billingAddress,
          plan: orderData.plan,
          billingCycle: orderData.billingCycle as 'monthly' | 'yearly'
        };

        return this.createOrder(revolutOrderData as unknown as CreateOrderRequest).pipe(
          switchMap(orderResponse => {
            if (!orderResponse.order_id) {
              throw new Error('Order creation failed: No order ID received');
            }
            
            return this.processOrderPayment(orderResponse.order_id as string, paymentMethod).pipe(
              switchMap(paymentResponse => {
                // If there's an active professional subscription, deactivate it first
                if (eligibility.userType === 'professional' && eligibility.status === 'active') {
                  return this.deactivateExistingSubscription().pipe(
                    switchMap(() => {
                      const subscriptionData = {
                        user_id: this.authService.userValue?.id ?? '',
                        plan: orderData.plan,
                        billing_cycle: orderData.billingCycle,
                      };
                      return this.updateSubscription(subscriptionData as SubscriptionUpdateData);
                    })
                  );
                }

                // Otherwise, just create the new subscription
                const subscriptionData = {
                  user_id: this.authService.userValue?.id ?? '',
                  plan: orderData.plan,
                  billing_cycle: orderData.billingCycle,
                };
                return this.updateSubscription(subscriptionData as SubscriptionUpdateData);
              })
            );
          })
        );
      }),
      tap(response => console.log('Order and payment process completed:', response)),
      catchError(error => {
        console.error('Order and payment process failed:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Deactivate existing subscription for user
   */
  private deactivateExistingSubscription(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/subscription/deactivate`,
      {},
      this.getOptions()
    ).pipe(
      tap(response => console.log('Deactivated existing subscription:', response)),
      catchError(error => {
        console.error('Error deactivating subscription:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Store payment details
   * @param orderId - The order ID
   * @param paymentId - The payment ID
   * @returns Observable with storage confirmation
   */
  storePaymentDetails(orderId: string, paymentId: string, amount: number, billingCycle: string, plan: string, user_id: string): Observable<{success: boolean, message?: string, user_id?: string}> {
    return this.http.post<{success: boolean, message?: string}>(
      `${this.apiUrl}/subscription/store-payment`,
      { orderId, paymentId, amount, billingCycle, plan, user_id },
      this.getOptions()
    ).pipe(
      tap(response => console.log('Payment details stored:', response)),
      catchError(error => {
        console.error('Error storing payment details:', error);
        return throwError(() => error);
      })
    );
  }
}