import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserProfile, UpdateUserProfileDto, PasswordUpdateDto, User } from '../interfaces/user.interface';
import { request } from 'http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getUserProfile(): Observable<UserProfile> {
    console.log('getUserProfile called');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const email = user.email;
    console.log('Retrieved email from user object:', email);
    
    if (!email) {
      console.log('No email found in user object');
      return throwError(() => new Error('User email not found'));
    }
    
    console.log('Making request with email:', email);
    return this.http.get<UserProfile>(`${this.apiUrl}/profile/get-profile`, {
      params: { email }
    }).pipe(
      catchError(error => {
        console.error('Profile request error:', error);
        return throwError(() => error);
      })
    );
  }

  updateUserProfile(profile: Partial<UserProfile>): Observable<UserProfile> {
    console.log('updateUserProfile called');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const email = user.email;
    console.log('Email:', email);

    const requestUrl = `${this.apiUrl}/profile/update-profile`;
    console.log('Request URL:', requestUrl);
    console.log('Profile data being sent:', profile);

    return this.http.put<UserProfile>(requestUrl, profile, {
        params: { email }
    }).pipe(
        catchError(error => {
            console.error('Update profile error:', error);
            const message = error.error?.message || 'Failed to update profile';
            return throwError(() => new Error(message));
        })
    );
  }

  updatePassword(user: User, passwordData: PasswordUpdateDto): Observable<any> {
    return this.http.post(`${this.apiUrl}/profile/update-password`, {
      user_id: user.id, 
      newPassword: passwordData.newPassword
    }).pipe(
      catchError(error => {
        const message = error.error?.message || 'Failed to update password';
        return throwError(() => new Error(message));
      })
    );
  }

  updateProfileImage(file: File): Observable<{ imageUrl: string }> {
    const formData = new FormData();
    formData.append('image', file);
    return this.http.put<{ imageUrl: string }>(`${this.apiUrl}/profile/update-profile-image`, formData).pipe(
      catchError(error => {
        console.error('Image upload error:', error);
        const message = error.error?.message || 'Failed to upload image';
        return throwError(() => new Error(message));
      })
    );
  }

  updateNotificationSettings(settings: any): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/notification-settings`, settings);
  }

  getUserProfileImage(): Observable<{ profileImage: string }> {
    return this.http.get<{ profileImage: string }>(`${this.apiUrl}/profile/get-profile-image`);
  }

}