<div class="dashboard-main">
  <app-header></app-header>
  <div *ngIf="canAccess">
    <!-- Open Sidebar Button (Visible only when sidebar is closed) -->
    <button *ngIf="!sidebarOpen" (click)="toggleSidebar()" class="sidebar-open-btn">
        <iconify-icon icon="iconoir:arrow-right"></iconify-icon>
    </button>

    <div class="dashboard-main-body d-flex">
        
        <!-- Chat History Sidebar -->
        <div *ngIf="sidebarOpen" class="sidebar-container">
            <div class="card h-100 p-0">

                <!-- Sidebar Close Button -->
                <button type="button" (click)="toggleSidebar()" class="sidebar-close-btn"> 
                    <iconify-icon icon="radix-icons:cross-2"></iconify-icon>
                </button>

                <div class="card-body p-0">
                    <div class="p-24">
                        <button (click)="startNewChat()" 
                                [class.active]="!currentChat"
                                class="btn btn-primary text-sm btn-sm px-12 py-12 w-100 radius-8 d-flex align-items-center justify-content-center gap-2">
                            <i class="ri-messenger-line"></i>
                            New Chat
                        </button>
                    </div>

                    <div class="ai-chat-list scroll-sm pe-24 ps-24 pb-24">
                      <!-- Today's Chats -->
                      <div class="chat-history-item cursor-pointer mb-16" 
                      *ngFor="let session of groupedSessions['today']" 
                      [class.active]="currentChat?.id === session.id">
                   <div class="d-flex justify-content-between align-items-center">
                     <span class="text-line-1 text-secondary-light text-hover-primary-600 flex-grow-1" 
                           (click)="loadChat(session.id)">
                       {{ session.title }}
                     </span>
                     <app-chat-menu 
                       [sessionId]="session.id"
                       [sessionTitle]="session.title || ''"
                       (onRename)="handleRename($event)"
                       (onCopy)="copySession(session.id)"
                       (onDelete)="deleteSession(session.id)"
                     ></app-chat-menu>
                   </div>
                 </div>
                  
                      <!-- Yesterday's Chats -->
                      <div *ngIf="groupedSessions['yesterday'].length > 0">
                          <span class="text-primary-600 text-sm fw-semibold d-block mb-3 mt-4">Yesterday</span>
                          <div *ngFor="let session of groupedSessions['yesterday']"
                               class="chat-history-item cursor-pointer mb-16"
                               [class.active]="currentChat?.id === session.id">
                              <div class="d-flex justify-content-between align-items-center">
                                  <a class="text-line-1 text-secondary-light text-hover-primary-600 flex-grow-1"
                                     (click)="loadChat(session.id)">
                                      {{ session.title }}
                                  </a>
                                  <app-chat-menu
                                      [sessionId]="session.id"
                                      [sessionTitle]="session.title"
                                      (onDelete)="deleteSession(session.id)"
                                      (onCopy)="copySession(session.id)"
                                      (onRename)="handleRename($event)">
                                  </app-chat-menu>
                              </div>
                          </div>
                      </div>
                  
                      <!-- Previous Chats -->
                      <div *ngIf="groupedSessions['older'].length > 0">
                          <span class="text-primary-600 text-sm fw-semibold d-block mb-3 mt-4">Previous</span>
                          <div *ngFor="let session of groupedSessions['older']"
                               class="chat-history-item cursor-pointer mb-16"
                               [class.active]="currentChat?.id === session.id">
                              <div class="d-flex justify-content-between align-items-center">
                                  <div class="flex-grow-1" (click)="loadChat(session.id)">
                                      <a class="text-line-1 text-secondary-light text-hover-primary-600 d-block">
                                          {{ session.title }}
                                      </a>
                                      <span class="text-xs text-secondary-light d-block mt-1">
                                          {{ session.created_at | date:'MMM d, y' }}
                                      </span>
                                  </div>
                                  <app-chat-menu
                                      [sessionId]="session.id"
                                      [sessionTitle]="session.title"
                                      (onDelete)="deleteSession(session.id)"
                                      (onCopy)="copySession(session.id)"
                                      (onRename)="handleRename($event)">
                                  </app-chat-menu>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>

        <!-- Chat Main Area -->
        <div class="main-content flex-grow-1">
            <div class="chat-main card overflow-hidden">
                <!-- Chat Header -->
                <div class="chat-sidebar-single gap-8 justify-content-between cursor-default flex-nowrap">
                    <div class="d-flex align-items-center gap-16">
                        <h6 class="text-lg mb-0 text-line-1">{{currentChat?.title || 'New Chat'}}</h6>
                    </div>
                </div>

                <!-- Causal Loop Diagram Section -->
                <div class="row mb-4" *ngIf="causalLoopData">
                    <!-- Diagram Card -->
                    <div class="col-xxl-6 col-lg-6">
                        <div class="card h-100">
                            <div class="card-body">
                                <h6 class="card-title fw-semibold mb-3">
                                    {{causalLoopData.title}}
                                </h6>
                                <div class="diagram-wrapper position-relative">
                                    <div class="export-buttons">
                                        <button class="btn btn-primary btn-icon me-2" (click)="exportAsPDF()" title="Export as PDF">
                                            <iconify-icon icon="mdi:file-pdf-box" width="24" height="24"></iconify-icon>
                                        </button>
                                        <button class="btn btn-primary btn-icon" (click)="exportAsImage()" title="Export as Image">
                                            <iconify-icon icon="mdi:image" width="24" height="24"></iconify-icon>
                                        </button>
                                    </div>
                                    <div class="diagram-container">
                                        <div #diagramContainer id="causal-loop-diagram"></div>
                                        <div class="export-loading" *ngIf="isExporting">
                                            <div class="spinner-border text-primary">
                                                <span class="visually-hidden">Exporting...</span>
                                            </div>
                                            <div class="mt-2">Preparing {{exportType}}...</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Analysis Card -->
                    <div class="col-xxl-6 col-lg-6">
                        <div class="card h-100">
                            <div class="card-body">
                                <h6 class="card-title fw-semibold mb-3">Analysis</h6>
                                <p class="card-text" *ngIf="!isLoadingDiagram">
                                    {{causalLoopData.description}}
                                </p>
                                <div class="text-center" *ngIf="isLoadingDiagram">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Chat Messages -->
                <div class="chat-message-list max-h-612-px min-h-612-px position-relative">
                    <div *ngIf="messages.length === 0" 
                         class="welcome-section">
                        <h5 class="welcome-message">Hi {{username}}, Welcome to Scrum Team Agent</h5>
                        <span class="welcome-subtitle mt-16">Please enter your metrics below to get more accurate suggestions!</span>
                        <div class="row" style="align-self: center;">
                            <!-- Team Metrics Card -->
                            <div class="col-xxl-6">
                              <div class="card h-100 radius-8 border-0">
                                <div class="section-header" [class.open]="isTeamMetricsPanelOpen" (click)="toggleTeamMetricsPanel()">
                                    <h6 class="section-custom-title">Team Metrics</h6>
                                    <i class="ri-arrow-down-s-line"></i>
                                </div>
                                <div class="card-body p-24" [@slideInOut]="isTeamMetricsPanelOpen">
                                  <div class="row g-3">
                                    <!-- Replace the existing Sprint Velocity input with this -->
                                      <div class="col-md-12">
                                        <app-velocity-tags
                                          [velocities]="teamMetrics.sprintVelocities"
                                          (velocitiesChange)="updateSprintVelocities($event)">
                                        </app-velocity-tags>
                                      </div>
                                    <div class="col-md-6">
                                      <label class="form-label">Team Size</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="teamMetrics.teamSize"
                                        placeholder="Enter Team Size"
                                      >
                                    </div>

                                    <div class="col-md-6">
                                      <label class="form-label">Changing Team Member</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="teamMetrics.changingTeamMember"
                                        placeholder="Enter changing team member"
                                      >
                                    </div>

                                    <div class="col-md-12">
                                      <label class="form-label">Sprint Events</label>
                                      <div class="custom-multiselect position-relative">
                                        <div class="select-wrapper w-100">
                                          <div 
                                            class="form-control h-56-px d-flex justify-content-between align-items-center cursor-pointer"
                                            (click)="toggleDropdown()"
                                          >
                                            <span class="text-secondary-light">{{ getSelectedEventsText() }}</span>
                                            <i class="ri-arrow-down-s-line" [class.open]="isDropdownOpen"></i>
                                          </div>
                                          
                                          <div class="dropdown-menu w-100 show p-16" *ngIf="isDropdownOpen">
                                            <div class="dropdown-item py-8 px-16" *ngFor="let event of scrumEvents">
                                              <div class="form-check style-check d-flex align-items-center gap-2">
                                                <input 
                                                  type="checkbox" 
                                                  [id]="'event_' + event"
                                                  class="form-check-input border border-neutral-300"
                                                  [checked]="isEventSelected(event)"
                                                  (change)="toggleEvent(event)"
                                                >
                                                <label [for]="'event_' + event" class="form-check-label">
                                                  {{ event }}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                 
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                            <!-- DevOps Metrics Card -->
                            <div class="col-xxl-6">
                              <div class="card h-100 radius-8 border-0">
                                <div class="section-header" [class.open]="isDevOpsMetricsPanelOpen" (click)="toggleDevOpsMetricsPanel()">
                                    <h6 class="section-custom-title">DevOps Metrics</h6>
                                    <i class="ri-arrow-down-s-line"></i>
                                </div>
                                <div class="card-body p-24" [@slideInOut]="isDevOpsMetricsPanelOpen">
                                  <div class="row g-3">
                                    <div class="col-md-6">
                                      <label class="form-label">Deployment Frequency</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="devOpsMetrics.deploymentFrequency"
                                        placeholder="Enter frequency"
                                      >
                                    </div>
                                    <div class="col-md-6">
                                      <label class="form-label">Lead Time</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="devOpsMetrics.leadTime"
                                        placeholder="Enter lead time"
                                      >
                                    </div>
                                    <div class="col-md-6">
                                      <label class="form-label">Change Failure Rate</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="devOpsMetrics.changeFailureRate"
                                        placeholder="Enter failure rate"
                                      >
                                    </div>
                                    <div class="col-md-6">
                                      <label class="form-label">Mean Time to Recover</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="devOpsMetrics.meanTimeToRecover"
                                        placeholder="Enter MTTR"
                                      >
                                    </div>
                                    <div class="col-md-12">
                                      <label class="form-label">Automation Rate</label>
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        [(ngModel)]="devOpsMetrics.automationRate"
                                        placeholder="Enter automation rate"
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            <!-- Jira Metrics -->
                            
                      <div class="col-xxl-6 mt-3" >
                        <div class="card mb-4"  *ngIf="isJiraConnected">
                          <div class="section-header" [class.open]="isJiraProjectsPanelOpen" (click)="toggleJiraProjectsPanel()">
                              <h6 class="section-custom-title">Jira Project Selection</h6>
                              <i class="ri-arrow-down-s-line"></i>
                          </div>
                          <div class="card-body" [@slideInOut]="isJiraProjectsPanelOpen">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="mb-3">
                                  <label class="form-label">Select Project</label>
                                  <select 
                                    class="form-select" 
                                    [(ngModel)]="selectedProject"
                                    (change)="onProjectSelect($event)">
                                    <option value="">Choose a project...</option>
                                    <option *ngFor="let project of jiraProjects" [value]="project.id">
                                      {{project.name}} ({{project.key}})
                                    </option>
                                  </select>
                                </div>
                              </div>
                                <!-- Add the boards dropdown -->
                                <div class="col-md-6">
                                <div class="mb-3" *ngIf="selectedProject && projectBoards.length > 0">
                                  <label class="form-label">Select Board</label>
                                  <select 
                                    class="form-select" 
                                    [(ngModel)]="selectedBoard"
                                    (change)="onBoardSelect($event)">
                                    <option value="">Choose a board...</option>
                                    <option *ngFor="let board of projectBoards" [value]="board.id">
                                      {{board.name}} ({{board.type}})
                                    </option>
                                  </select>
                                </div>
                              </div>
                         
                              </div>
                            </div>
                          </div>
                        </div>
                               <!-- Add the metrics section here -->
                               <div class="mb-6" *ngIf="selectedBoard && boardMetrics">
                                <div class="card radius-8">
                                  <div class="section-header" [class.open]="isBoardMetricsPanelOpen" (click)="toggleBoardMetricsPanel()">
                                    <h6 class="section-custom-title">Board Metrics</h6>
                                    <i class="ri-arrow-down-s-line"></i>
                                  </div>
                                  <div class="card-body p-24" [@slideInOut]="isBoardMetricsPanelOpen">
                                    <!-- Rest of your existing board metrics content -->
                                    <div class="row g-4" *ngIf="!isLoadingMetrics">
                                      <!-- Team Overview Card -->
                                      <div class="col-md-4">
                                        <div class="card radius-8">
                                          <div class="card-body p-24">
                                            <h6 class="text-md fw-semibold mb-3">Team Overview</h6>
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                              <span class="text-secondary-light">Team Size</span>
                                              <span class="fw-semibold">{{boardMetrics.teamMemberCount}}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                              <span class="text-secondary-light">Planned vs Completed</span>
                                              <span class="fw-semibold">{{boardMetrics.averagePlannedVsCompleted | number:'1.0-1' }}%</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Sprint Metrics Card -->
                                      <div class="col-md-8">
                                        <div class="card radius-8 border-0">
                                          <div class="card-body p-24">
                                            <h6 class="text-md fw-semibold mb-3">Recent Sprints</h6>
                                            <div class="bordered-table ">
                                              <table class="table table-hover mb-0">
                                                <thead>
                                                  <tr>
                                                    <th class="text-secondary-light">Sprint</th>
                                                    <th class="text-secondary-light">Velocity</th>
                                                    <th class="text-secondary-light">Lead Time</th>
                                                    <th class="text-secondary-light">Completion</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let sprint of boardMetrics.velocityData">
                                                    <td>{{sprint.name}}</td>
                                                    <td>{{sprint.metrics.velocity}}</td>
                                                    <td>{{sprint.metrics.averageLeadTime | number:'1.0-1' }} days</td>
                                                    <td>{{sprint.metrics.plannedVsCompletedRate * 100 | number:'1.0-1' }}%</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- No Data Message -->
                                    <div class="text-center text-secondary-light p-24" 
                                         *ngIf="!isLoadingMetrics && (!boardMetrics.velocityData || boardMetrics.velocityData.length === 0)">
                                      <p class="mb-0">No sprint metrics available for this board.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                    </div>
                    <div class="chat-messages p-24">
                        <div *ngFor="let msg of messages" 
                             [ngClass]="{'user-message': msg.role === 'user', 'assistant-message': msg.role === 'assistant'}"
                             class="message-container mb-16">
                             <!-- AI Icon -->
                             <div class="message-icon" *ngIf="msg.role === 'assistant'">
                                <img src="assets/images/scrum/theme/logo/favicon.png" 
                                     alt="AI Assistant" 
                                     class="message-avatar"
                                     width="24">
                             </div>
                             <!-- User Icon -->
                             <div class="message-icon" *ngIf="msg.role === 'user'">
                                <img [src]="userProfileImageUrl || 'assets/images/user.png'"
                                     alt="User" 
                                     class="message-avatar"
                                     width="24">
                             </div>
                             <div class="message-content p-16 radius-8" [innerHTML]="msg.formattedContent"></div>
                        </div>
                    </div>
                </div>

                <!-- Chat Input -->
                <form class="chat-message-box" (ngSubmit)="sendMessage()" #chatForm="ngForm">
                    <!-- Add this near your chat input/submit area -->
<div class="d-flex gap-2 mt-3">
    <button 
      *ngIf="canShowDifferentApproach"
      (click)="getDifferentApproach()" 
      class="btn btn-secondary">
      Different Approach
    </button>
  </div>
                    <input type="text" 
                           name="chatMessage" 
                           [(ngModel)]="newMessage"
                           [disabled]="isLoading"
                           placeholder="Type your message here...">
                    <button type="submit" 
                            [disabled]="isLoading || !newMessage.trim()"
                            class="w-44-px h-44-px d-flex justify-content-center align-items-center radius-8 bg-primary-600 text-white bg-hover-primary-700 text-xl">
                        <iconify-icon [icon]="isLoading ? 'eos-icons:three-dots-loading' : 'f7:paperplane-fill'"></iconify-icon>
                    </button>
                </form>
            </div>
        </div>
    </div>
  </div>
  <div *ngIf="!canAccess" class="upgrade-prompt">
    <h3>Upgrade your plan</h3>
    <p>This feature requires a paid subscription</p>
    <button (click)="navigateToUpgrade()">Upgrade Now</button>
  </div>
    <footer class="d-footer">
        <div class="row align-items-center justify-content-between">
            <div class="col-auto">
                <p class="mb-0">© 2024 VDF AI. All Rights Reserved.</p>
            </div>
            <div class="col-auto"></div>
        </div>
    </footer>
</div>



