import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../../header/header.component';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from '../../../sidebar/sidebar.component';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'app-payment-success',
  standalone: true,
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TitleCasePipe,
    HeaderComponent,
    SidebarComponent,
    FooterComponent
  ]
})
export class PaymentSuccessComponent implements OnInit {
  orderId: string = '';
  planType: string = '';
  amount: number = 0;
  isLoading: boolean = true;
  error: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    // Get query parameters
    this.route.queryParams.subscribe({
      next: (params) => {
        this.orderId = params['order_id'] || '';
        this.planType = params['planType'] || '';
        this.amount = parseFloat(params['amount']) || 0;

        // Verify payment status
        if (this.orderId) {
          this.verifyPaymentStatus();
        } else {
          this.error = 'No order ID found';
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.error = 'Failed to load payment details';
        this.isLoading = false;
        console.error('Error loading parameters:', err);
      }
    });
  }

  private async verifyPaymentStatus() {
    try {
      const response = await this.subscriptionService.getPaymentStatus(this.orderId).toPromise();
      console.log('PaymentSuccessComponent: Verify Payment Started');
      if (response.success) {
        // Update user type if payment is successful
        if (response.data.status === 'completed') {
          await this.authService.updateUserType(this.planType as any).toPromise();
          this.isLoading = false;
        } else {
          this.error = 'Payment verification pending';
          this.isLoading = false;
        }
      } else {
        this.error = 'Failed to verify payment status';
        this.isLoading = false;
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      this.error = 'An error occurred while verifying payment';
      this.isLoading = false;
    }
  }

  goToDashboard() {
    this.router.navigate(['/app/dashboard']);
  }

  viewSubscription() {
    this.router.navigate(['/app/upgrade-plan'], { 
      queryParams: { 
        view: 'subscription' 
      }
    });
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
