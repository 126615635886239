import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-authenticated-app',
  standalone: true,
  templateUrl: './authenticated-app.component.html',
  styleUrl: './authenticated-app.component.css',
  imports: [RouterModule, SidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthenticatedAppComponent {
  constructor() {}
  ngOnInit() {
    // Make sure iconify is loaded
    if (!(window as any).Iconify) {
      console.warn('Iconify not loaded');
    }
  }
}
