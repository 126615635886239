import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeaderComponent } from '../shared/header/header.component';
import { UserProfile, UpdateUserProfileDto, PasswordUpdateDto } from '../interfaces/user.interface';
import { UserService } from '../services/user.service';
import { FooterComponent } from '../shared/footer/footer.component';
import { AuthService, User } from '../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, HeaderComponent, FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileComponent implements OnInit {
  userProfile?: UserProfile;
  isLoading = false;
  errorMessage = '';
  successMessage = '';
  
  profileForm!: FormGroup;
  passwordForm!: FormGroup;

  showCurrentPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  imageFile: File | null = null;
  imagePreviewUrl: string | null = null;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService
  ) {
    this.initializeForms();
  }

  ngOnInit(): void {
    this.loadUserProfile();
    this.loadUserProfileImage();
  }

  loadUserProfileImage(): void {
    this.userService.getUserProfileImage().subscribe({
      next: (response: { profileImage: string }) => {
        console.log('Profile image URL:', response.profileImage);
        this.imagePreviewUrl = response.profileImage;
      },
      error: (error) => {
        console.error('Error loading user profile image:', error);
        this.errorMessage = 'Unable to load profile image. Please try again later.';
      }
    });
  }

  private initializeForms(): void {
    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      title: [''],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(100)
      ]],
      bio: ['', [
        Validators.maxLength(500)
      ]]
    });

    this.passwordForm = this.fb.group({
      currentPassword: ['', [
        Validators.required,
        Validators.minLength(8)
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]],
      confirmPassword: ['', [
        Validators.required
      ]]
    }, {
      validators: [this.passwordMatchValidator]
    });
  }

  private passwordMatchValidator(g: FormGroup) {
    const newPassword = g.get('newPassword')?.value;
    const confirmPassword = g.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { mismatch: true };
  }

  private loadUserProfile(): void {
    console.log('loadUserProfile called');
    this.isLoading = true;
    this.userService.getUserProfile().subscribe({
      next: (response: any) => {
        if (response.success && response.data) {
          this.userProfile = {
            id: response.data.id,
            name: response.data.name,
            surname: response.data.surname,
            title: response.data.title,
            email: response.data.email,
            memberSince: new Date(response.data.memberSince),
            planName: response.data.planName || 'Free',
            bio: response.data.bio || '',
            profileImage: response.data.profileImage || 'assets/images/default-avatar.png'
          };

          this.profileForm.patchValue({
            name: response.data.name,
            surname: response.data.surname,
            title: response.data.title,
            email: response.data.email,
            bio: response.data.bio || ''
          });
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Profile error:', error);
        this.errorMessage = 'Unable to load profile. Please try again later.';
        this.isLoading = false;
      }
    });
  }

  onProfileSubmit(): void {
    if (this.profileForm.valid) {
      this.isLoading = true;
      const updateData: UpdateUserProfileDto = this.profileForm.value;
      
      if (this.imageFile) {
        this.userService.updateProfileImage(this.imageFile).subscribe({
          next: (response: { imageUrl: string }) => {
            updateData.profileImage = response.imageUrl;
            this.userService.updateUserProfile(updateData).subscribe({
              next: (profile: any) => {
                this.userProfile = {
                  id: profile.id,
                  name: profile.name,
                  surname: profile.surname,
                  title: profile.title,
                  email: profile.email,
                  memberSince: new Date(profile.memberSince),
                  planName: profile.planName || 'Free',
                  bio: profile.bio,
                  profileImage: profile.profileImage
                };
                this.successMessage = 'Profile updated successfully';
                this.isLoading = false;
                this.loadUserProfile();
              },
              error: (error) => {
                console.error('Profile update error:', error);
                this.errorMessage = 'Unable to update profile. Please try again later.';
                this.isLoading = false;
              }
            });
          },
          error: (error) => {
            console.error('Image upload error:', error);
            this.errorMessage = 'Unable to update profile image. Please try again later.';
            this.isLoading = false;
          }
        });
      } else {
        this.userService.updateUserProfile(updateData).subscribe({
          next: (profile: any) => {
            this.userProfile = {
              id: profile.id,
              name: profile.name,
              surname: profile.surname,
              title: profile.title,
              email: profile.email,
              memberSince: new Date(profile.memberSince),
              planName: profile.planName || 'Free',
              bio: profile.bio,
              profileImage: profile.profileImage
            };
            this.successMessage = 'Profile updated successfully';
            this.isLoading = false;
            this.loadUserProfile();
          },
          error: (error) => {
            console.error('Profile update error:', error);
            this.errorMessage = 'Unable to update profile. Please try again later.';
            this.isLoading = false;
          }
        });
      }
    }
  }

  onPasswordSubmit(): void {
    if (this.passwordForm.valid) {
      if (this.passwordForm.hasError('mismatch')) {
        this.errorMessage = 'New password and confirmation do not match';
        return;
      }

      this.isLoading = true;
      const passwordData: PasswordUpdateDto = {
        currentPassword: this.passwordForm.get('currentPassword')?.value,
        newPassword: this.passwordForm.get('newPassword')?.value,
        confirmPassword: this.passwordForm.get('confirmPassword')?.value,
        id: Number(this.userProfile?.id)
      };
      const userProfile = this.userProfile as unknown as User;

      this.authService.verifyUserPassword(passwordData.currentPassword).subscribe({
        next: (isCorrect: boolean) => {
          if (!isCorrect) {
            this.errorMessage = 'Current password is incorrect. Please try again.';
            this.isLoading = false;
            return;
          }

          this.userService.updatePassword(userProfile, passwordData).subscribe({
            next: () => {
              this.successMessage = 'Password updated successfully';
              this.passwordForm.reset();
              this.isLoading = false;
            },
            error: (error) => {
              console.error('Password update error:', error);
              this.errorMessage = 'Unable to update password. Please check your current password and try again.';
              this.isLoading = false;
            }
          });
        },
        error: (error) => {
          console.error('Password verification error:', error);
          this.errorMessage = 'Unable to verify current password. Please try again later.';
          this.isLoading = false;
        }
      });
    } else {
      this.errorMessage = 'Please fill all required fields correctly';
    }
  }

  onImageSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.imageFile = file;

      // Create a preview of the image
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  getErrorMessage(formGroup: FormGroup, controlName: string): string {
    const control = formGroup.get(controlName);
    if (control?.errors && control.touched) {
      if (control.errors['required']) {
        return `${controlName.charAt(0).toUpperCase() + controlName.slice(1)} is required`;
      }
      if (control.errors['email']) {
        return 'Please enter a valid email address';
      }
      if (control.errors['minlength']) {
        return `Minimum length is ${control.errors['minlength'].requiredLength} characters`;
      }
      if (control.errors['maxlength']) {
        return `Maximum length is ${control.errors['maxlength'].requiredLength} characters`;
      }
      if (control.errors['pattern']) {
        switch (controlName) {
          case 'fullName':
            return 'Only letters and spaces are allowed';
          case 'phoneNumber':
            return 'Please enter a valid phone number';
          case 'newPassword':
            return 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character';
          default:
            return 'Invalid format';
        }
      }
    }
    return '';
  }

  isFieldInvalid(formGroup: FormGroup, controlName: string): boolean {
    const control = formGroup.get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  toggleCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleNewPassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}