<script src="https://code.iconify.design/3/3.0.1/iconify.min.js"></script>
<body>
    <main class="dashboard-main">
      <div class="dashboard-main-body">
        <app-header></app-header>
        <div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
      <h6 class="fw-semibold mb-0">Welcome to VDF AI Enterprise Edition</h6>
      <ul class="d-flex align-items-center gap-2">
        <li class="fw-medium">
          <a href="index.html" class="d-flex align-items-center gap-1 hover-text-primary">
            <iconify-icon icon="solar:home-smile-angle-outline" class="icon text-lg"></iconify-icon>
            Value Delivery
          </a>
        </li>
        <li>-</li>
        <li class="fw-medium">Admin Dashboard</li>
      </ul>
    </div>
        
        <div class="row gy-4 mt-4">
    
            <!-- Crypto Home Widgets Start -->
            <div class="col-xxl-8">
                <div class="row gy-4">
                    <div class="col-12">
                        <div class="card h-100 radius-8 border-0">
                            <div class="card-body p-24">
                                <div class="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                                    <h6 class="mb-2 fw-bold text-lg">Usage Analytics</h6>
                                    
                                    <div class="d-flex flex-wrap align-items-center gap-4">
                                        <div class="form-check d-flex align-items-center">
                                            <input class="form-check-input" type="radio" name="crypto" id="BTC">
                                            <label class="form-check-label line-height-1 fw-medium text-secondary-light" for="BTC"> NAME 1 </label>
                                        </div>
                                        <div class="form-check d-flex align-items-center">
                                            <input class="form-check-input" type="radio" name="crypto" id="ETH">
                                            <label class="form-check-label line-height-1 fw-medium text-secondary-light" for="ETH"> NAME 2 </label>
                                        </div>
                                        <div class="form-check d-flex align-items-center">
                                            <input class="form-check-input" type="radio" name="crypto" id="SOL">
                                            <label class="form-check-label line-height-1 fw-medium text-secondary-light" for="SOL"> NAME 3 </label>
                                        </div>
                                        <div class="form-check d-flex align-items-center">
                                            <input class="form-check-input" type="radio" name="crypto" id="LTE">
                                            <label class="form-check-label line-height-1 fw-medium text-secondary-light" for="LTE"> NAME 4 </label>
                                        </div>
                                    </div>
                                    
                                    <select class="form-select form-select-sm w-auto bg-base border text-secondary-light">
                                        <option>Yearly</option>
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Today</option>
                                    </select>
                                </div>
            
                                <div class="d-flex align-items-center gap-2 mt-12">
                                    <h6 class="fw-semibold mb-0">500</h6>
                                    <p class="text-sm mb-0 d-flex align-items-center gap-1">
                                        NUMBER OF QUERIES
                                        <span class="bg-success-focus border border-success px-8 py-2 rounded-pill fw-semibold text-success-main text-sm d-inline-flex align-items-center gap-1">
                                            10%
                                            <iconify-icon icon="iconamoon:arrow-up-2-fill" class="icon"></iconify-icon>  
                                        </span> 
                                    </p>
                                </div>
            
                                <div id="candleStickChart"></div>
                            
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6">
                        <div class="card h-100 radius-8 border-0">
                            <div class="card-body p-24">
                                <div class="card-body p-24">
                                  <div class="w-64-px h-64-px d-inline-flex align-items-center justify-content-center bg-danger-600 text-white mb-16 radius-12">
                                    <iconify-icon icon="ph:code-fill" class="h5 mb-0"></iconify-icon> 
                                  </div>
                                    <h6 class="mb-8">Scrum Team Agent</h6>
                                    <p class="card-text mb-8 text-secondary-light">7/24 AI team consultant helps to improve your teams' performance.</p>
                                    <a href="javascript:void(0)" class="btn text-danger-600 hover-text-danger px-0 py-10 d-inline-flex align-items-center gap-2"> 
                                    Get Suggestions <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-xxl-6">
                        <div class="card h-100 radius-8 border-0">
                          <div class="card-body p-24">
                            <div class="card-body p-24">
                              <div class="w-64-px h-64-px d-inline-flex align-items-center justify-content-center bg-success-600 text-white mb-16 radius-12">
                                  <iconify-icon icon="fluent:toolbox-20-filled" class="h5 mb-0"></iconify-icon> 
                              </div>
                              <h6 class="mb-8">Decision Agent</h6>
                              <p class="card-text mb-8 text-secondary-light">Coming soon..</p>
                              <a href="javascript:void(0)" class="btn text-success-600 hover-text-success px-0 py-10 d-inline-flex align-items-center gap-2"> 
                              </a>
                          </div>                           
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6">
                      <div class="card h-100 radius-8 border-0">
                        <div class="card-body p-24">
                          <div class="card-body p-24">
                          <div class="w-64-px h-64-px d-inline-flex align-items-center justify-content-center bg-lilac-600 text-white mb-16 radius-12">
                              <iconify-icon icon="solar:medal-ribbons-star-bold" class="h5 mb-0"></iconify-icon> 
                          </div>
                          <h6 class="mb-8">Assessment Agent</h6>
                          <p class="card-text mb-8 text-secondary-light">Upload your team assessment and get assistance.</p>
                          <a href="javascript:void(0)" class="btn text-lilac-600 hover-text-lilac px-0 py-0 mt-16 d-inline-flex align-items-center gap-2"> 
                              Try Now <iconify-icon icon="iconamoon:arrow-right-2" class="text-xl"></iconify-icon> 
                          </a>
                      </div>
                    </div>
                      </div>
                  </div>
    
                  <div class="col-xxl-6">
                      <div class="card h-100 radius-8 border-0">
                        <div class="card-body p-24">
                          <div class="card-body p-24">
                            <div class="w-64-px h-64-px d-inline-flex align-items-center justify-content-center bg-primary-600 text-white mb-16 radius-12">
                                <iconify-icon icon="ri:computer-fill" class="h5 mb-0"></iconify-icon> 
                            </div>
                            <h6 class="mb-8">Transformation Agent</h6>
                            <p class="card-text mb-8 text-secondary-light">Coming soon ...</p>
                          </div>                        
                        </div>
                      </div>
                  </div>
                    
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title mb-0">Team</h5>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table colored-row-table mb-0">
                          <thead>
                            <tr>
                              <th scope="col" class="bg-base">Registered On</th>
                              <th scope="col" class="bg-base">Users</th>
                              <th scope="col" class="bg-base">Email</th>
                              <th scope="col" class="bg-base">Plan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="bg-primary-light">27 Mar 2024</td>
                              <td class="bg-primary-light">
                                <div class="d-flex align-items-center">
                                  <img src="assets/images/users/user1.png" alt="" class="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden">
                                  <h6 class="text-md mb-0 fw-medium flex-grow-1">Dianne Russell</h6>
                                </div>
                              </td>
                              <td class="bg-primary-light">random&#64;gmail.com</td>
                              <td class="bg-primary-light">Free</td>
                            </tr>
                            <tr>
                              <td class="bg-success-focus">27 Mar 2024</td>
                              <td class="bg-success-focus">
                                <div class="d-flex align-items-center">
                                  <img src="assets/images/users/user2.png" alt="" class="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden">
                                  <h6 class="text-md mb-0 fw-medium flex-grow-1">Wade Warren</h6>
                                </div>
                              </td>
                              <td class="bg-success-focus">random&#64;gmail.com</td>
                              <td class="bg-success-focus">Basic</td>
                            </tr>
                            <tr>
                              <td class="bg-info-focus">27 Mar 2024</td>
                              <td class="bg-info-focus">
                                <div class="d-flex align-items-center">
                                  <img src="assets/images/users/user3.png" alt="" class="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden">
                                  <h6 class="text-md mb-0 fw-medium flex-grow-1">Albert Flores</h6>
                                </div>
                              </td>
                              <td class="bg-info-focus">random&#64;gmail.com</td>
                              <td class="bg-info-focus">Standard </td>
                            </tr>
                            <tr>
                              <td class="bg-warning-focus">27 Mar 2024</td>
                              <td class="bg-warning-focus">
                                <div class="d-flex align-items-center">
                                  <img src="assets/images/users/user4.png" alt="" class="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden">
                                  <h6 class="text-md mb-0 fw-medium flex-grow-1">Bessie Cooper</h6>
                                </div>
                              </td>
                              <td class="bg-warning-focus">random&#64;gmail.com</td>
                              <td class="bg-warning-focus">Business </td>
                            </tr>
                            <tr>
                              <td class="bg-danger-focus">27 Mar 2024</td>
                              <td class="bg-danger-focus">
                                <div class="d-flex align-items-center">
                                  <img src="assets/images/users/user5.png" alt="" class="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden">
                                  <h6 class="text-md mb-0 fw-medium flex-grow-1">Arlene McCoy</h6>
                                </div>
                              </td>
                              <td class="bg-danger-focus">random&#64;gmail.com</td>
                              <td class="bg-danger-focus">Enterprise </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div><!-- card end -->
    
                    <div class="col-xxl-12">
                        <div class="card h-100">
                            <div class="card-body p-24">
                                <div class="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
                                    <h6 class="mb-2 fw-bold text-lg mb-0">Recent Payments</h6>
                                    <a href="javascript:void(0)" class="text-primary-600 hover-text-primary d-flex align-items-center gap-1">
                                      View All
                                      <iconify-icon icon="solar:alt-arrow-right-linear" class="icon"></iconify-icon>
                                    </a>
                                </div>
                                <div class="table-responsive scroll-sm">
                                    <table class="table bordered-table mb-0 xsm-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Licence</th>
                                                <th scope="col">Date & Time</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Card</th>
                                                <th scope="col" class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
    
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center gap-2">
                                                        <span class="text-success-main bg-success-focus w-32-px h-32-px d-flex align-items-center justify-content-center rounded-circle text-xl">
                                                            <iconify-icon icon="tabler:arrow-up-right" class="icon"></iconify-icon>
                                                        </span>
                                                        <span class="fw-medium">5 Members</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">27 Mar 2024</span>
                                                    <span class="text-secondary-light text-sm">10:34 AM</span>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">$3,480.90</span>
                                                </td>
                                                <td>Abc.........np562</td>
                                                <td class="text-center"> 
                                                    <span class="bg-success-focus text-success-main px-16 py-4 radius-4 fw-medium text-sm">Completed</span> 
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center gap-2">
                                                        <span class="text-danger-main bg-danger-focus w-32-px h-32-px d-flex align-items-center justify-content-center rounded-circle text-xl">
                                                            <iconify-icon icon="tabler:arrow-down-left" class="icon"></iconify-icon>
                                                        </span>
                                                        <span class="fw-medium">5 Members</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">27 Mar 2024</span>
                                                    <span class="text-secondary-light text-sm">10:34 AM</span>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">$3,480.90</span>
                                                </td>
                                                <td>Abc.........np562</td>
                                                <td class="text-center"> 
                                                    <span class="bg-danger-focus text-danger-main px-16 py-4 radius-4 fw-medium text-sm">Terminated</span> 
                                                </td>
                                            </tr>
    
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center gap-2">
                                                        <span class="text-success-main bg-success-focus w-32-px h-32-px d-flex align-items-center justify-content-center rounded-circle text-xl">
                                                            <iconify-icon icon="tabler:arrow-up-right" class="icon"></iconify-icon>
                                                        </span>
                                                        <span class="fw-medium">5 Members</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">27 Mar 2024</span>
                                                    <span class="text-secondary-light text-sm">10:34 AM</span>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">$3,480.90</span>
                                                </td>
                                                <td>Abc.........np562</td>
                                                <td class="text-center"> 
                                                    <span class="bg-success-focus text-success-main px-16 py-4 radius-4 fw-medium text-sm">Completed</span> 
                                                </td>
                                            </tr>
    
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center gap-2">
                                                        <span class="text-danger-main bg-danger-focus w-32-px h-32-px d-flex align-items-center justify-content-center rounded-circle text-xl">
                                                            <iconify-icon icon="tabler:arrow-down-left" class="icon"></iconify-icon>
                                                        </span>
                                                        <span class="fw-medium">4 Members</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">27 Mar 2024</span>
                                                    <span class="text-secondary-light text-sm">10:34 AM</span>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">$3,480.90</span>
                                                </td>
                                                <td>Abc.........np562</td>
                                                <td class="text-center"> 
                                                    <span class="bg-danger-focus text-danger-main px-16 py-4 radius-4 fw-medium text-sm">Terminated</span> 
                                                </td>
                                            </tr>
    
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center gap-2">
                                                        <span class="text-success-main bg-success-focus w-32-px h-32-px d-flex align-items-center justify-content-center rounded-circle text-xl">
                                                            <iconify-icon icon="tabler:arrow-up-right" class="icon"></iconify-icon>
                                                        </span>
                                                        <span class="fw-medium">4 Members</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">27 Mar 2024</span>
                                                    <span class="text-secondary-light text-sm">10:34 AM</span>
                                                </td>
                                                <td>
                                                    <span class="text-primary-light d-block fw-medium">$3,480.90</span>
                                                </td>
                                                <td>Abc.........np562</td>
                                                <td class="text-center"> 
                                                    <span class="bg-success-focus text-success-main px-16 py-4 radius-4 fw-medium text-sm">Completed</span> 
                                                </td>
                                            </tr>
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Crypto Home Widgets End -->
    
            
    
            <div class="col-xxl-4">
                <div class="row gy-4">
                  <div class="col-xxl-12 col-lg-6">
                    <div class="card h-100 radius-8 border-0">
                      <div class="card h-100 p-0">
                        <div class="card-header border-bottom bg-base py-16 px-24">
                        <h6 class="text-lg fw-semibold mb-0">Share Your Feedback</h6>
                        </div>
                        <div class="card-body p-24">
                          <label class="form-label">We value your input! Your feedback helps us improve and serve you better.</label>
                            <ul class="d-flex flex-wrap align-items-center gap-12">
                                <li class="text-warning-600 text-2xxl line-height-1"><iconify-icon icon="material-symbols:star"></iconify-icon></li>
                                <li class="text-warning-600 text-2xxl line-height-1"><iconify-icon icon="material-symbols:star"></iconify-icon></li>
                                <li class="text-warning-600 text-2xxl line-height-1"><iconify-icon icon="material-symbols:star"></iconify-icon></li>
                                <li class="text-warning-600 text-2xxl line-height-1"><iconify-icon icon="material-symbols:star"></iconify-icon></li>
                                <li class="text-warning-600 text-2xxl line-height-1"><iconify-icon icon="material-symbols:star"></iconify-icon></li>
                            </ul>
                        </div>
                        <div class="card-body">
                              <textarea name="#0" class="form-control"  cols="50" placeholder="Enter your feedback..."></textarea>
                              <br>
                              <a href="" class="btn btn-primary text-sm btn-sm px-8 py-12 w-100 radius-8"> Submit</a>
                        </div>
                    </div>
                        
                        
                    </div>
                </div>
                <div class="col-xxl-12 col-lg-6">
                  <div class="card h-100 radius-8 border-0">
    
                    <div class="card-body p-24">
                      <div class="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                          <h6 class="mb-2 fw-bold text-lg">Overall User Activates</h6>
                          <div class="">
                          <select class="form-select form-select-sm w-auto bg-base border text-secondary-light">
                              <option>This Week</option>
                              <option>This Month</option>
                              <option>This Year</option>
                          </select>
                          </div>
                      </div>
    
                      <div class="position-relative">
                          <span class="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute end-0 top-0 z-1">+30%</span>
                          <div id="statisticsDonutChart" class="mt-36 flex-grow-1 apexcharts-tooltip-z-none title-style circle-none"></div>
                          <span class="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute start-0 bottom-0 z-1">+25%</span>
                      </div>
                      
                      <ul class="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-3">
                          <li class="d-flex align-items-center gap-2">
                              <span class="w-12-px h-12-px radius-2 bg-primary-600"></span>
                              <span class="text-secondary-light text-sm fw-normal">Visits By Day:  
                                  <span class="text-primary-light fw-bold">200</span>
                              </span>
                          </li>
                          <li class="d-flex align-items-center gap-2">
                              <span class="w-12-px h-12-px radius-2 bg-yellow"></span>
                              <span class="text-secondary-light text-sm fw-normal">Total Query:  
                                  <span class="text-primary-light fw-bold">2000</span>
                              </span>
                          </li>
                      </ul>
                 
                  </div>
                   
                </div>    
                  </div>
                    <div class="col-xxl-12 col-lg-6">
                        <div class="card h-100 radius-8 border-0">
                            <div class="card-body">
                                <div class="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
                                    <h6 class="mb-2 fw-bold text-lg">Enterprise Cards</h6>
                                    <a href="" class="btn btn-outline-primary d-inline-flex align-items-center gap-2 text-sm btn-sm px-8 py-6"> 
                                    <iconify-icon icon="ph:plus-circle" class="icon text-xl"></iconify-icon> Button
                                </a>
                                </div>
                                
                                <div>
                                    <div class="card-slider">
                                        <div class="p-20 h-240-px radius-8 overflow-hidden position-relative z-1">
                                            <img src="assets/images/card/card-bg.png" alt="" class="position-absolute start-0 top-0 w-100 h-100 object-fit-cover z-n1">
            
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                                    <h6 class="text-white mb-0">Master Card</h6>
                                                    <img src="assets/images/card/card-logo.png" alt="">
                                                </div>
                
                                                <div>
                                                    <span class="text-white text-xs fw-normal text-opacity-75">Credit Card Number</span>
                                                    <h6 class="text-white text-xl fw-semibold mt-1 mb-0">2356 9854 3652 5612</h6>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span class="text-white text-xs fw-normal text-opacity-75">Name</span>
                                                        <h6 class="text-white text-xl fw-semibold mb-0">Arlene McCoy</h6>
                                                    </div>
                                                    <div>
                                                        <span class="text-white text-xs fw-normal text-opacity-75">Exp. Date</span>
                                                        <h6 class="text-white text-xl fw-semibold mb-0">05/26</h6>
                                                    </div>
            
                                                </div>
                                            </div>
            
                                        </div>
                                        <div class="p-20 h-240-px radius-8 overflow-hidden position-relative z-1">
                                            <img src="assets/images/card/card-bg.png" alt="" class="position-absolute start-0 top-0 w-100 h-100 object-fit-cover z-n1">
            
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                                    <h6 class="text-white mb-0">Master Card</h6>
                                                    <img src="assets/images/card/card-logo.png" alt="">
                                                </div>
                
                                                <div>
                                                    <span class="text-white text-xs fw-normal text-opacity-75">Credit Card Number</span>
                                                    <h6 class="text-white text-xl fw-semibold mt-1 mb-0">2356 9854 3652 5612</h6>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span class="text-white text-xs fw-normal text-opacity-75">Name</span>
                                                        <h6 class="text-white text-xl fw-semibold mb-0">Arlene McCoy</h6>
                                                    </div>
                                                    <div>
                                                        <span class="text-white text-xs fw-normal text-opacity-75">Exp. Date</span>
                                                        <h6 class="text-white text-xl fw-semibold mb-0">05/26</h6>
                                                    </div>
            
                                                </div>
                                            </div>
            
                                        </div>
                                        <div class="p-20 h-240-px radius-8 overflow-hidden position-relative z-1">
                                            <img src="assets/images/card/card-bg.png" alt="" class="position-absolute start-0 top-0 w-100 h-100 object-fit-cover z-n1">
            
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                                    <h6 class="text-white mb-0">Master Card</h6>
                                                    <img src="assets/images/card/card-logo.png" alt="">
                                                </div>
                
                                                <div>
                                                    <span class="text-white text-xs fw-normal text-opacity-75">Credit Card Number</span>
                                                    <h6 class="text-white text-xl fw-semibold mt-1 mb-0">2356 9854 3652 5612</h6>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span class="text-white text-xs fw-normal text-opacity-75">Name</span>
                                                        <h6 class="text-white text-xl fw-semibold mb-0">Arlene McCoy</h6>
                                                    </div>
                                                    <div>
                                                        <span class="text-white text-xs fw-normal text-opacity-75">Exp. Date</span>
                                                        <h6 class="text-white text-xl fw-semibold mb-0">05/26</h6>
                                                    </div>
            
                                                </div>
                                            </div>
            
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                    </div>
                    <div class="col-xxl-12 col-lg-6">
                        <div class="card h-100">
                            <div class="card-body p-24">
                                <span class="mb-4 text-sm text-secondary-light">Internal Systems'</span>
                                <h6 class="mb-4">INTEGRATIONS</h6>
    
                                <ul class="nav nav-pills pill-tab mb-24 mt-28 border input-form-light p-1 radius-8 bg-neutral-50" id="pills-tab" role="tablist">
                                    <li class="nav-item w-50" role="presentation">
                                      <button class="nav-link px-12 py-10 text-md w-100 text-center radius-8 active" id="pills-Buy-tab" data-bs-toggle="pill" data-bs-target="#pills-Buy" type="button" role="tab" aria-controls="pills-Buy" aria-selected="true">Jira/TFS</button>
                                    </li>
                                    <li class="nav-item w-50" role="presentation">
                                      <button class="nav-link px-12 py-10 text-md w-100 text-center radius-8" id="pills-Sell-tab" data-bs-toggle="pill" data-bs-target="#pills-Sell" type="button" role="tab" aria-controls="pills-Sell" aria-selected="false">Git</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-Buy" role="tabpanel" aria-labelledby="pills-Buy-tab" tabindex="0">
                                        <div class="mb-20">  
                                            <label for="estimatedValue" class="fw-semibold mb-8 text-primary-light">Project Space</label>
                                            <div class="input-group input-group-lg border input-form-light radius-8">
                                                <input type="text" class="form-control bg-base border-0 radius-8" id="estimatedValue" placeholder="Project Space">
                                                <div class="input-group-text bg-neutral-50 border-0 fw-normal text-md ps-1 pe-1">
                                                    <select class="form-select form-select-sm w-auto bg-transparent fw-bolder border-0 text-secondary-light">
                                                        <option class="bg-base">Project 1</option>
                                                        <option class="bg-base">Project 2</option>
                                                        <option class="bg-base">Project 3</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-20">  
                                            <label for="tradeValue" class="fw-semibold mb-8 text-primary-light">Team Dashboard</label>
                                            <div class="input-group input-group-lg border input-form-light radius-8">
                                                <input type="text" class="form-control bg-base border-0 radius-8" id="tradeValue" placeholder="Team Dashboard">
                                                <div class="input-group-text bg-neutral-50 border-0 fw-normal text-md ps-1 pe-1">
                                                    <select class="form-select form-select-sm w-auto bg-transparent fw-bolder border-0 text-secondary-light">
                                                      <option class="bg-base">Team 1</option>
                                                      <option class="bg-base">Team 2</option>
                                                      <option class="bg-base">Team 3</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-20">  
                                            <label class="fw-semibold mb-8 text-primary-light">OAuth Credentials</label>
                                              <input type="text" name="#0" class="form-control" placeholder="Username">
                                              <br>
                                              <input type="text" name="#0" class="form-control" placeholder="password">
                                        </div>
                                        <div class="mb-24">  
                                            <span class="mb-4 text-sm text-secondary-light">Last Update</span>
                                            <h6 class="mb-4 fw-semibold text-xl text-warning-main">31.01.2024</h6>
                                        </div>
                                        <a href="" class="btn btn-primary text-sm btn-sm px-8 py-12 w-100 radius-8">Update Now</a>
                                    </div>
                                    <div class="tab-pane fade" id="pills-Sell" role="tabpanel" aria-labelledby="pills-Sell-tab" tabindex="0">
                                        <div class="mb-20">  
                                            <label for="estimatedValueSell" class="fw-semibold mb-8 text-primary-light">Estimated Purchase Value</label>
                                            <div class="input-group input-group-lg border input-form-light radius-8">
                                                <input type="text" class="form-control border-0 radius-8" id="estimatedValueSell" placeholder="Estimated Value">
                                                <div class="input-group-text bg-neutral-50 border-0 fw-normal text-md ps-1 pe-1">
                                                    <select class="form-select form-select-sm w-auto bg-transparent fw-bolder border-0 text-secondary-light">
                                                        <option>BTC</option>
                                                        <option>LTC</option>
                                                        <option>USD</option>
                                                        <option>ETC</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-20">  
                                            <label for="tradeValueSell" class="fw-semibold mb-8 text-primary-light">Trade Value</label>
                                            <div class="input-group input-group-lg border input-form-light radius-8">
                                                <input type="text" class="form-control border-0 radius-8" id="tradeValueSell" placeholder="Trade Value">
                                                <div class="input-group-text bg-neutral-50 border-0 fw-normal text-md ps-1 pe-1">
                                                    <select class="form-select form-select-sm w-auto bg-transparent fw-bolder border-0 text-secondary-light">
                                                        <option>BTC</option>
                                                        <option>LTC</option>
                                                        <option>USD</option>
                                                        <option>ETC</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-20">  
                                            <label class="fw-semibold mb-8">Trade Value</label>
                                            <textarea class="form-control h-80-px radius-8" placeholder="Enter Address"></textarea>
                                        </div>
                                        <div class="mb-24">  
                                            <span class="mb-4 text-sm text-secondary-light">Total Balance</span>
                                            <h6 class="mb-4 fw-semibold text-xl text-warning-main">$320,320.00</h6>
                                        </div>
                                        <a href="" class="btn btn-primary text-sm btn-sm px-8 py-12 w-100 radius-8"> Transfer Now</a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
    
      <footer class="d-footer">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <p class="mb-0">© 2024 VDF AI. All Rights Reserved.</p>
        </div>
        <div class="col-auto">
          
        </div>
      </div>
    </footer>
    </main>
      
      <!-- jQuery library js -->
      <script src="assets/js/lib/jquery-3.7.1.min.js"></script>
      <!-- Bootstrap js -->
      <script src="assets/js/lib/bootstrap.bundle.min.js"></script>
      <!-- Apex Chart js -->
      <!-- <script src="assets/js/lib/apexcharts.min.js"></script> -->
      <script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
      <!-- Data Table js -->
      <script src="assets/js/lib/dataTables.min.js"></script>
      <!-- Iconify Font js -->
      <script src="assets/js/lib/iconify-icon.min.js"></script>
      <!-- jQuery UI js -->
      <script src="assets/js/lib/jquery-ui.min.js"></script>
      <!-- Vector Map js -->
      <script src="assets/js/lib/jquery-jvectormap-2.0.5.min.js"></script>
      <script src="assets/js/lib/jquery-jvectormap-world-mill-en.js"></script>
      <!-- Popup js -->
      <script src="assets/js/lib/magnifc-popup.min.js"></script>
      <!-- Slick Slider js -->
      <script src="assets/js/lib/slick.min.js"></script>
      <!-- prism js -->
      <script src="assets/js/lib/prism.js"></script>
      <!-- file upload js -->
      <script src="assets/js/lib/file-upload.js"></script>
      <!-- audioplayer -->
      <script src="assets/js/lib/audioplayer.js"></script>
      
      <!-- main js -->
      <script src="assets/js/app.js"></script>
    
    <script src="assets/js/homeFourChart.js"></script>
    
    </body>