import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { RegistrationService } from './registration.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private apiUrl = `${environment.apiUrl}/registration`;
  private errorMessage: string = '';
  private isSubmitting: boolean = false;

  constructor(private http: HttpClient, private authService: AuthService, private registrationService: RegistrationService) {
    console.log('CompanyService initialized with apiUrl:', this.apiUrl);
  }

  completeRegistration(email: string, companyDetails: any): Observable<any> {
    const payload = {
      email: this.authService.getUserEmail(),
      companyDetails: {
        company: companyDetails.company,
        billing: companyDetails.billing,
        teamMembers: companyDetails.teamMembers
      }
    };

    console.log('CompanyService: Sending registration completion request to:', `${this.apiUrl}/complete`);
    console.log('CompanyService: Payload:', JSON.stringify(payload, null, 2));

    return this.http.post(`${this.apiUrl}/complete`, payload).pipe(
      tap(response => {
        console.log('CompanyService: Registration completion successful:', response);
      }),
      catchError(error => {
        console.error('CompanyService: Registration completion failed:', error);
        if (error.error) {
          console.error('Server error details:', error.error);
        }
        throw error;
      })
    );
  }

  registerTeamMembers(teamMembers: any[], companyId: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/register-team-members`, { 
        teamMembers,
        companyId 
    });
  }

  checkTeamMembersEmails(teamMembers: Array<{ email: string }>): Promise<boolean> {
    let allEmailsAvailable = true;
    let checkedEmailsCount = 0;

    return new Promise<boolean>((resolve, reject) => {
      teamMembers.forEach((member) => {
        this.registrationService.checkEmailExists(member.email).subscribe({
          next: (response) => {
            checkedEmailsCount++;
            if (!response.success) {
              // Email exists, show error message and stop further processing
              this.errorMessage = `Email ${member.email} already exists`;
              allEmailsAvailable = false;
              this.isSubmitting = false;
              resolve(false); // Return false if any email exists
            }

            // If all emails have been checked, proceed based on availability
            if (checkedEmailsCount === teamMembers.length && allEmailsAvailable) {
              resolve(true); // Return true if all emails are available
            }
          },
          error: (error) => {
            checkedEmailsCount++;
            this.errorMessage = error.message || `Failed to check email for ${member.email}`;
            this.isSubmitting = false;
            console.error('Error checking email:', error);

            // If all emails have been checked, ensure submission is stopped
            if (checkedEmailsCount === teamMembers.length) {
              resolve(false); // Return false if there was an error
            }
          }
        });
      });
    });
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }

  validateTokenAndSetupPassword(token: string, password: string): Observable<any> {
    return this.http.post('/api/registration/validate-token', { token, password });
  }
  getCompanyDetails(companyId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/company-details/${companyId}`).pipe(
      tap(response => {
        console.log('CompanyService: Fetched company details:', response);
      }),
      catchError(error => {
        console.error('CompanyService: Failed to fetch company details:', error);
        throw error;
      })
    );
  }

  updateCompanyDetails(details: any): Observable<any> {
    const payload = {
      ...details,
      companyId: details.companyId
    };
    console.log('company-service: Updating company details:', payload);
    return this.http.put(`${this.apiUrl}/update-company`, payload).pipe(
      tap(response => {
        console.log('CompanyService: Company details updated successfully:', response);
      }),
      catchError(error => {
        console.error('CompanyService: Failed to update company details:', error);
        throw error;
      })
    );
  }
} 