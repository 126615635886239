
<app-sidebar></app-sidebar>   
<div class="dashboard">

 <div class="dashboard-main">
  <app-header></app-header>
  <div class="container">    
    <div class="dashboard-main-body">
    <!-- Header -->
    <div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
      <h6 class="fw-semibold mb-0">Payment Details</h6>
      <ul class="d-flex align-items-center gap-2">
        <li class="fw-medium">
          <a routerLink="/register/packages" class="hover-text-primary">
            Last Step
          </a>
        </li>
        <li>:</li>
        <li class="fw-medium">Confirm Your Plan</li>
      </ul>
    </div>

    <!-- Main Content -->
    <div class="row">
      <!-- Payment Form -->
      <div class="col-lg-8">
        <div class="card h-100 p-0 radius-12">
          <div class="card-body p-40">
            <!-- Plan Summary -->
            <div class="bg-neutral-50 p-24 radius-8 mb-32">
              <h6 class="mb-16">Plan Summary</h6>
              <div class="d-flex justify-content-between align-items-center mb-12">
                <span class="text-secondary-light">Selected Plan</span>
                <span class="fw-semibold">{{ selectedPlan | titlecase }}</span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-12">
                <span class="text-secondary-light">Billing Cycle</span>
                <div class="form-check form-switch d-flex align-items-center gap-3">
                  <label class="form-check-label">Monthly</label>
                  <input 
                    type="checkbox" 
                    class="form-check-input" 
                    role="switch" 
                    [checked]="isAnnualBilling"
                    (change)="toggleBillingCycle()">
                  <label class="form-check-label">Annual (Save 17%)</label>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-secondary-light">Total Amount</span>
                <span class="fw-semibold text-lg">
                  {{ formatCurrency(calculateTotal()) }}
                </span>
              </div>
            </div>

            <!-- Payment Form -->
            <form [formGroup]="paymentForm">
              <div formGroupName="billingAddress">
                <h6 class="mb-24">Billing Address</h6>
                <div class="row">
                  <div class="col-12 mb-20">
                    <label class="form-label">Select Country</label>
                    <div class="form-group position-relative">
                      <input 
                        id="country" 
                        type="text" 
                        [formControl]="countrySearchControl" 
                        class="form-control" 
                        placeholder="Type to search..." 
                        (focus)="showDropdown = true" 
                        (input)="showDropdown = true" 
                        (blur)="hideDropdown()">
                      <ul 
                        class="dropdown-menu show" 
                        *ngIf="showDropdown && filteredCountries().length > 0" 
                        style="width: 100%; max-height: 200px; overflow-y: auto; position: absolute; z-index: 1000;">
                        <li 
                          *ngFor="let country of filteredCountries()" 
                          (mousedown)="selectCountry(country)" 
                          class="dropdown-item">
                          {{ country.name }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-12 mb-20">
                    <label class="form-label">Address</label>
                    <input 
                      type="text" 
                      class="form-control"
                      formControlName="address"
                      [class.is-invalid]="isFieldInvalid('billingAddress.address')"
                      placeholder="Street address">
                  </div>

                  <div class="col-sm-6 mb-20">
                    <label class="form-label">City</label>
                    <input 
                      type="text" 
                      class="form-control"
                      formControlName="city"
                      [class.is-invalid]="isFieldInvalid('billingAddress.city')"
                      placeholder="City">
                  </div>

                  <div class="col-sm-6 mb-20">
                    <label class="form-label">Postal Code</label>
                    <input 
                      type="text" 
                      class="form-control"
                      formControlName="postalCode"
                      [class.is-invalid]="isFieldInvalid('billingAddress.postalCode')"
                      placeholder="Postal code">
                  </div>

                  <div class="col-12 mb-20">
                    <label class="form-label">Cardholder Name</label>
                    <input 
                      type="text" 
                      class="form-control"
                      formControlName="cardholderName"
                      [class.is-invalid]="isFieldInvalid('billingAddress.cardholderName')"
                      placeholder="Enter full name as it appears on card">
                    <div class="invalid-feedback" *ngIf="isFieldInvalid('billingAddress.cardholderName')">
                      Please enter your full name (at least first and last name)
                    </div>
                  </div>
                </div>
              </div>

              <!-- Error Message -->
              <div class="alert alert-danger mt-24" *ngIf="errorMessage">
                {{errorMessage}}
              </div>

              <!-- Credit Card Details -->
              <div class="revolut-card-container">
                <div id="revolut-card-field"></div>
                <button id="button-submit" 
                        type="button" 
                        class="btn btn-primary mt-3"
                        [disabled]="isSubmitting">
                  {{ isSubmitting ? 'Processing...' : 'Submit Payment' }}
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>

      <!-- Order Summary -->
      <div class="col-lg-4">
        <div class="card h-100 p-0 radius-12">
          <div class="card-body p-24">
            <h6 class="mb-24">Order Summary</h6>
            
            <!-- Plan Details -->
            <div class="mb-16">
              <div class="d-flex justify-content-between mb-8">
                <span class="text-secondary-light">{{ selectedPlan }} Plan</span>
                <span>{{ formatCurrency(getPlanPrice()) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-8" *ngIf="isAnnualBilling">
                <span class="text-secondary-light">Annual Discount (17%)</span>
                <span class="text-success">-{{ formatCurrency(getDiscount()) }}</span>
              </div>
            </div>

            <hr class="my-16">

            <!-- Total -->
            <div class="d-flex justify-content-between align-items-center">
              <span class="fw-semibold">Total</span>
              <span class="text-lg fw-semibold">{{ formatCurrency(calculateTotal()) }}</span>
            </div>

            <!-- Additional Info -->
            <div class="mt-24 bg-neutral-50 p-16 radius-8">
              <h6 class="mb-12 text-sm">What's included:</h6>
              <ul class="list-unstyled mb-0">
                <li class="d-flex align-items-center gap-2 mb-8" 
                    *ngFor="let feature of getPlanFeatures()">
                  <iconify-icon
                    icon="heroicons:check-circle"
                    class="text-success">
                  </iconify-icon>
                  <span class="text-sm">{{ feature }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="alert alert-success mt-24" *ngIf="successMessage">
  {{successMessage}}
</div>
<!-- Payment Processing Modal -->
<div class="modal" [class.show]="showProcessingModal" *ngIf="showProcessingModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center p-32">
        <div class="mb-24">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Processing payment...</span>            
          </div>
        </div>
        <h5 class="mb-8">Processing Payment</h5>
        <p class="text-secondary-light mb-0">
          Please do not close this window while we process your payment...
        </p>
      </div>
    </div>
  </div>
</div>

</div>
<app-footer></app-footer> 
</div>
