// src/app/services/registration.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, catchError, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { RegistrationData, SocialRegistrationData, RegistrationResponse } from '../interfaces/registration.interface';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private apiUrl = `${environment.apiUrl}/registration`;
  private registrationDataSubject = new BehaviorSubject<RegistrationData | null>(null);

  constructor(private http: HttpClient) {}

  sendVerificationEmail(email: string, name: string, surname: string, password: string): Observable<any> {
    console.log('sendVerificationEmail: Sending email to', email);
    this.storeRegistrationData({ email, name, surname, password } as RegistrationData);

    return this.http.post(`${environment.apiUrl}/verification/send-code`, { 
        email, 
        name,
        surname,
        password 
    }).pipe(
        catchError((error: any) => {
            console.error('sendVerificationEmail: Error sending verification email:', error);
            return throwError(() => new Error(error.error?.message || 'Failed to send verification email'));
        })
    );
  }

  verifyCode(email: string, code: string): Observable<any> {
    console.log('verifyCode: Verifying code for email', email);
    return this.http.post(`${environment.apiUrl}/registration/verification/verify-code`, {
      email,
      code
    }).pipe(
      catchError((error: any) => {
        console.error('verifyCode: Error verifying code:', error);
        return throwError(() => new Error(error.error?.message || 'Failed to verify code'));
      })
    );
  }

  resendVerificationCode(email: string): Observable<RegistrationResponse> {
    return this.http.post<RegistrationResponse>(`${environment.apiUrl}/verification/resend-code`, { email });
  }

  completeRegistration(data: RegistrationData): Observable<RegistrationResponse> {
    console.log('completeRegistration: Completing registration with data', data);
    return this.http.post<RegistrationResponse>(`${this.apiUrl}/complete`, data).pipe(
      tap(response => console.log('completeRegistration: Response received', response)),
      catchError((error: any) => {
        console.error('completeRegistration: Error completing registration:', error);
        return throwError(() => new Error(error.error?.message || 'Failed to complete registration'));
      })
    );
  }

  completeSocialRegistration(data: SocialRegistrationData): Observable<RegistrationResponse> {
    return this.http.post<RegistrationResponse>(`${this.apiUrl}/complete-social`, data);
  }

  storeRegistrationData(data: RegistrationData): void {
    const currentData = this.registrationDataSubject.getValue();
    this.registrationDataSubject.next({
      ...currentData,
      ...data
    });
  }

  getRegistrationData(): RegistrationData | null {
    const data = this.registrationDataSubject.getValue();
    return data ? {
      email: data.email || '',
      name: data.name || '',
      surname: data.surname || '',
      password: data.password || '',
      plan: data.plan || 'free'
    } : null;
  }

  clearRegistrationData(): void {
    this.registrationDataSubject.next(null);
  }

  checkEmailExists(email: string): Observable<RegistrationResponse> {
    return this.http.post<RegistrationResponse>(`${this.apiUrl}/check-email`, { email }).pipe(
      catchError((error: any) => {
        console.error('checkEmailExists: Error checking email:', error);
        return throwError(() => new Error(error.error?.message || 'Failed to check email'));
      })
    );
  }
}