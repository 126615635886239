// shared/velocity-tags/velocity-tags.component.ts
import { Component, Input, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-velocity-tags',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './velocity-tags.component.html',
  styleUrls: ['./velocity-tags.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VelocityTagsComponent {
  @Input() velocities: number[] = [];
  @Output() velocitiesChange = new EventEmitter<number[]>();
  
  inputValue: string = '';

  addVelocity(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault(); // Prevent form submission
    }
    
    const value = parseFloat(this.inputValue);
    console.log('Adding velocity:', value); // Debug log
    
    if (!isNaN(value) && this.velocities.length < 6) {
      const newVelocities = [...this.velocities, value];
      console.log('New velocities array:', newVelocities); // Debug log
      this.velocitiesChange.emit(newVelocities);
      this.inputValue = ''; // Clear input after adding
    }
  }
  
  removeVelocity(index: number) {
    console.log('Removing velocity at index:', index); // Debug log
    const newVelocities = this.velocities.filter((_, i) => i !== index);
    this.velocitiesChange.emit(newVelocities);
  }
  
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.addVelocity(event);
    }
  }
}