export const environment = {
    production: true,
    apiUrl: 'https://sysart.ai/api',
    frontendUrl: 'https://sysart.ai',
    revolutPublicKey: 'pk_Kmkn0go4siYW4QaJJeIk4ib6wPIdzCxqvUaS9VT5wF2mSu5i',
    jira: {
      clientId: 'BFaLS58pDBAW7RaAK5KmIBsW5nB03psb',
      clientSecret: 'ATOAhAGXzKbGZtB_bgM70lCH0xmCeCin6n2rHbbPv_8BL45mTqqM6NZYNYOzl88M9sJx89B7D50B',
      callbackUrl: 'https://sysart.ai/integrations/jira/callback',
      allowedOrigins: [
        'https://sysart.ai'
      ]
    },
    socialAuth: {
      google: {
        clientId: '960376345924-ha5vg1pmsk9m4pqbr5h1drbooa8o8r91.apps.googleusercontent.com',
        redirectUri: 'https://sysart.ai/auth/google/callback',
        clientSecret: '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'
      },
      facebook: {
        clientId: '923334076290571',
        appSecret: '0ac3b54b0c898d80cf40dac42b079cba'
      }
    }
  };
  