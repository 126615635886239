<app-sidebar></app-sidebar>    
<div class="dashboard-main">
    <app-header></app-header>
<div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
    <ul class="d-flex align-items-center gap-2">
        <li class="fw-medium">
            <a href="index.html" class="d-flex align-items-center gap-1 hover-text-primary">
                <iconify-icon icon="solar:home-smile-angle-outline" class="icon text-lg"></iconify-icon>
                Help
            </a>
        </li>
    </ul>
</div>

<div class="card basic-data-table">
    <div class="card-header p-0 border-0">
        <div class="responsive-padding-40-150 bg-gradient-start-1">
            <div class="row gy-4 align-items-center">
                <div class="col-xl-7">
                    <h4 class="mb-20">VDF AI - Help</h4>
                    <p class="mb-0 text-secondary-light max-w-634-px text-xl">Welcome to the VDF AI help page! Here
                        you’ll find answers to the most common questions about our AI platform, designed to
                        help individuals and organizations navigate their agility journey. Explore the FAQs below to
                        learn more about how VDF AI can empower your team and transform your business operations.</p>
                </div>
                <div class="col-xl-5 d-xl-block d-none">
                    <img src="https://imagedelivery.net/soJx4BiMTBvAFlSCQpzu3A/c3766cbd-48fe-434d-760a-afadb713ad00/public" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="card-body bg-base responsive-padding-40-150">
        <div class="row gy-4">
            <div class="col-lg-4">
                <div class="active-text-tab flex-column nav-pills bg-base shadow py-0 px-24 radius-12 border"
                    id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom active" id="v-pills-general-tab" data-bs-toggle="pill" data-bs-target="#v-pills-general" type="button" role="tab" aria-controls="v-pills-general" aria-selected="true">General Questions</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom" id="v-pills-how-to-use-tab" data-bs-toggle="pill" data-bs-target="#v-pills-how-to-use" type="button" role="tab" aria-controls="v-pills-how-to-use" aria-selected="false">How to Use VDF AI?</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom" id="v-pills-additional-features-tab" data-bs-toggle="pill" data-bs-target="#v-pills-additional-features" type="button" role="tab" aria-controls="v-pills-additional-features" aria-selected="false">Advanced Features</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom" id="v-pills-support-tab" data-bs-toggle="pill" data-bs-target="#v-pills-support" type="button" role="tab" aria-controls="v-pills-support" aria-selected="false">Support</button>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="tab-content" id="v-pills-tabContent">
                    <!-- General Questions Tab -->
                    <div class="tab-pane fade show active" id="v-pills-general" role="tabpanel"
                        aria-labelledby="v-pills-general-tab" tabindex="0">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What are VDF AI Apps?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        VDF (Value Drlivery Facilitator) AI is developed to provide
                                        personalized assistance, information, and intelligent services through advanced
                                        AI and machine learning technologies. They are designed to improve user
                                        experience by offering relevant responses and recommendations.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How can I object to the processing of my data or ask for inaccurate data in VDF AI responses to be corrected?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        You can object to the processing of your data or request corrections by
                                        accessing the data privacy settings in your VDF AI Account. Follow the
                                        instructions provided in the "Manage Your Data" section to make changes.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        What are VDF AI's legal bases of processing data under European Union (EU) or United Kingdom (UK) data protection law?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        VDF AI processes data based on several legal bases, including user consent,
                                        contractual necessity, and legitimate interests. These bases comply with the
                                        General Data Protection Regulation (GDPR) and the UK Data Protection Act.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What data is collected? How is it used?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        VDF AI Apps may collect data such as user queries, location, device information,
                                        and feedback to enhance service functionality, improve AI responses, and provide
                                        a personalized user experience. The data is used to optimize performance,
                                        security, and relevance.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Do you use my VDF AI Apps conversations to show me ads?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        No, VDF AI Apps do not use conversations for advertising purposes. Your data is
                                        not shared with advertisers and is not used for ad targeting.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        Who has access to my VDF AI Apps conversations?
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Access to your conversations is limited to authorized personnel and systems that
                                        require it to perform specific functions, such as improving AI models or
                                        resolving technical issues. Your data is protected according to VDF AI's privacy
                                        policies.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- How to Use VDF AI Tab -->
                    <div class="tab-pane fade" id="v-pills-how-to-use" role="tabpanel"
                        aria-labelledby="v-pills-how-to-use-tab" tabindex="0">
                        <div class="accordion" id="accordionExampleHowToUse">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHowToUse1" aria-expanded="true" aria-controls="collapseHowToUse1">
                                        Aim and Capabilities
                                    </button>
                                </h2>
                                <div id="collapseHowToUse1" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExampleHowToUse">
                                    <div class="accordion-body">
                                        <strong>Aim:</strong> The primary aim of VDF AI is to enhance the performance of
                                        agile teams and organizations by providing them with expert advice and
                                        strategies. The application
                                        is designed to assist leaders, agile coaches, scrum masters, product owners, and
                                        team members in
                                        understanding and applying advanced agile practices and systems thinking
                                        principles to optimize their workflows and team dynamics.<br><br>
                                        <strong>Capabilities:</strong>
                                        <ul>
                                            <li>Contextual Responses: VDF AI generates responses based on user prompts
                                                and predefined contexts related to Scrum patterns and systems thinking.
                                            </li>
                                            <li>Sequential Context Handling: The application processes multiple contexts
                                                in sequence, allowing users to explore different aspects of agile, Scrum
                                                and
                                                systems thinking.</li>
                                            <li>Scalable Interaction: Designed to handle multiple users and requests
                                                simultaneously, providing fast and reliable access to expert guidance.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHowToUse2" aria-expanded="false" aria-controls="collapseHowToUse2">
                                        Packages and Features
                                    </button>
                                </h2>
                                <div id="collapseHowToUse2" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleHowToUse">
                                    <div class="accordion-body">
                                        <ul>
                                            <li><strong>Free Trial:</strong> Limited Usage per User (3 Queries per Day),
                                                Chat Box, Causal Loop Diagram Generation</li>
                                            <li><strong>Pro:</strong> Everything in Free Trial, Unlimited Queries</li>
                                            <li><strong>Enterprise:</strong> Everything in Pro, Unlimited Usage,
                                                Enterprise Integrations (Azure, Jira, Git), Additional Data Entry
                                                Options (Team DevOps Metrics), Team Agility Report Assessment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- Additional content sections for "How to Use VDF AI?" -->
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHowToUse3" aria-expanded="false" aria-controls="collapseHowToUse3">
                                        After Login - Free Trial Version Screen
                                    </button>
                                </h2>
                                <div id="collapseHowToUse3" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleHowToUse">
                                    <div class="accordion-body">
                                        On this screen, users are prompted to optionally enter some information with
                                        brief explanations about the fields. Once submitted, the system displays AI
                                        response in the suggestions & chat area. The causal loop diagram generated in
                                        Python will also be displayed, with explanations on how to read the diagram
                                        included.
                                    </div>
                                </div>
                            </div>
                            <!-- More accordion items can be added as needed -->
                        </div>
                    </div>

                    <!-- Advanced Features Tab -->
                    <div class="tab-pane fade" id="v-pills-additional-features" role="tabpanel"
                        aria-labelledby="v-pills-additional-features-tab" tabindex="0">
                        <div class="accordion" id="accordionExampleAdditionalFeatures">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdditional1" aria-expanded="true" aria-controls="collapseAdditional1">
                                Causal Loop Diagram Customization
                            </button>
                                </h2>
                                <div id="collapseAdditional1" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExampleAdditionalFeatures">
                                    <div class="accordion-body">
                                        In addition to generating standard causal loop diagrams, VDF AI allows users to
                                        customize diagrams based on specific metrics such as team velocity, deployment
                                        frequency, and lead time. By providing more metrics and information you can get
                                        more information for your challenges.
                                        These customizations help teams visualize their unique
                                        challenges and identify root causes more effectively.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdditional2" aria-expanded="false" aria-controls="collapseAdditional2">
                                Real-Time Feedback Integration
                            </button>
                                </h2>
                                <div id="collapseAdditional2" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleAdditionalFeatures">
                                    <div class="accordion-body">
                                        VDF AI offers real-time integration with various project management and DevOps
                                        tools such as Jira, Azure, and Git. This feature enables continuous feedback
                                        loops and ensures that teams can respond to challenges in real-time with
                                        data-backed solutions.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Support Tab -->
                    <div class="tab-pane fade" id="v-pills-support" role="tabpanel"
                        aria-labelledby="v-pills-support-tab" tabindex="0">
                        <div class="accordion" id="accordionExampleSupport">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupport1" aria-expanded="false" aria-controls="collapseSupport1">
                                    Contacting Customer Support
                                </button>
                                </h2>
                                <div id="collapseSupport1" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleSupport">
                                    <div class="accordion-body">
                                        If you encounter any issues or need further assistance, our customer support
                                        team is here to help. You can reach us via email at support&#64;vdfai.com or use the
                                        live chat option on our website. For more information, visit the <a
                                            href="contact.html">Contact Us</a> page.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupport2" aria-expanded="false" aria-controls="collapseSupport2">
                                    Troubleshooting Common Issues
                                </button>
                                </h2>
                                <div id="collapseSupport2" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleSupport">
                                    <div class="accordion-body">
                                        Having trouble with VDF AI? Our help center provides detailed guides for
                                        troubleshooting common issues, including login problems, system integration
                                        errors, and more. Check out our <a href="help-center.html">Help Center</a> for
                                        quick resolutions.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer> 
</div>
