// src/app/registration/components/verification/verification.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { RegistrationStateService, RegistrationState } from '../../services/registration-state.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RegistrationService } from '../../../services/registration.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true
})

export class VerificationComponent implements OnInit, OnDestroy {
  verificationForm!: FormGroup;
  userEmail: string = '';
  errorMessage: string = '';
  isSubmitting: boolean = false;
  resendDisabled: boolean = false;
  resendTimer: number = 0;
  private resendInterval?: number;
  private stateSub?: Subscription;
  private currentQueryParams: any = {};
  private routeSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private registrationStateService: RegistrationStateService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    console.log('VerificationComponent: Constructor called');
    this.verificationForm = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  ngOnInit(): void {
    console.log('VerificationComponent: Initializing');
    this.isSubmitting = false;

    // Store query parameters
    this.routeSub = this.route.queryParams.subscribe(params => {
      console.log('VerificationComponent: Received query params:', params);
      this.currentQueryParams = { ...params };
    });

    this.stateSub = this.registrationStateService.state$.subscribe({
      next: (state) => {
        console.log('VerificationComponent: Received state:', state);
        if (!state.userDetails?.email) {
          console.log('VerificationComponent: No email found, navigating to details');
          this.router.navigate(['/register/details']);
          return;
        }
        this.userEmail = state.userDetails.email;
        console.log('VerificationComponent: User email set to', this.userEmail);
        
        this.sendInitialCode(state.userDetails);
      },
      error: (error) => {
        console.error('VerificationComponent: State subscription error:', error);
        this.isSubmitting = false;
      }
    });
  }

  sendInitialCode(userDetails: any): void {
    console.log('sendInitialCode: Sending code to', userDetails.email);
    this.registrationService.sendVerificationEmail(
      userDetails.email,
      userDetails.name || '',
      userDetails.password || '',
      userDetails.surname || ''
    ).subscribe({
      next: () => {
        console.log('sendInitialCode: Code sent successfully');
        this.startResendTimer();
      },
      error: (error) => {
        console.error('sendInitialCode: Error sending code:', error);
      }
    });
  }

  verifyCode(): void {
    console.log('verifyCode: Called with state', {
      isValid: this.verificationForm.valid,
      isSubmitting: this.isSubmitting,
      formValue: this.verificationForm.get('code')?.value
    });

    if (!this.verificationForm.valid || this.isSubmitting) {
      console.log('verifyCode: Form is invalid or already submitting');
      return;
    }

    this.isSubmitting = true;
    console.log('verifyCode: isSubmitting set to', this.isSubmitting);

    const code = this.verificationForm.get('code')?.value;

    this.registrationService.verifyCode(this.userEmail, code)
      .subscribe({
        next: (response) => {
          console.log('verifyCode: Verification response', response);
          if (response.success) {
            console.log('verifyCode: Verification successful');
            this.registrationStateService.completeVerification();
            const state = this.registrationStateService.getCurrentState();
            console.log('verifyCode: Current state after verification', state);
            
            // Redirect based on the selected plan
            this.handlePostVerification();
          } else {
            this.errorMessage = response.message || 'Verification failed';
            console.log('verifyCode: Verification failed with message', this.errorMessage);
          }
          this.isSubmitting = false;
          console.log('verifyCode: isSubmitting reset to', this.isSubmitting);
        },
        error: (error) => {
          console.error('verifyCode: Verification error:', error);
          this.errorMessage = error.message || 'Failed to verify code';
          this.isSubmitting = false;
          console.log('verifyCode: isSubmitting reset to', this.isSubmitting);
        }
      });
  }

  private startResendTimer(): void {
    console.log('startResendTimer: Timer started');
    if (this.resendInterval) {
      clearInterval(this.resendInterval);
    }
    
    this.resendInterval = window.setInterval(() => {
      this.resendTimer--;
      console.log('startResendTimer: Timer countdown', this.resendTimer);
      if (this.resendTimer <= 0) {
        this.resendDisabled = false;
        console.log('startResendTimer: Resend enabled');
        if (this.resendInterval) {
          clearInterval(this.resendInterval);
        }
      }
    }, 1000);
  }

  goBack(): void {
    console.log('goBack: Navigating back');
    this.location.back();
  }

  ngOnDestroy(): void {
    console.log('VerificationComponent: Destroying');
    if (this.stateSub) {
      this.stateSub.unsubscribe();
    }
    if (this.resendInterval) {
      clearInterval(this.resendInterval);
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.verificationForm.get(fieldName);
    const isInvalid = field ? (field.invalid && (field.dirty || field.touched)) : false;
    console.log(`isFieldInvalid: Field ${fieldName} is invalid:`, isInvalid);
    return isInvalid;
  }

  resendCode(): void {
    console.log('resendCode: Resending code');
    const state = this.registrationStateService.getCurrentState();
    if (!state.userDetails) {
      this.errorMessage = 'User details not found';
      console.log('resendCode: User details not found');
      return;
    }
    this.sendInitialCode(state.userDetails);
  }

  private handlePostVerification(): void {
    const state = this.registrationStateService.getCurrentState();
    console.log('VerificationComponent: Handling post verification with state:', state);
    console.log('VerificationComponent: Current query params:', this.currentQueryParams);

    switch (state.plan) {
      case 'free':
        console.log('VerificationComponent: Completing registration for free plan');
        this.completeRegistration(state);
        break;
      case 'professional':
        console.log('VerificationComponent: Navigating to payment for professional plan');
        this.router.navigate(['/public/register/payment'], { 
          queryParams: this.currentQueryParams 
        });
        break;
      case 'enterprise':
        console.log('VerificationComponent: Navigating to company details for enterprise plan');
        this.router.navigate(['/public/register/company'], { 
          queryParams: this.currentQueryParams 
        });
        break;
      default:
        console.error('VerificationComponent: Unknown plan type');
        this.errorMessage = 'Unknown plan type';
    }
  }

  private completeRegistration(state: RegistrationState): void {
    if (!state.userDetails) {
      this.errorMessage = 'User details are incomplete';
      console.error('completeRegistration: User details are missing');
      return;
    }

    const registrationData = {
      email: state.userDetails.email,
      name: state.userDetails.name,
      surname: state.userDetails.surname || '',
      password: state.userDetails.password,
      plan: state.plan
    };

    console.log('completeRegistration: Completing registration with data', registrationData);

    this.registrationService.completeRegistration(registrationData)
      .subscribe({
        next: (response) => {
          console.log('completeRegistration: Response received', response);
          if (response.success) {
            this.router.navigate(['/app/dashboard']);
          } else {
            this.errorMessage = response.message || 'Failed to complete registration';
          }
        },
        error: (error) => {
          this.errorMessage = error.message || 'Failed to complete registration';
          console.error('completeRegistration: Error completing registration:', error);
        }
      });
  }

  async onVerificationSuccess() {
    const currentState = this.registrationStateService.getCurrentState();
    
    // Update state to mark verification as complete
    this.registrationStateService.updateState({
      isVerified: true,
      step: 'verified'
    });

    // Route based on plan
    switch (currentState.plan) {
      case 'free':
        await this.router.navigate(['/app/dashboard']);
        break;
      case 'professional':
        await this.router.navigate(['/public/register/payment']);
        break;
      case 'enterprise':
        await this.router.navigate(['/public/register/company-details']);
        break;
      default:
        await this.router.navigate(['/app/dashboard']);
    }
  }
}