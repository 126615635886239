// src/app/integrations/integrations.component.ts
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../shared/header/header.component';
import { JiraService, JiraAuthResponse, JiraConnectionStatus, JiraBasicAuth } from '../services/jira.service';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';  // Add finalize here
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

// Declare the global bootstrap object from the bundled JS
interface BootstrapModal {
  show(): void;
  hide(): void;
  dispose(): void;
}

declare global {
  interface Window {
    bootstrap: {
      Modal: new (element: Element | null, options?: any) => BootstrapModal;
    };
  }
}

interface Integration {
  id: string;
  name: string;
  description: string;
  icon: string;
  status: 'connected' | 'disconnected';
  colorClass: string;
  gradientClass: string;
  isLoading?: boolean;
  error?: string;
}

export type JiraAuthConfig = JiraBasicAuth;

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule, 
    HeaderComponent,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  private messageSubscription?: Subscription;
  private destroy$ = new Subject<void>();
  basicAuthForm!: FormGroup;
  isLoading = false;
  private modal: BootstrapModal | null = null;

  integrations: Integration[] = [
    {
      id: 'jira',
      name: 'Jira',
      description: 'Connect with Jira to sync your project management workflow and track issues seamlessly.',
      icon: 'logos:jira',
      status: 'disconnected',
      colorClass: 'text-primary-600',
      gradientClass: 'bg-gradient-primary'
    },

    {
        id: 'azure',
        name: 'Azure DevOps',
        description: 'Integrate with Azure DevOps for comprehensive cloud-based development and deployment.',
        icon: 'logos:microsoft-azure',
        status: 'disconnected',
        colorClass: 'text-success-600',
        gradientClass: 'bg-gradient-success'
      },
      {
        id: 'tfs',
        name: 'Team Foundation Server',
        description: 'Connect to TFS for source control, reporting, requirements management, and project tracking.',
        icon: 'vscode-icons:file-type-tfs',
        status: 'disconnected',
        colorClass: 'text-danger-600',
        gradientClass: 'bg-gradient-danger'
      },
      {
        id: 'slack',
        name: 'Slack',
        description: 'Integrate with Slack for real-time notifications and team collaboration.',
        icon: 'logos:slack-icon',
        status: 'disconnected',
        colorClass: 'text-lilac-600',
        gradientClass: 'bg-gradient-purple'
      },
      {
        id: 'trello',
        name: 'Trello',
        description: 'Connect with Trello to manage your boards and cards directly from the platform.',
        icon: 'logos:trello',
        status: 'disconnected',
        colorClass: 'text-primary-600',
        gradientClass: 'bg-gradient-primary'
      },
      {
        id: 'asana',
        name: 'Asana',
        description: 'Integrate with Asana for project and task management synchronization.',
        icon: 'logos:asana',
        status: 'disconnected',
        colorClass: 'text-success-600',
        gradientClass: 'bg-gradient-success'
      }
  ];

  constructor(
    private fb: FormBuilder,
    private jiraService: JiraService
  ) {}

  ngOnInit(): void {
    this.initForms();
    this.checkExistingConnections();
    this.setupAuthListener();
    this.initModal();
  }

  private initForms(): void {
    this.basicAuthForm = this.fb.group({
      url: ['', [Validators.required, Validators.pattern('https://.*')]],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  private initModal(): void {
    const modalElement = document.getElementById('jiraAuthModal');
    if (modalElement && window.bootstrap?.Modal) {
      this.modal = new window.bootstrap.Modal(modalElement);
    } else {
      console.error('Bootstrap Modal not initialized: Modal element or Bootstrap not found');
    }
  }

  showModal(): void {
    if (this.modal) {
      this.modal.show();
    } else {
      console.error('Modal not initialized');
    }
  }

  hideModal(): void {
    if (this.modal) {
      this.modal.hide();
    }
  }

  ngOnDestroy(): void {
    if (this.modal) {
      this.modal.dispose();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupAuthListener(): void {
    window.addEventListener('message', (event) => {
      if (event.data?.type === 'jira_auth_success') {
        this.handleJiraAuthSuccess(event.data);
      }
    });
  }

  private handleJiraAuthSuccess(data: any): void {
    const integration = this.integrations.find(i => i.id === 'jira');
    if (!integration) return;

    integration.isLoading = true;

    this.jiraService.saveConnection(data.cloudId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => integration.isLoading = false)
      )
      .subscribe({
        next: (response) => {
          if (response.success) {
            integration.status = 'connected';
            this.fetchJiraData();
          } else {
            integration.status = 'disconnected';
            integration.error = response.error;
          }
        },
        error: (error) => {
          console.error('Failed to save Jira connection:', error);
          integration.status = 'disconnected';
          integration.error = 'Failed to connect to Jira';
        }
      });
  }

  private checkExistingConnections(): void {
    this.jiraService.checkConnection()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: JiraConnectionStatus) => {
          const jiraIntegration = this.integrations.find(i => i.id === 'jira');
          if (jiraIntegration && response.success && response.isConnected) {
            jiraIntegration.status = 'connected';
          }
        },
        error: (error) => {
          console.error('Error checking connections:', error);
          this.handleConnectionError('jira', error);
        }
      });
  }

  handleButtonClick(integration: Integration): void {
    if (integration.id === 'jira' && integration.status === 'disconnected') {
      this.showModal();
    } else if (integration.status === 'connected') {
      this.disconnectIntegration(integration);
    }
  }

  private disconnectIntegration(integration: Integration): void {
    switch (integration.id) {
      case 'jira':
        this.jiraService.disconnect()
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => integration.isLoading = false)
          )
          .subscribe({
            next: (response) => {
              if (response.success) {
                integration.status = 'disconnected';
              }
            },
            error: (error) => this.handleConnectionError(integration.id, error)
          });
        break;
      default:
        integration.isLoading = false;
        console.log(`${integration.name} disconnection not implemented`);
    }
  }

  private handleConnectionError(integrationId: string, error: any): void {
    const integration = this.integrations.find(i => i.id === integrationId);
    if (integration) {
      integration.error = error?.message || 'An error occurred';
      integration.isLoading = false;
      integration.status = 'disconnected';
    }
  }

  private fetchJiraData(): void {
    this.jiraService.getBoards()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response.success) {
            localStorage.setItem('jiraBoards', JSON.stringify(response.boards));
          }
        },
        error: (error) => console.error('Failed to fetch Jira boards:', error)
      });
  }

  getButtonText(integration: Integration): string {
    if (integration.isLoading) return 'Processing...';
    return integration.status === 'connected' ? 'Disconnect' : 'Connect';
  }

  showJiraAuthDialog(): void {
    // You can implement this as a modal or separate route
    // For now, let's use basic auth as an example
    const config: JiraBasicAuth = {
      authType: 'basic',
      url: 'https://your-jira-instance.com',
      username: 'your-username',
      password: 'your-password'
    };

    const integration = this.integrations.find(i => i.id === 'jira');
    if (!integration) return;

    integration.isLoading = true;

    this.jiraService.connectJira(config)
      .pipe(finalize(() => integration.isLoading = false))
      .subscribe({
        next: (response) => {
          if (response.success) {
            integration.status = 'connected';
          } else {
            integration.status = 'disconnected';
            integration.error = response.error;
          }
        },
        error: (error) => {
          integration.status = 'disconnected';
          integration.error = error.message;
        }
      });
  }

  connectBasicAuth(): void {
    if (this.basicAuthForm.valid) {
      this.isLoading = true;
      const config: JiraBasicAuth = {
        authType: 'basic',
        ...this.basicAuthForm.value
      };

      this.jiraService.connectJira(config)
        .pipe(finalize(() => {
          this.isLoading = false;
          this.hideModal();
        }))
        .subscribe({
          next: (response) => {
            if (response.success) {
              const jiraIntegration = this.integrations.find(i => i.id === 'jira');
              if (jiraIntegration) {
                jiraIntegration.status = 'connected';
              }
              this.basicAuthForm.reset();
            }
          },
          error: (error) => {
            const jiraIntegration = this.integrations.find(i => i.id === 'jira');
            if (jiraIntegration) {
              jiraIntegration.error = error.message;
            }
          }
        });
    }
  }
}