// src/app/shared/payment/payment.component.ts

import { Component, OnInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, AbstractControl, ValidationErrors, FormsModule, FormControl } from '@angular/forms';
import { OrderData, BillingAddress, PaymentVerificationResponse, VerifyPaymentResponse, PaymentResponse } from '../../interfaces/payment.interface';
import { AuthorizationService } from '../../services/authorization.service';
import { Subscription, BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { UserType } from '../../interfaces/user.interface';
import { HeaderComponent } from '../../shared/header/header.component';
import { countries, Country } from '../data/countries'; 
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionService } from '../../services/subscription.service';
import { FooterComponent } from '../footer/footer.component';
import { CompanyService } from '../../services/company.service';


interface PlanPricing {
  monthly: number;
  yearly: number;
  currency: string;
}

interface PlanPricingMap {
  free: {
    monthly: number;
    yearly: number;
    currency: string;
  };
  professional: {
    monthly: number;
    yearly: number;
    currency: string;
  };
  enterprise: {
    monthly: number;
    yearly: number;
    currency: string;
  };
}

interface PaymentStatus {
  status: 'pending' | 'processing' | 'completed' | 'failed';
  message?: string;
}

interface RevolutPaymentEvent {
  type: 'success' | 'error' | 'cancel';
  error?: string; 
  dropOffState?: string; 
}

interface PaymentData {
  orderId: string;
  paymentId?: string;
  status?: string;
}

interface OrderResponse {
  success: boolean;
  id: string;
  token: string;
  error?: string;
  type?: string;
  state?: string;
  created_at?: string;
  updated_at?: string;
  amount?: number;
  currency?: string;
  outstanding_amount?: number;
  capture_mode?: string;
  checkout_url?: string;
  enforce_challenge?: string;
}

// Define types based on documentation
type RevolutCheckoutError = {
  type: string;
  message: string;
  code?: number;
};

type CardFieldInstance = {
  submit: (meta?: Meta) => void;
  validate: () => void;
  destroy: () => void;
};

type Meta = {
  name?: string;
  email?: string;
  phone?: string;
  cardholderName?: string;
  billingAddress?: {
    countryCode: string;
    region?: string;
    city?: string;
    postcode: string;
    streetLine1?: string;
    streetLine2?: string;
  };
};

// Add these type definitions
interface RevolutCardField {
  validate: () => Promise<void>;
  submit: (data: any) => Promise<void>;
  destroy: () => void;
}

interface RevolutInstance {
  revolutPay: {
    mount: (element: HTMLElement, options: any) => Promise<void>;
  };
}

// Add type for RevolutCheckout
declare const RevolutCheckout: {
  payments: (config: {
    publicToken: string;
    mode: 'sandbox' | 'prod';
    locale?: string;
  }) => Promise<any>;
};

interface CreateOrderRequest {
  amount: number;
  currency: string;
  plan: string;
  billingCycle: string;
  billing_address: {
    country: string;
    address: string;
    city: string;
    postal_code: string;
  };
}

interface CreateOrderResponse {
  success: boolean;
  order_id?: string;
  token?: string;
  checkout_url?: string;
  error?: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TitleCasePipe,
    HeaderComponent,
    FooterComponent
  ]
})

export class PaymentComponent implements OnInit, OnDestroy, AfterViewInit {
  paymentForm: FormGroup;
  selectedPlan: UserType = 'professional';
  selectedPlanPrice: number = 29;
  isAnnualBilling = false;
  isSubmitting = false;
  errorMessage = '';
  showProcessingModal = false;
  selectedPaymentMethod: 'card' | 'revolut' = 'card';
  private paymentStatus = new BehaviorSubject<PaymentStatus>({ status: 'pending' });
  private subscriptions: Subscription[] = [];
  countrySearchControl = new FormControl('');
  showDropdown: boolean = false;
  countries: Country[] = countries;
  private revolutInstance: any = null;
  selectedBillingCycle: 'monthly' | 'yearly' = 'monthly';
  @ViewChild('revolutPayContainer') revolutPayContainer?: ElementRef;
  @ViewChild('revolutCardField') revolutCardField?: ElementRef;
  
  private revolutPay: any;
  private currentOrderId: string | null = null;
  private currentOrderToken: string | null = null;
  cardFieldError: string = '';
  showRevolutContainer = false;

  planPricing: PlanPricingMap = {
    free: {
      monthly: 0,
      yearly: 0,
      currency: 'USD'
    },
    professional: {
      monthly: 29,
      yearly: 290,
      currency: 'USD'
    },
    enterprise: {
      monthly: 99.00,
      yearly: 990.00,
      currency: 'USD'
    }
  };

  public isLoading = false;
  private cardField: any = null;
  private viewInitialized = false;
  successMessage: string = '';

  private readonly BASE_ENTERPRISE_PRICE = 99;
  private readonly ADDITIONAL_MEMBER_PRICE = 99;

  // Update price constants for both billing cycles
  private readonly MONTHLY_BASE_ENTERPRISE_PRICE = 99;
  private readonly YEARLY_BASE_ENTERPRISE_PRICE = 990;
  private readonly MONTHLY_ADDITIONAL_MEMBER_PRICE = 99;
  private readonly YEARLY_ADDITIONAL_MEMBER_PRICE = 990;

  // Add teamMembers property
  private teamMembers: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private authorizationService: AuthorizationService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {
    this.paymentForm = this.createPaymentForm();
  }


  isFieldInvalid(fieldPath: string): boolean {
    const field = fieldPath.includes('.') ? 
      this.paymentForm.get(fieldPath) :
      this.paymentForm.get(fieldPath);
    return field ? (field.invalid && (field.dirty || field.touched)) : false;
  }

  private handlePaymentStatusChange(status: PaymentStatus): void {
    this.showProcessingModal = status.status === 'processing';
    
    switch (status.status) {
      case 'completed':
        this.router.navigate(['/register/success']);
        break;
      case 'failed':
        this.errorMessage = status.message || 'Payment failed';
        break;
      // Add other status handlers as needed
    }
  }

  private markFormFieldsAsTouched(): void {
    Object.keys(this.paymentForm.controls).forEach(key => {
      const control = this.paymentForm.get(key);
      if (control?.invalid) {
        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach(subKey => {
            control.get(subKey)?.markAsTouched();
          });
        } else {
          control.markAsTouched();
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    // Subscribe to query parameters
    this.route.queryParams.subscribe(params => {
      this.selectedPlan = params['plan'] || 'professional';
      this.selectedBillingCycle = params['billingCycle'] || 'monthly';
      this.isAnnualBilling = this.selectedBillingCycle === 'yearly';

      // Handle enterprise plan pricing with team members
      if (this.selectedPlan === 'enterprise') {
        const teamMembers = parseInt(params['teamMembers'] || '1', 10);
        const additionalMembers = Math.max(0, teamMembers);
        
        // Update plan pricing for enterprise
        const monthlyPrice = this.BASE_ENTERPRISE_PRICE + (additionalMembers * this.ADDITIONAL_MEMBER_PRICE);
        const annualPrice = monthlyPrice * 10; // 2 months free

        this.planPricing['enterprise'] = {
          monthly: monthlyPrice,
          yearly: annualPrice,
          currency: 'USD'
        };

        // Load company details from session storage
        const companyDetails = JSON.parse(sessionStorage.getItem('companyDetails') || '{}');
        if (companyDetails.billing) {
          this.paymentForm.patchValue({
            billingAddress: {
              cardholderName: companyDetails.billing.name,
              country: companyDetails.billing.country,
              address: companyDetails.billing.address,
              city: companyDetails.billing.city,
              state: companyDetails.billing.state,
              postalCode: companyDetails.billing.postalCode
            }
          });
        }
      }

      // Update selected plan price using the billing cycle
      const cycleKey = this.isAnnualBilling ? 'yearly' : 'monthly';
      this.selectedPlanPrice = this.planPricing[this.selectedPlan][cycleKey];
      
      console.log('Updated plan details:', {
        plan: this.selectedPlan,
        billingCycle: this.selectedBillingCycle,
        isAnnual: this.isAnnualBilling,
        price: this.selectedPlanPrice
      });
    });

    // Initialize form with validators
    this.paymentForm = this.fb.group({
      billingAddress: this.fb.group({
        cardholderName: ['', [
          Validators.required, 
          Validators.pattern(/^[a-zA-Z]+ [a-zA-Z]+(?: [a-zA-Z]+)*$/)
        ]],
        country: ['', Validators.required],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: [''],
        postalCode: ['', Validators.required]
      })
    });

    // Initialize with default values
    this.selectedPlan = 'professional';
    this.selectedPlanPrice = 29;
    this.selectedBillingCycle = 'monthly';

    const user = this.getUserInfo();

    if (!user.isVerified || !['professional', 'enterprise'].includes(user.plan)) {
      this.router.navigate(['/app/packages']);
      return;
    }

    // Update selected plan and price
    this.selectedPlan = user.plan as UserType;
    this.selectedPlanPrice = this.planPricing[user.plan].monthly;

    // Pre-fill form if enterprise user
    if (user.plan === 'enterprise' && user.companyDetails) {
      this.paymentForm.patchValue({
        billingAddress: {
          country: 'US',
          address: '',
          city: '',
          postalCode: ''
        }
      });
    }

    // Create initial order once form is valid
    this.paymentForm.statusChanges.pipe(
      filter((status: string) => status === 'VALID'),
      take(1)
    ).subscribe(async () => {
      try {
        const orderResponse = await this.createOrder();
        if (orderResponse.success) {
          console.log('Initial order created:', orderResponse);
          // Initialize payment methods after order creation
          if (this.selectedPaymentMethod === 'revolut') {
            await this.initializeRevolutPay();
          } else {
            await this.initializeRevolutCardField();
          }
        }
      } catch (error) {
        console.error('Failed to create initial order:', error);
        this.errorMessage = 'Failed to initialize payment. Please try again.';
      }
    });

    // Debug form changes
    this.paymentForm.valueChanges.subscribe(value => {
      console.log('Form value changed:', {
        value,
        valid: this.paymentForm.valid,
        errors: this.paymentForm.errors
      });
    });

    const paymentStatusSub = this.paymentStatus.subscribe(status => {
      this.handlePaymentStatusChange(status);
    });

    this.subscriptions.push(paymentStatusSub);

    // Subscribe to query parameters
    this.route.queryParams.subscribe(params => {
      this.selectedPlan = params['plan'] || 'professional';
      this.selectedBillingCycle = params['billingCycle'] || 'monthly';
      this.isAnnualBilling = this.selectedBillingCycle === 'yearly';

      // Handle enterprise plan pricing with team members
      if (this.selectedPlan === 'enterprise') {
        const teamMembers = parseInt(params['teamMembers'] || '1', 10);
        const additionalMembers = Math.max(0, teamMembers);
        
        // Update plan pricing for enterprise
        const monthlyPrice = this.BASE_ENTERPRISE_PRICE + (additionalMembers * this.ADDITIONAL_MEMBER_PRICE);
        const annualPrice = monthlyPrice * 10; // 2 months free

        this.planPricing['enterprise'] = {
          monthly: monthlyPrice,
          yearly: annualPrice,
          currency: 'USD'
        };

        // Load company details from session storage
        const companyDetails = JSON.parse(sessionStorage.getItem('companyDetails') || '{}');
        if (companyDetails.billing) {
          this.paymentForm.patchValue({
            billingAddress: {
              cardholderName: companyDetails.billing.name,
              country: companyDetails.billing.country,
              address: companyDetails.billing.address,
              city: companyDetails.billing.city,
              state: companyDetails.billing.state,
              postalCode: companyDetails.billing.postalCode
            }
          });
        }
      }

      // Update selected plan price using the billing cycle
      const cycleKey = this.isAnnualBilling ? 'yearly' : 'monthly';
      this.selectedPlanPrice = this.planPricing[this.selectedPlan][cycleKey];
      
      console.log('Updated plan details:', {
        plan: this.selectedPlan,
        billingCycle: this.selectedBillingCycle,
        isAnnual: this.isAnnualBilling,
        price: this.selectedPlanPrice
      });
    });
  }

  private createPaymentForm(): FormGroup {
    return this.fb.group({
      cardNumber: ['', [
        Validators.required,
        Validators.pattern(/^[\d\s]{16,19}$/),
        this.validateLuhn()
      ]],
      expiryDate: ['', [
        Validators.required,
        Validators.pattern(/^(0[1-9]|1[0-2])\/([0-9]{2})$/),
        this.validateExpiryDate()
      ]],
      cvv: ['', [
        Validators.required,
        Validators.pattern(/^\d{3,4}$/)
      ]],
      cardHolderName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      billingAddress: this.fb.group({
        country: ['', Validators.required],
        address: ['', Validators.required],
        city: ['', Validators.required],
        postalCode: ['', Validators.required]
      })
    });
  }

  // Custom Validators
  private validateLuhn() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value.replace(/\s/g, '');
      if (!value) return null;
      
      let sum = 0;
      let isEven = false;
      
      // Loop through values starting from the right
      for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i));

        if (isEven) {
          digit *= 2;
          if (digit > 9) {
            digit -= 9;
          }
        }

        sum += digit;
        isEven = !isEven;
      }

      return (sum % 10 === 0) ? null : { 'luhn': true };
    };
  }

  private validateExpiryDate() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const [month, year] = value.split('/').map(Number);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const currentMonth = currentDate.getMonth() + 1;

      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        return { 'expired': true };
      }

      return null;
    };
  }

  // Form Helpers
  formatCardNumber(event: any): void {
    let value = event.target.value.replace(/\s/g, '');
    if (value.length > 0) {
      value = value.match(new RegExp('.{1,4}', 'g')).join(' ');
    }
    event.target.value = value;
  }

  formatExpiryDate(event: any): void {
    let value = event.target.value.replace(/\D/g, '');
    if (value.length >= 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
    event.target.value = value;
  }

  getCardTypeIcon(): string {
    const number = this.paymentForm.get('cardNumber')?.value || '';
    // Basic card type detection
    if (number.startsWith('4')) return 'assets/images/payment/visa.png';
    if (number.startsWith('5')) return 'assets/images/payment/mastercard.png';
    if (number.startsWith('3')) return 'assets/images/payment/amex.png';
    return 'assets/images/payment/generic-card.png';
  }

  async onSubmit() {
    if (this.selectedPaymentMethod === 'card' && this.cardField) {
      try {
        this.isSubmitting = true;
        this.errorMessage = '';

        await this.cardField.validate();
        
        const billingAddress = this.paymentForm.get('billingAddress')?.value;
        await this.cardField.submit({
          email: this.paymentForm.get('email')?.value,
          billingAddress: {
            countryCode: billingAddress?.country,
            city: billingAddress?.city,
            postcode: billingAddress?.postalCode,
            streetLine1: billingAddress?.address
          }
        });

      } catch (error: any) {
        console.error('Payment failed:', error);
        this.errorMessage = error.message || 'Payment failed. Please try again.';
      } finally {
        this.isSubmitting = false;
        this.cdr.detectChanges();
      }
    } else {
      // Handle other payment methods
      // ... your existing code ...
    }
  }



  private async createOrder(): Promise<CreateOrderResponse> {
    try {
      const orderData = this.createOrderDataFromForm();
      console
      const response = await firstValueFrom(
        this.subscriptionService.createOrder(orderData as unknown as CreateOrderRequest)
      ) as CreateOrderResponse;
      
      console.log('Order creation response:', response);

      if (!response.success || !response.token) {
        throw new Error('Invalid order response: ' + JSON.stringify(response));
      }

      this.currentOrderId = this.currentOrderToken = response.token ?? null;
      return response;

    } catch (error: any) {
      console.error('Detailed error in createOrder:', {
        error,
        message: error.message,
        stack: error.stack,
        formState: this.paymentForm.value,
        selectedPlan: this.selectedPlan,
        calculatedAmount: this.calculateTotal()
      });
      throw error;
    }
  }

  // UI Helpers
  getPlanFeatures(): string[] {
    const commonFeatures = [
      'All core features',
      'Priority support'
    ];

    if (this.selectedPlan === 'enterprise') {
      return [
        ...commonFeatures,
        'Everything in Professional Plan',
        'Enterprise Integrations (Azure, Jira, Git)',
        'Assessment Document Analysis',
        'Dedicated Account Manager',
        'Unlimited Team Members'
      ];
    }

    return [
      ...commonFeatures,
      'Everything in Free Trial',
      'Limitless Usage',
      'DevOps Metrics Evaluation',
      'Priority Support'
    ];
  }

  calculateTotal(): number {
    const pricing = this.planPricing[this.selectedPlan];
    return this.isAnnualBilling ? pricing.yearly : pricing.monthly;
  }

  getDiscount(): number {
    const pricing = this.planPricing[this.selectedPlan];
    return (pricing.monthly * 12) - pricing.yearly;
  }

  formatCurrency(amount: number): string {
    const currency = this.planPricing[this.selectedPlan].currency;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  }

  getPlanPrice(): number {
    const pricing = this.planPricing[this.selectedPlan];
    return this.isAnnualBilling ? (pricing.monthly * 12) : pricing.monthly;
  }

  toggleBillingCycle(): void {
    this.isAnnualBilling = !this.isAnnualBilling;
    this.selectedBillingCycle = this.isAnnualBilling ? 'yearly' : 'monthly';
    
    // Store the billing cycle choice
    sessionStorage.setItem('selectedBillingCycle', this.selectedBillingCycle);
    
    // Update selected plan price when billing cycle changes
    this.selectedPlanPrice = this.calculateTotal();
    
    console.log('Billing cycle toggled:', {
      isAnnual: this.isAnnualBilling,
      cycle: this.selectedBillingCycle,
      price: this.selectedPlanPrice
    });
  }

  getSubmitButtonText(): string {
    if (this.isSubmitting) return 'Processing...';
    return `Pay ${this.formatCurrency(this.calculateTotal())}`;
  }

  // Navigation
  goBack(): void {
    if (this.selectedPlan === 'enterprise') {
      this.router.navigate(['/register/company']);
    } else {
      this.router.navigate(['/register/verify']);
    }
  }

  // Mock method to get user information
    private getUserInfo() {
    return {
        isVerified: true,
        plan: 'professional' as UserType,
        companyDetails: {
            name: 'Example Company'
        }
    };
  }

  filteredCountries() {
    const searchValue = (this.countrySearchControl.value ?? '').toLowerCase();
    return this.countries.filter(country =>
      country.name.toLowerCase().includes(searchValue)
    );
  }

  selectCountry(country: Country) {
    this.paymentForm.get('billingAddress.country')?.setValue(country);
    this.countrySearchControl.setValue(country.name);
    this.showDropdown = false;
  }

  hideDropdown() {
    setTimeout(() => this.showDropdown = false, 200);
  }

  async upgradePlan(): Promise<void> {
    try {
      this.isSubmitting = true;
      this.showProcessingModal = true;
      this.errorMessage = '';

      const orderData = this.createOrderDataFromForm();

      console.log('Upgrading plan with:', {
        planType: this.selectedPlan,
        orderData
      });

      const response = await this.subscriptionService
        .createOrder(orderData as unknown as CreateOrderRequest)
        .toPromise();

      if (response?.success) {
        this.handlePaymentSuccess({
          orderId: response.order_id || '',
          paymentId: response.token || '',
          status: 'pending'
        });
      } else {
        throw new Error(response?.error || 'Upgrade failed');
      }
    } catch (error) {
      console.error('Error during plan upgrade:', error);
      this.handlePaymentFailure(error);
    } finally {
      this.isSubmitting = false;
      this.showProcessingModal = false;
    }
  }

  async initializeRevolutPay() {
    try {
      if (!this.revolutPayContainer || this.selectedPaymentMethod !== 'revolut') {
        return;
      }

      console.log('Starting payment process...');
      
      const { revolutPay } = await RevolutCheckout.payments({
        locale: 'en',
        mode: 'sandbox',
        publicToken: environment.revolutPublicKey
      });

      const paymentOptions = {
        currency: 'USD',
        totalAmount: this.calculateTotal() * 100,
        createOrder: async () => {
          try {
            const orderData = this.createOrderDataFromForm();
            console.log('Creating order with data:', orderData);
            
            const response = await firstValueFrom(
              this.subscriptionService.createOrder(orderData as unknown as CreateOrderRequest)
            );

            console.log('Order creation response:', response);

            const typedResponse = response as CreateOrderResponse;

            if (!typedResponse.success || !typedResponse.token) {
              throw new Error('Invalid order response: ' + JSON.stringify(response));
            }

            // Store the order ID for later use
            this.currentOrderToken = typedResponse.token ?? null;
            this.currentOrderId = typedResponse.order_id ?? null;
            console.log('Payment Options Order ID:', this.currentOrderId);
            console.log('Payment Options Token:', this.currentOrderToken);

            return { 
              publicId: typedResponse.token,
              orderId: typedResponse.order_id
             };
          } catch (error: any) {
            console.error('Error creating order:', error);
            this.errorMessage = error.message || 'Failed to create order';
            throw error;
          }
        }
      };

      console.log('Mounting Revolut Pay with options:', paymentOptions);

      this.revolutPay = revolutPay;
      await this.revolutPay.mount(
        this.revolutPayContainer.nativeElement, 
        paymentOptions
      );

      this.revolutPay.on('payment', async (event: any) => {
        console.log('Payment event received:', event);
        
        try {
          switch (event.type) {
            case 'success':
              if (!this.currentOrderId) {
                throw new Error('Order ID not found');
              }
              
              await this.handlePaymentSuccess({
                orderId: this.currentOrderId
              });
              break;

            case 'cancel':
              this.errorMessage = 'Payment was cancelled';
              break;

            case 'error':
              console.error('Payment error:', event.error);
              this.errorMessage = event.error?.message || 'An error occurred during payment';
              break;
          }
        } catch (error: any) {
          console.error('Error handling payment event:', error);
          this.errorMessage = error.message || 'Payment processing failed';
        }
      });

    } catch (error: any) {
      console.error('Error initializing Revolut Pay:', error);
      this.errorMessage = error.message || 'Failed to initialize payment system';
    }
  }

  public async handlePaymentSuccess(paymentData: PaymentData) {
    console.log('PaymentComponent: Starting payment success handler', paymentData);

    try {
      if (paymentData.status === 'success') {
        console.log('PaymentComponent: Payment verified successfully');
        
        // Get company details from session storage
        const companyDetails = JSON.parse(sessionStorage.getItem('companyDetails') || '{}');
        console.log('PaymentComponent: Retrieved company details from session', companyDetails);
        
        // Store company details for enterprise plan
        if (this.selectedPlan === 'enterprise' && companyDetails) {
          console.log('PaymentComponent: Processing enterprise plan company details');
          const email = companyDetails.company?.email;
          
          if (email) {
            console.log('PaymentComponent: Calling completeRegistration', { email });
            const response = await firstValueFrom(
              this.companyService.completeRegistration(email, companyDetails)
            );
            
            if (!response.success) {
              console.error('PaymentComponent: Failed to store company details', response);
              throw new Error(response.message);
            }
            console.log('PaymentComponent: Company details stored successfully');

            // Register team members after company registration with company ID
            if (companyDetails.teamMembers?.length > 0) {
              const teamResponse = await firstValueFrom(
                this.companyService.registerTeamMembers(
                  companyDetails.teamMembers,
                  response.companyId  // Pass the company ID from the registration response
                )
              );
              
              if (!teamResponse.success) {
                console.error('Failed to register team members:', teamResponse.message);
                // Consider how to handle this error - maybe show a warning but continue
              }
            }
          }
        }

        // Update subscription
        console.log('PaymentComponent: Updating subscription');
        const subscriptionResponse = await firstValueFrom(
          this.subscriptionService.updateSubscription({
            user_id: this.authService.userValue?.id?.toString() ?? '',
            plan: this.selectedPlan,
            billing_cycle: this.isAnnualBilling ? 'annual' : 'monthly',
            order_id: paymentData.orderId
          })
        );
        console.log('PaymentComponent: Subscription updated', subscriptionResponse);

        // Navigate to success page
        console.log('PaymentComponent: Navigating to success page');
        await this.router.navigate(['/app/payment/success'], {
          queryParams: {
            planType: this.selectedPlan,
            orderId: paymentData.orderId,
            amount: this.calculateTotal(),
            billingCycle: this.isAnnualBilling ? 'annual' : 'monthly'
          }
        });

      } else {
        console.warn('PaymentComponent: Payment not completed', paymentData.status);
        this.errorMessage = 'Payment is still pending. Please complete the payment process.';
        this.isSubmitting = false;
        this.cdr.detectChanges();
      }
    } catch (error) {
      console.error('PaymentComponent: Error in handlePaymentSuccess', error);
      this.errorMessage = 'An error occurred while updating your subscription. Please try again.';
      this.isSubmitting = false;
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    console.log('View initialized');
    this.viewInitialized = true;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    // Cleanup
    if (this.revolutPay) {
      this.revolutPay.destroy();
    }
    if (this.revolutInstance) {
      this.revolutInstance.unmount();
    }
    if (this.cardField) {
      this.cardField.destroy();
    }
  }

  private async initializeRevolutCardField() {
    try {
      const targetElement = await this.waitForElement('#revolut-card-field');
      const submitButton = document.getElementById('button-submit');

      if (!submitButton) {
        throw new Error('Submit button not found');
      }

      const RevolutCheckout = await this.loadRevolutScript();
      if (!RevolutCheckout) {
        throw new Error('Revolut Pay script failed to load');
      }

      const { createCardField } = await RevolutCheckout(this.currentOrderToken);
      console.log('Creating card field with token:', this.currentOrderToken);

      const cardField = createCardField({
        target: targetElement,
        onSuccess: async (response: any) => {
          console.log('Payment successful response:', response);
          try {
            // Call handlePaymentSuccess after successful payment
            await this.handlePaymentSuccess({
              orderId: this.currentOrderId ?? '',
              paymentId: response?.paymentId,
              status: 'success'
            });
            console.log('CreateCardField: OrderID:', this.currentOrderId);
            this.showSuccessMessage('Payment successful! Thank you for your purchase.');
            
            // Store payment details
            await firstValueFrom(
              this.subscriptionService.storePaymentDetails(
                this.currentOrderId ?? '',
                this.currentOrderToken ?? '',
                this.calculateTotal(),
                this.isAnnualBilling ? 'annual' : 'monthly',
                this.selectedPlan,
                this.authService.userValue?.id?.toString() ?? '',
              )
            );
            console.log('FirstValueFrom: OrderID:', this.currentOrderId);
            // Update subscription
            await firstValueFrom(
              this.subscriptionService.updateSubscription({
                user_id: this.authService.userValue?.id?.toString() ?? '',
                plan: this.selectedPlan,
                billing_cycle: this.isAnnualBilling ? 'annual' : 'monthly',
                order_id: this.currentOrderId ?? ''
              })
            );

            console.log('Subscription updated successfully');
            
            // Reset submission state before navigation
            this.isSubmitting = false;
            this.cdr.detectChanges();
            
            await this.router.navigate(['/app/payment/success'], {
              queryParams: {
                planType: this.selectedPlan,
                orderId: this.currentOrderId,
                amount: this.calculateTotal(),
                billingCycle: this.isAnnualBilling ? 'annual' : 'monthly',
                paymentDate: new Date().toISOString()
              }
            });
          } catch (error) {
            console.error('Error handling success:', error);
            this.isSubmitting = false;
            this.cdr.detectChanges();
          }
        },
        onError: (error: any) => {
          console.error('Payment failed:', error);
          this.errorMessage = error.message || 'Payment failed. Please try again.';
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }
      });

      // Add click handler for submit button
      submitButton.addEventListener('click', async () => {
        try {
          this.isSubmitting = true;
          this.errorMessage = '';
          this.cdr.detectChanges();

          await cardField.validate();
          
          const billingAddress = this.paymentForm.get('billingAddress');
          await cardField.submit({
            email: this.authService.userValue?.email,
            name: billingAddress?.get('cardholderName')?.value,
            billingAddress: {
              countryCode: billingAddress?.get('country')?.value?.code,
              streetLine1: billingAddress?.get('address')?.value,
              city: billingAddress?.get('city')?.value,
              postcode: billingAddress?.get('postalCode')?.value
            }
          });
        } catch (error: any) {
          console.error('Submit failed:', error);
          this.errorMessage = error.message || 'Payment failed';
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }
      });

      return cardField;
    } catch (error) {
      console.error('Error initializing Revolut card field:', error);
      this.isSubmitting = false;
      this.cdr.detectChanges();
      throw error;
    }
  }

  private waitForElement(selector: string): Promise<Element> {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector)!);
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector)!);
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  public async handlePayment() {
    try {
      if (!this.cardField) {
        throw new Error('Card field not initialized');
      }

      this.isLoading = true;
      this.cardFieldError = '';

      await this.cardField.validate();
      
      // Submit with customer details
      await this.cardField.submit({
        name: 'Customer Name',
        email: 'customer@example.com',
        billingAddress: {
          countryCode: 'GB',
          postcode: '12345'
        }
      });

    } catch (error) {
      console.error('Payment failed:', error);
      this.cardFieldError = 'Payment failed. Please try again.';
    } finally {
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  // Step 1: Create order and get token
  private createOrderDataFromForm(): CreateOrderRequest {
    const billingAddress = this.paymentForm.get('billingAddress')?.value;
    return {
      amount: this.calculateTotal(),
      currency: this.planPricing[this.selectedPlan].currency,
      plan: this.selectedPlan,
      billingCycle: this.selectedBillingCycle,
      billing_address: {
        country: billingAddress?.country?.code || '',
        address: billingAddress?.address || '',
        city: billingAddress?.city || '',
        postal_code: billingAddress?.postalCode || ''
      }
    };
  }

  // Add this method to handle container creation
  onRevolutContainerCreated(): void {
    console.log('Revolut container created');
    setTimeout(() => {
      this.initializeRevolutCardField().catch(error => {
        console.error('Failed to initialize Revolut:', error);
        this.errorMessage = 'Failed to initialize payment system';
        this.cdr.detectChanges();
      });
    }, 100);
    }


  // Test method to verify the order creation flow
  async testOrderCreation() {
    try {
      const order = await this.createOrder();
      console.log('Order created successfully:', {
        success: order.success,
        orderId: order.order_id,
        token: order.token,
        checkoutUrl: order.checkout_url
      });
    } catch (error: any) {
      console.error('Test order creation failed:', error);
      this.errorMessage = error.message;
      this.cdr.detectChanges();
    }
  }

  private handlePaymentFailure(error: any): void {
    console.error('Payment failed:', error);
    this.errorMessage = error.message || 'Payment processing failed';
    this.cdr.detectChanges();
  }

  private async loadRevolutScript(): Promise<any> {
    if ((window as any).RevolutCheckout) {
      return (window as any).RevolutCheckout;
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://sandbox-merchant.revolut.com/embed.js';
      script.onload = () => resolve((window as any).RevolutCheckout);
      script.onerror = () => reject(new Error('Failed to load Revolut script'));
      document.head.appendChild(script);
    });
  }

  // Add a method to show success message (you can implement this according to your UI needs)
  private showSuccessMessage(message: string) {
    this.errorMessage = ''; // Clear any error messages
    this.successMessage = message;
    this.cdr.detectChanges();
  }

  calculateTotalPrice(): number {
    // Get team members from session storage if available
    const companyDetails = JSON.parse(sessionStorage.getItem('companyDetails') || '{}');
    this.teamMembers = companyDetails.teamMembers || [];
    
    // Calculate total price
    const additionalMembers = Math.max(0, this.teamMembers.length);
    const basePrice = this.isAnnualBilling ? this.YEARLY_BASE_ENTERPRISE_PRICE : this.MONTHLY_BASE_ENTERPRISE_PRICE;
    const memberPrice = this.isAnnualBilling ? this.YEARLY_ADDITIONAL_MEMBER_PRICE : this.MONTHLY_ADDITIONAL_MEMBER_PRICE;
    
    const totalPrice = basePrice + (additionalMembers * memberPrice);
    console.log('Calculated price:', {
      basePrice,
      additionalMembers,
      additionalCost: additionalMembers * memberPrice,
      totalPrice,
      billingCycle: this.isAnnualBilling ? 'yearly' : 'monthly'
    });
    return totalPrice;
  }
}