import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  template: `
    <footer class="d-footer">
        <div class="row align-items-center justify-content-between">
            <div class="col-auto">
                <p class="mb-0">© {{currentYear}} {{companyName}}. {{rightText}}</p>
            </div>
            <div class="col-auto">
                <p class="mb-0" *ngIf="additionalText">{{additionalText}}</p>
            </div>
        </div>
    </footer>
  `
})
export class FooterComponent {
  @Input() companyName: string = 'VDF AI';
  @Input() rightText: string = 'All Rights Reserved.';
  @Input() additionalText?: string;
  
  currentYear: number = new Date().getFullYear();
}