// src/app/services/authorization.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

export type UserType = 'free' | 'professional' | 'enterprise';

interface FeatureConfig {
  enabled: boolean;
  limit?: number;
}

interface PermissionsResponse {
  success: boolean;
  data: {
    user_type: UserType;
    features: Record<string, FeatureConfig>;
  };
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private featurePermissions = new BehaviorSubject<Record<string, FeatureConfig>>({});
  private userType = new BehaviorSubject<UserType | null>(null);

  // Public observables for components to subscribe to
  public permissions$ = this.featurePermissions.asObservable();
  public userType$ = this.userType.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    // Try to load cached permissions on service initialization
    const cachedPermissions = localStorage.getItem('userPermissions');
    if (cachedPermissions) {
      try {
        const { userType, features } = JSON.parse(cachedPermissions);
        this.userType.next(userType);
        this.featurePermissions.next(features);
      } catch (e) {
        console.error('Error parsing cached permissions:', e);
      }
    }
  }

  /**
   * Fetch user permissions from backend API.
   * @returns Observable that resolves when permissions are loaded
   */
  loadUserPermissions(): Observable<void> {
    const token = this.authService.token;
    
    if (!token) {
      console.error('No authentication token found');
      return new Observable(subscriber => {
        subscriber.error('No authentication token');
        subscriber.complete();
      });
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http
      .get<PermissionsResponse>(`${environment.apiUrl}/auth/permissions`, { headers })
      .pipe(
        tap(response => {
          console.log('Permissions response:', response);
        }),
        map(response => {
          if (!response.success) {
            throw new Error(response.error || 'Failed to load permissions');
          }

          const { user_type, features } = response.data;
          
          // Update the subjects with new data
          this.userType.next(user_type);
          this.featurePermissions.next(features);

          // Cache the permissions
          localStorage.setItem('userPermissions', JSON.stringify({
            userType: user_type,
            features
          }));

          console.log('User Type:', user_type);
          console.log('Feature Permissions:', features);
        }),
        catchError(error => {
          console.error('Error loading permissions:', error);
          throw error;
        })
      );
  }

  /**
   * Clear stored permissions (useful for logout)
   */
  clearPermissions(): void {
    localStorage.removeItem('userPermissions');
    this.userType.next(null);
    this.featurePermissions.next({});
  }

  /**
   * Check if a feature is accessible for the user
   * @param feature Feature name
   * @returns true if feature is enabled, false otherwise
   */
  canAccessFeature(feature: string): boolean {
    const permissions = this.featurePermissions.getValue();
    return permissions[feature]?.enabled ?? false;
  }

  /**
   * Get the usage limit of a feature for the user
   * @param feature Feature name
   * @returns limit of usage, if any
   */
  getFeatureLimit(feature: string): number | undefined {
    const permissions = this.featurePermissions.getValue();
    return permissions[feature]?.limit;
  }

  /**
   * Get current user type
   * @returns current user type or null if not set
   */
  getCurrentUserType(): UserType | null {
    return this.userType.getValue();
  }

  /**
   * Check if user has enterprise access
   * @returns boolean indicating if user has enterprise access
   */
  isEnterprise(): boolean {
    return this.userType.getValue() === 'enterprise';
  }

  /**
   * Check if user has professional access
   * @returns boolean indicating if user has professional access
   */
  isProfessional(): boolean {
    return this.userType.getValue() === 'professional';
  }

  /**
   * Check if user is on free tier
   * @returns boolean indicating if user is on free tier
   */
  isFree(): boolean {
    return this.userType.getValue() === 'free';
  }

  /**
   * Check if a feature requires upgrade
   * @param feature Feature name
   * @returns boolean indicating if feature requires upgrade
   */
  requiresUpgrade(feature: string): boolean {
    const permissions = this.featurePermissions.getValue();
    return !permissions[feature]?.enabled;
  }

  hasAccessToChatHistory(): boolean {
    return this.canAccessFeature('chat-history');
  }

  hasAccessToChatMessages(): boolean {
    return this.canAccessFeature('chat-messages');
  }

  getChatHistoryLimit(): number | undefined {
    return this.getFeatureLimit('chat-history');
  }

  getChatMessagesLimit(): number | undefined {
    return this.getFeatureLimit('chat-messages');
  }

}