import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-password-setup',
  templateUrl: './password-setup.component.html',
  styleUrls: ['./password-setup.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule]
})
export class PasswordSetupComponent implements OnInit {
  token: string = '';
  password: string = '';
  confirmPassword: string = '';
  errorMessage: string = '';
  successMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];
    console.log('Token:', this.token);
    if (!this.token) {
      this.errorMessage = 'Invalid or missing token.';
    }
  }

  validateTokenAndSetupPassword(): void {
    if (this.password !== this.confirmPassword) {
      this.errorMessage = 'Passwords do not match.';
      return;
    }

    this.companyService.validateTokenAndSetupPassword(this.token, this.password)
      .subscribe(
        response => {
          if (response.success) {
            this.successMessage = 'Password setup successful. Redirecting to login...';
            setTimeout(() => this.router.navigate(['public/login']), 3000);
          } else {
            this.errorMessage = response.message;
          }
        },
        error => {
          this.errorMessage = 'An error occurred. Please try again later.';
        }
      );
  }
}