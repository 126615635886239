import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from '../../services/social-auth.service';
import { AuthService } from '../../services/auth.service';

@Component({
  template: '<div>Processing login...</div>'
})
export class SocialAuthCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const code = this.route.snapshot.queryParamMap.get('code');
    const provider = this.route.snapshot.data['provider'];

    if (!code) {
      this.router.navigate(['/auth/login']);
      return;
    }

    const handler = provider === 'google' 
      ? this.socialAuthService.handleGoogleCallback(code)
      : this.socialAuthService.handleFacebookCallback(code);

    handler.subscribe({
      next: (response) => {
        if (response.success && response.user) {
          // Store the token and redirect
          this.authService.setAuthToken(response.user.token);
          this.router.navigate(['/app/dashboard']);
        } else {
          this.router.navigate(['/auth/login']);
        }
      },
      error: () => {
        this.router.navigate(['/auth/login']);
      }
    });
  }
}