<section class="auth bg-base d-flex flex-wrap">
  <div class="auth-left d-lg-block d-none">
    <div class="d-flex align-items-center flex-column h-100 justify-content-center">
      <img src="https://imagedelivery.net/soJx4BiMTBvAFlSCQpzu3A/b2ee7753-3ba0-4fae-8642-a614920ccc00/public" alt="">
    </div>
  </div>
  
  <div class="col-md-6 mt-90">
    <div class="card">
      <div class="card-body">
        <a routerLink="/" class="mb-40 max-w-290-px">
          <img src="/assets/images/scrum/theme/logo/light_168x44.png" alt="">
        </a>
        
        <h6 class="mb-4 text-xl">Set Up Your Password</h6>
        <p class="text-neutral-500 mb-32">Please create a secure password for your account.</p>

        <!-- Alert Messages -->
        <div *ngIf="errorMessage" class="alert alert-danger radius-8 mb-24">
          {{ errorMessage }}
        </div>
        <div *ngIf="successMessage" class="alert alert-success radius-8 mb-24">
          {{ successMessage }}
        </div>

        <!-- Password Setup Form -->
        <form (ngSubmit)="validateTokenAndSetupPassword()">
          <div class="mb-20">
            <label for="password" class="form-label fw-semibold text-primary-light text-sm mb-8">
              New Password <span class="text-danger-600">*</span>
            </label>
            <input 
              type="password" 
              id="password" 
              class="form-control radius-8" 
              [(ngModel)]="password" 
              name="password" 
              placeholder="Enter your password"
              required>
          </div>

          <div class="mb-32">
            <label for="confirmPassword" class="form-label fw-semibold text-primary-light text-sm mb-8">
              Confirm Password <span class="text-danger-600">*</span>
            </label>
            <input 
              type="password" 
              id="confirmPassword" 
              class="form-control radius-8" 
              [(ngModel)]="confirmPassword" 
              name="confirmPassword" 
              placeholder="Confirm your password"
              required>
          </div>

          <button 
            type="submit" 
            class="btn btn-primary w-100 text-md px-24 py-12 radius-8">
            Set Password
          </button>
        </form>
      </div>
    </div>
  </div>
</section>