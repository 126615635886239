<div class="dashboard-main">
    <app-header></app-header>
    <div class="dashboard-main-body d-flex flex-column min-vh-100">
        <div class="container">
        <!-- Header Section -->
        <div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
            <h6 class="fw-semibold mb-0">View Profile</h6>
            <ul class="d-flex align-items-center gap-2">
                <li class="fw-medium">
                    <a routerLink="/app/dashboard" class="d-flex align-items-center gap-1 hover-text-primary">
                        <iconify-icon icon="solar:home-smile-angle-outline" class="icon text-lg"></iconify-icon>
                        Dashboard
                    </a>
                </li>
                <li>-</li>
                <li class="fw-medium">View Profile</li>
            </ul>
        </div>

        <!-- Loading Spinner -->
        <div *ngIf="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="row gy-4" *ngIf="!isLoading">
            <!-- Profile Info Card -->
            <div class="col-lg-4">
                <div class="user-grid-card position-relative border radius-16 overflow-hidden bg-base h-100">
                    <!-- Placeholder for maintaining layout -->
                    <div class="image-placeholder"></div>
                    <div class="pb-24 ms-16 mb-24 me-16 mt--100">
                        <div class="text-center border border-top-0 border-start-0 border-end-0">
                            <img [src]="imagePreviewUrl || 'assets/images/default-avatar.png'" 
                                 alt="Profile Image" 
                                 class="border br-white border-width-2-px w-200-px h-200-px rounded-circle object-fit-cover">
                            <h6 class="mb-0 mt-16">{{ userProfile?.name }} {{ userProfile?.surname }}</h6>
                            <span class="text-secondary-light mb-16">{{ userProfile?.email }}</span>
                            <div class="mt-2">
                                <span class="badge bg-primary">{{ userProfile?.planName || 'Free' }}</span>
                            </div>
                        </div>
                        <!-- Personal Info Section -->
                        <div class="mt-24">
                            <h6 class="text-xl mb-16">Personal Info</h6>
                            <ul>
                                <li class="d-flex align-items-center gap-1 mb-12">
                                    <span class="w-30 text-md fw-semibold text-primary-light">Name</span>
                                    <span class="w-70 text-secondary-light fw-medium">: {{ userProfile?.name }}</span>
                                </li>
                                <li class="d-flex align-items-center gap-1 mb-12">
                                    <span class="w-30 text-md fw-semibold text-primary-light">Surname</span>
                                    <span class="w-70 text-secondary-light fw-medium">: {{ userProfile?.surname }}</span>
                                </li>
                                <li class="d-flex align-items-center gap-1 mb-12">
                                    <span class="w-30 text-md fw-semibold text-primary-light">Title</span>
                                    <span class="w-70 text-secondary-light fw-medium">: {{ userProfile?.title || ' - ' }}</span>
                                </li>
                                <li class="d-flex align-items-center gap-1 mb-12">
                                    <span class="w-30 text-md fw-semibold text-primary-light">Email</span>
                                    <span class="w-70 text-secondary-light fw-medium">: {{ userProfile?.email }}</span>
                                </li>
                                <li class="d-flex align-items-center gap-1 mb-12">
                                    <span class="w-30 text-md fw-semibold text-primary-light">Member Since</span>
                                    <span class="w-70 text-secondary-light fw-medium">: {{ userProfile?.memberSince | date }}</span>
                                </li>
                                <li class="d-flex align-items-center gap-1">
                                    <span class="w-30 text-md fw-semibold text-primary-light">Short Bio</span>
                                    <span class="w-70 text-secondary-light fw-medium">: {{ userProfile?.bio || ' - ' }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Edit Profile Card -->
            <div class="col-lg-8">
                <div class="card h-100">
                    <div class="card-body p-24">
                        <!-- Tabs -->
                        <ul class="nav border-gradient-tab nav-pills mb-20 d-inline-flex" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link d-flex align-items-center px-24 active" 
                                        id="pills-edit-profile-tab" 
                                        data-bs-toggle="pill" 
                                        data-bs-target="#pills-edit-profile" 
                                        type="button" 
                                        role="tab">
                                    Edit Profile
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link d-flex align-items-center px-24" 
                                        id="pills-change-password-tab" 
                                        data-bs-toggle="pill" 
                                        data-bs-target="#pills-change-password" 
                                        type="button" 
                                        role="tab">
                                    Change Password
                                </button>
                            </li>
                        </ul>

                        <!-- Tab Content -->
                        <div class="tab-content" id="pills-tabContent">
                            <!-- Edit Profile Tab -->
                            <div class="tab-pane fade show active" id="pills-edit-profile" role="tabpanel">
                                <form [formGroup]="profileForm" (ngSubmit)="onProfileSubmit()">

                                    <!-- Profile Image Upload -->
                                    <div class="mb-24 mt-16">
                                        <div class="avatar-upload">
                                            <div class="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                                                <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" (change)="onImageSelected($event)" hidden>
                                                <label for="imageUpload" class="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle">
                                                    <iconify-icon icon="solar:camera-outline" class="icon"></iconify-icon>
                                                </label>
                                            </div>
                                            <div class="avatar-preview">
                                                <div id="imagePreview" [style.backgroundImage]="imagePreviewUrl ? 'url(' + imagePreviewUrl + ')' : 'none'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="mb-20">
                                                <label for="name" class="form-label">Name *</label>
                                                <input type="text" 
                                                       class="form-control" 
                                                       id="name" 
                                                       formControlName="name" 
                                                       placeholder="Enter Name"
                                                       [class.is-invalid]="isFieldInvalid(profileForm, 'name')">
                                                <div class="invalid-feedback" *ngIf="isFieldInvalid(profileForm, 'name')">
                                                    {{ getErrorMessage(profileForm, 'name') }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="mb-20">
                                                <label for="surname" class="form-label">Surname *</label>
                                                <input type="text" 
                                                       class="form-control" 
                                                       id="surname" 
                                                       formControlName="surname" 
                                                       placeholder="Enter Surname"
                                                       [class.is-invalid]="isFieldInvalid(profileForm, 'surname')">
                                                <div class="invalid-feedback" *ngIf="isFieldInvalid(profileForm, 'surname')">
                                                    {{ getErrorMessage(profileForm, 'surname') }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-20">
                                                <label for="title" class="form-label">Title</label>
                                                <input type="text" 
                                                       class="form-control" 
                                                       id="title" 
                                                       formControlName="title" 
                                                       placeholder="Enter Title">
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-20">
                                                <label for="bio" class="form-label">Short Bio</label>
                                                <textarea class="form-control" 
                                                          id="bio" 
                                                          formControlName="bio"
                                                          rows="4"
                                                          placeholder="Write a short bio..."></textarea>
                                                <small class="text-muted">Max 500 Chars</small>
                                            </div>
                                        </div>

                                        

                                        <div class="col-12">
                                            <div class="d-flex justify-content-center gap-3 mt-4">
                                                <button type="button" 
                                                        class="btn btn-outline-danger" 
                                                        (click)="profileForm.reset()">
                                                    Cancel
                                                </button>
                                                <button type="submit" 
                                                        class="btn btn-primary" 
                                                        [disabled]="profileForm.invalid || isLoading">
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Change Password Tab -->
                            <div class="tab-pane fade" id="pills-change-password" role="tabpanel">
                                <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()" class="password-form">
                                    <!-- Current Password -->
                                    <div class="mb-20">
                                        <label for="currentPassword" class="form-label fw-semibold text-primary-light text-sm mb-8">
                                            Current Password <span class="text-danger-600">*</span>
                                        </label>
                                        <div class="position-relative">
                                            <input [type]="showCurrentPassword ? 'text' : 'password'"
                                                class="form-control radius-8"
                                                id="currentPassword"
                                                formControlName="currentPassword"
                                                placeholder="Enter current password">
                                            <button type="button"
                                                class="btn btn-link position-absolute top-50 end-0 translate-middle-y text-gray-600"
                                                (click)="toggleCurrentPassword()">
                                                <iconify-icon [icon]="showCurrentPassword ? 'solar:eye-closed-linear' : 'solar:eye-linear'"
                                                    class="icon"></iconify-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="isFieldInvalid(passwordForm, 'currentPassword')" class="text-danger">
                                            {{ getErrorMessage(passwordForm, 'currentPassword') }}
                                        </div>
                                    </div>

                                    <!-- New Password -->
                                    <div class="mb-20">
                                        <label for="newPassword" class="form-label fw-semibold text-primary-light text-sm mb-8">
                                            New Password <span class="text-danger-600">*</span>
                                        </label>
                                        <div class="position-relative">
                                            <input [type]="showNewPassword ? 'text' : 'password'"
                                                class="form-control radius-8"
                                                id="newPassword"
                                                formControlName="newPassword"
                                                placeholder="Enter new password">
                                            <button type="button"
                                                class="btn btn-link position-absolute top-50 end-0 translate-middle-y text-gray-600"
                                                (click)="toggleNewPassword()">
                                                <iconify-icon [icon]="showNewPassword ? 'solar:eye-closed-linear' : 'solar:eye-linear'"
                                                    class="icon"></iconify-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="isFieldInvalid(passwordForm, 'newPassword')" class="text-danger">
                                            {{ getErrorMessage(passwordForm, 'newPassword') }}
                                        </div>
                                        <span class="mt-12 text-sm text-secondary-light d-block">Your password must have at least 8 characters</span>
                                    </div>

                                    <!-- Confirm Password -->
                                    <div class="mb-20">
                                        <label for="confirmPassword" class="form-label fw-semibold text-primary-light text-sm mb-8">
                                            Confirm New Password <span class="text-danger-600">*</span>
                                        </label>
                                        <div class="position-relative">
                                            <input [type]="showConfirmPassword ? 'text' : 'password'"
                                                class="form-control radius-8"
                                                id="confirmPassword"
                                                formControlName="confirmPassword"
                                                placeholder="Confirm your new password">
                                            <button type="button"
                                                class="btn btn-link position-absolute top-50 end-0 translate-middle-y text-gray-600"
                                                (click)="toggleConfirmPassword()">
                                                <iconify-icon [icon]="showConfirmPassword ? 'solar:eye-closed-linear' : 'solar:eye-linear'"
                                                    class="icon"></iconify-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="isFieldInvalid(passwordForm, 'confirmPassword')" class="text-danger">
                                            {{ getErrorMessage(passwordForm, 'confirmPassword') }}
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-center gap-3 mt-4">
                                        <button type="button" 
                                                class="btn btn-outline-danger" 
                                                (click)="passwordForm.reset()">
                                            Cancel
                                        </button>
                                        <button type="submit" 
                                                class="btn btn-primary" 
                                                [disabled]="passwordForm.invalid || isLoading">
                                            Update Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <!-- Alert Messages - Moved to bottom -->
                            <div class="position-fixed bottom-0 start-50 translate-middle-x mb-4" style="z-index: 1050;">
                                <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
                                    {{ errorMessage }}
                                     <button type="button" class="btn-close" (click)="errorMessage = ''"></button>
                                </div>
                                <div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show" role="alert">
                                    {{ successMessage }}
                                    <button type="button" class="btn-close" (click)="successMessage = ''"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
       
        </div>
    </div>
    <app-footer></app-footer>
</div>

