<aside class="sidebar">
    <button type="button" class="sidebar-close-btn">
    <iconify-icon icon="radix-icons:cross-2"></iconify-icon>
  </button>
    <div>
        <a routerLinkActive="active-page" href="/app/Dashboard" class="sidebar-logo">
            <img src="/assets/images/scrum/theme/logo/light_168x44.png" alt="site logo" class="light-logo">
            <img src="/assets/images/scrum/theme/logo/dark_168x44.png" alt="site logo" class="dark-logo">
            <img width="50" src="/assets/images/scrum/theme/logo/favicon.png" alt="site logo" class="logo-icon">
        </a>
    </div>
    <div class="sidebar-menu-area">
        <ul class="sidebar-menu" id="sidebar-menu">
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription == 8) ">
                <a routerLinkActive="active-page" href="/app/dashboard">
                    <iconify-icon icon="solar:home-smile-angle-outline" class="menu-icon"></iconify-icon>
                    <span>Dashboard</span>
                </a>
            </li>
            <li class="sidebar-menu-group-title">Agile Consultant</li>
            <li>
                <a routerLinkActive="active-page" href="/app/scrum-team-agent">
                    <iconify-icon icon="bi:chat-dots" class="menu-icon"></iconify-icon>
                    <span>Scrum Consultant</span>
                </a>
            </li>
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription == 8) ">
                <a routerLinkActive="active-page" href="/app/report-agent">
                    <iconify-icon icon="hugeicons:invoice-03" class="menu-icon"></iconify-icon>
                    <span>Report Analysis</span>
                </a>
            </li>
            <li class="sidebar-menu-group-title">Product Consultant</li>
            <li>
                <a routerLinkActive="active-page" href="/app/value-identification">
                    <iconify-icon icon="bi:chat-dots" class="menu-icon"></iconify-icon>
                    <span>Value Identification</span>
                </a>
            </li>
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription == 8) ">
                <a routerLinkActive="active-page" href="/app/persona">
                    <iconify-icon icon="hugeicons:invoice-03" class="menu-icon"></iconify-icon>
                    <span>Persona Generation</span>
                </a>
            </li>

            <ul class="sidebar-menu" id="sidebar-menu">
                <!-- Other menu items go here -->

<!-- Container for bottom dropdowns -->
<div class="bottom-dropdowns">
    <!-- First Dropdown: Help & Support -->
    <li class="dropdown help-support">
        <a href="javascript:void(0)" (click)="toggleDropdown('helpSupport')">
            <iconify-icon icon="octicon:info-24" class="menu-icon"></iconify-icon>
            <span>Help & Support</span>
        </a>
        <ul class="sidebar-submenu" [ngClass]="{'show': dropdownStates.helpSupport}">
            <!-- Terms & Conditions -->
            <li>
                <a href="/app/terms-conditions" 
                   routerLinkActive="active-page" 
                   (click)="$event.stopPropagation()">
                    <iconify-icon icon="octicon:info-24" class="menu-icon"></iconify-icon>
                    <span>Terms & Conditions</span>
                </a>
            </li>
            
            <!-- Privacy Policy -->
            <li>
                <a href="/app/privacy-policy" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <iconify-icon icon="octicon:info-24" class="menu-icon"></iconify-icon>
                    <span>Privacy Policy</span>
                </a>
            </li>
            
            <!-- FAQs -->
            <li>
                <a href="/app/faq" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <iconify-icon icon="mage:message-question-mark-round" class="menu-icon"></iconify-icon>
                    <span>FAQs</span>
                </a>
            </li>
            
            <!-- Help -->
            <li>
                <a href="/app/vdf-help" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <iconify-icon icon="mage:message-question-mark-round" class="menu-icon"></iconify-icon>
                    <span>Help</span>
                </a>
            </li>
        </ul>
    </li>

    <!-- Second Dropdown: Settings -->
    <li class="dropdown settings">
        <a href="javascript:void(0)" (click)="toggleDropdown('settings')">
            <iconify-icon icon="icon-park-outline:setting-two" class="menu-icon"></iconify-icon>
            <span>Settings</span>
        </a>
        <ul class="sidebar-submenu" [ngClass]="{'show': dropdownStates.settings}">
            <!-- Company Profile -->
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription == 8)">
                <a href="/app/company-profile"
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <i class="ri-circle-fill circle-icon text-primary-600 w-auto"></i>
                    <span>Company Profile</span>
                </a>
            </li>
            
            <!-- Integrations -->
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription == 8)">
                <a routerLinkActive="active-page" href="app/integrations" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <i class="ri-circle-fill circle-icon text-warning-main w-auto"></i>
                    <span>Integrations</span>
                </a>
            </li>
            
            <!-- People -->
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription == 8)">
                <a [routerLink]="linkPath('users','account')" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <i class="ri-circle-fill circle-icon text-info-main w-auto"></i>
                    <span>People</span>
                </a>
            </li>
            
            <!-- Account -->
            <li>
                <a href="/app/upgrade-plan" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <i class="ri-circle-fill circle-icon text-danger-main w-auto"></i>
                    <span>Upgrade Plan</span>
                </a>
            </li>
            
            <!-- Invoices -->
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription > 1)">
                <a [routerLink]="linkPath('invoices','account')" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <i class="ri-circle-fill circle-icon text-danger-main w-auto"></i>
                    <span>Invoices</span>
                </a>
            </li>
            
            <!-- Payment Method -->
            <li *ngIf="!user.isPublic && (user.isSuperAdmin || user.subscription > 1)">
                <a [routerLink]="linkPath('paymentMethods','account')" 
                   routerLinkActive="active-page"
                   (click)="$event.stopPropagation()">
                    <i class="ri-circle-fill circle-icon text-danger-main w-auto"></i>
                    <span>Payment Method</span>
                </a>
            </li>
        </ul>
    </li>
</div>
            </ul>


        </ul>
    </div>
</aside>

<!-- jQuery library js -->