// src/app/sidebar/sidebar.component.ts

import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

class MockAuthService {
  logout() {
    console.log("Mock logout called");
  }
}

class MockUtilityFunctions {
  redirectToLanding() {
    console.log("Mock redirect to landing");
  }
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA],  // Allows use of custom elements like <iconify-icon> and <gsb-page-container>
})
export class SidebarComponent {
  @Input() user = {
    subscription: 1,
    isPublic: false,
    isSuperAdmin: true,
    profilePictureUrl: '',
    title: 'Admin User'
  };

  dropdownStates = {
    helpSupport: false,
    settings: false
  };

  toggleDropdown(dropdown: 'helpSupport' | 'settings') {
    // Close other dropdowns when opening one
    Object.keys(this.dropdownStates).forEach(key => {
      if (key !== dropdown) {
        this.dropdownStates[key as keyof typeof this.dropdownStates] = false;
      }
    });
    
    // Toggle the clicked dropdown
    this.dropdownStates[dropdown] = !this.dropdownStates[dropdown];
  }

  public authService = new MockAuthService();  // Instance of the mock auth service
  public functions = new MockUtilityFunctions();  // Instance of the mock utility functions

  linkPath(path: string, param: string): string {
    return `/${path}/${param}`;
  }
}