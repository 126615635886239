// src/app/interfaces/payment.interface.ts

interface PlanPricing {
  monthly: number;
  annual: number;
  currency: string;
}

interface PlanPricingMap {
  [key: string]: PlanPricing;
}

interface PaymentStatus {
  status: 'pending' | 'processing' | 'completed' | 'failed';
  message?: string;
}

interface RevolutPaymentEvent {
  type: 'success' | 'error' | 'cancel';
  error?: string; 
  dropOffState?: string; 
}

interface PaymentData {
  orderId: string;
  paymentId?: string;
  status?: string;
}

interface OrderResponse {
  success: boolean;
  id: string;
  token: string;
  error?: string;
  type?: string;
  state?: string;
  created_at?: string;
  updated_at?: string;
  amount?: number;
  currency?: string;
  outstanding_amount?: number;
  capture_mode?: string;
  checkout_url?: string;
  enforce_challenge?: string;
}

// Define types based on documentation
type RevolutCheckoutError = {
  type: string;
  message: string;
  code?: number;
};

type CardFieldInstance = {
  submit: (meta?: Meta) => void;
  validate: () => void;
  destroy: () => void;
};

type Meta = {
  name?: string;
  email?: string;
  phone?: string;
  cardholderName?: string;
  billingAddress?: {
    countryCode: string;
    region?: string;
    city?: string;
    postcode: string;
    streetLine1?: string;
    streetLine2?: string;
  };
};

// Add these type definitions
interface RevolutCardField {
  validate: () => Promise<void>;
  submit: (data: any) => Promise<void>;
  destroy: () => void;
}

interface RevolutInstance {
  revolutPay: {
    mount: (element: HTMLElement, options: any) => Promise<void>;
  };
}

// Add type for RevolutCheckout
declare const RevolutCheckout: {
  payments: (config: {
    publicToken: string;
    mode: 'sandbox' | 'prod';
    locale?: string;
  }) => Promise<any>;
};


export interface CreateOrderResponse {
  success: boolean;
  order_id?: string;
  token?: string;
  checkout_url?: string;
  error?: string;
}

// Move interface to the top of the file, outside the class
interface ExtendedOrderData extends OrderData {
  billingCycle: 'monthly' | 'yearly';
}

export type UserType = 'free' | 'professional' | 'enterprise';

export interface BillingAddress {
  country: string;
  address: string;
  city: string;
  postalCode: string;
}

export interface OrderData {
  amount: number;
  currency: string;
  description: string;
  billingAddress: BillingAddress;
  plan: string;
  billingCycle: 'monthly' | 'yearly';
}


export interface PaymentVerificationResponse {
  success: boolean;
  data?: {
    payment_id: string;
    // ... other payment data
  };
  error?: string;
}

export interface SubscriptionStatus {
  userType: UserType;
  startDate: string;
  endDate: string;
  status: string;
}

export interface Feature {
  name: string;
  enabled: boolean;
  usageLimit?: number;
}

export interface PaymentResponse {
  success: boolean;
  error?: string;
  data?: any;
}

export interface VerifyPaymentResponse {
  success: boolean;
  data?: PaymentResponse;
  error?: string;
}

export interface UpgradeResponse {
  success: boolean;
  data?: {
    subscriptionId: number;
    userType: string;
    startDate: string;
    endDate: string;
    status: string;
  };
  error?: string;
}

export interface PaymentMethodData {
  type: string;
  id: string;
  initiator: 'customer' | 'merchant';
}

export interface SubscriptionInfo {
  userType: UserType;
  status: string;
  message?: string;
  canUpgrade: boolean;
}

export interface SubscriptionUpdateData {
  user_id: string;
  plan: string;
  billing_cycle: string;
  order_id?: string;
}

export interface PaymentVerificationRequest {
  order_id: string;
}

// Interface for internal use
export interface OrderData extends Omit<CreateOrderRequest, 'billing_address'> {
  billingAddress: {
    country: string;
    address: string;
    city: string;
    postalCode: string;
  };
  plan: string;
  billingCycle: 'monthly' | 'yearly';
}

// Interface for API request
export interface CreateOrderRequest {
  amount: number;
  currency: string;
  plan: string;
  billingCycle: string;
  billing_address: {
    country: string;
    address: string;
    city: string;
    postal_code: string;
  };
}

