import { 
  Component, 
  EventEmitter, 
  Input, 
  Output, 
  ViewChild, 
  ElementRef 
} from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RenameEvent } from '../../interfaces/chat.interface';

@Component({
  selector: 'app-chat-menu',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatMenuModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['./chat-menu.component.css'],
  templateUrl: './chat-menu.component.html',
})
export class ChatMenuComponent {
  @Input() sessionTitle: string = '';
  @Input() sessionId: number | undefined = undefined;  
  @Output() onDelete = new EventEmitter<void>();
  @Output() onCopy = new EventEmitter<void>();
  @Output() onRename = new EventEmitter<RenameEvent>();  // Update the type here

  isRenaming = false;
  newTitle: string = '';
  isMenuOpen = false;

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @ViewChild('renameInput') renameInput!: ElementRef;

  ngOnInit() {
    this.newTitle = this.sessionTitle;
  }

  toggleRename(): void {
    this.isRenaming = true;
    this.newTitle = this.sessionTitle;
    setTimeout(() => {
      if (this.renameInput?.nativeElement) {
        this.renameInput.nativeElement.focus();
        this.renameInput.nativeElement.select();
      }
    });
  }

  confirmRename(): void {
    if (this.sessionId && this.newTitle?.trim() && this.newTitle !== this.sessionTitle) {
      console.log('About to emit rename event:', {
        sessionId: this.sessionId,
        newTitle: this.newTitle.trim()
      });
      
      const renameEvent: RenameEvent = {
        sessionId: this.sessionId,
        newTitle: this.newTitle.trim()
      };
      
      this.onRename.emit(renameEvent);
    } else {
      console.log('Invalid rename parameters:', {
        sessionId: this.sessionId,
        newTitle: this.newTitle,
        originalTitle: this.sessionTitle
      });
    }
    this.isRenaming = false;
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  cancelRename(): void {
    this.newTitle = this.sessionTitle;
    this.isRenaming = false;
    this.menuTrigger.closeMenu();
  }

  handleCopy(): void {
    if (this.sessionId) {
      this.onCopy.emit();
    }
    this.menuTrigger.closeMenu();
  }

  handleDelete(): void {
    if (this.sessionId) {
      this.onDelete.emit();
    }
    this.menuTrigger.closeMenu();
  }
}