// src/app/services/chat.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SafeHtml } from '@angular/platform-browser';
import { TeamMetrics, DevOpsMetrics, MetricsContext } from '../interfaces/metrics.interface';



export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
  formattedContent?: SafeHtml;
}

export interface ChatSession {
  id: number;
  agent_type: string;
  created_at: string;
  last_message?: string;
  user_id?: string;
  title: string;
}

export interface GroupedSessions {
  today: ChatSession[];
  yesterday: ChatSession[];
  older: ChatSession[];
}

export interface ChatResponse {
  success: boolean;
  message?: string;
  error?: string;
  session_id?: number;
}

export interface ChatHistoryResponse {
  success: boolean;
  messages: ChatMessage[];
  error?: string;
}

export interface CopyChatResponse {
  success: boolean;
  data?: {
    message: string;
    metrics: Array<{
      metrics_type: string;
      metrics_data: any;
    }>;
  };
  error?: string;
}

export interface CausalLoopDiagram {
  variables: string[];
  relations: {
    from: string;
    to: string;
    type: 'positive' | 'negative';
  }[];
}

export interface CausalLoopData {
  diagram: CausalLoopDiagram;
  description: string;
  title: string;
}

export interface CausalLoopResponse {
  success: boolean;
  data?: CausalLoopData;
  error?: string;
}

interface ChatMessagePayload {
  message: string;
  session_id?: number;
  title: string;
  metrics_context?: MetricsContext;
  previous_response?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private validateMetricsContext(metrics?: MetricsContext): MetricsContext | undefined {
    if (!metrics) {
      return undefined;
    }

    const validatedMetrics: MetricsContext = {};

    // Validate team metrics
    if (metrics.teamMetrics && typeof metrics.teamMetrics === 'object') {
      // Separate handling for sprintEvents
      const sprintEvents = metrics.teamMetrics.sprintEvents || [];
      
      // Filter numeric metrics
      const filteredTeamMetrics = Object.entries(metrics.teamMetrics)
        .filter(([key, value]) => {
          // Skip sprintEvents in this filter
          if (key === 'sprintEvents') return false;
          return value !== undefined;
        })
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value
        }), {} as Partial<TeamMetrics>);

      // Add sprintEvents back to the filtered metrics
      if (sprintEvents.length > 0) {
        filteredTeamMetrics.sprintEvents = sprintEvents;
      }

      if (Object.keys(filteredTeamMetrics).length > 0) {
        validatedMetrics.teamMetrics = filteredTeamMetrics as TeamMetrics;
      }
      console.debug('Filtered team metrics:', filteredTeamMetrics);
    }

    // Validate DevOps metrics
    if (metrics.devOpsMetrics && typeof metrics.devOpsMetrics === 'object') {
      const filteredDevOpsMetrics = Object.entries(metrics.devOpsMetrics)
        .filter(([_, value]) => value !== undefined)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value
        }), {} as DevOpsMetrics);

      if (Object.keys(filteredDevOpsMetrics).length > 0) {
        validatedMetrics.devOpsMetrics = filteredDevOpsMetrics;
      }
      console.debug('Filtered DevOps metrics:', filteredDevOpsMetrics);
    }

    return Object.keys(validatedMetrics).length > 0 ? validatedMetrics : undefined;
  }

  deleteChatSession(sessionId: number): Observable<ChatResponse> {
    return this.http.delete<ChatResponse>(
      `${this.apiUrl}/chat/${sessionId}`
    ).pipe(
      tap(response => {
        console.log('Delete session response:', response);
      }),
      catchError(error => {
        console.error('Error deleting session:', error);
        throw error;
      })
    );
  }

  renameChatSession(sessionId: number, newTitle: string): Observable<any> {
    console.log(`Attempting to rename session ${sessionId} to "${newTitle}"`);
    return this.http.put<any>(
      `${this.apiUrl}/chat/${sessionId}/rename`,
      { title: newTitle }
    ).pipe(
      tap(response => {
        console.log('Rename response:', response);
        if (!response.success) {
          throw new Error(response.error || 'Failed to rename session');
        }
      }),
      catchError(error => {
        console.error('Error in rename request:', error);
        throw error;
      })
    );
  }

  getSessionDataForCopy(sessionId: number): Observable<CopyChatResponse> {
    return this.http.get<CopyChatResponse>(
      `${this.apiUrl}/chat/${sessionId}/copy`
    ).pipe(
      tap(response => {
        console.log('Get session data for copy response:', response);
      }),
      catchError(error => {
        console.error('Error getting session data:', error);
        throw error;
      })
    );
  }

 sendMessageWithDiagram(
    message: string,
    sessionId?: number,
    title?: string,
    metricsContext?: MetricsContext
): Observable<[ChatResponse, CausalLoopResponse]> {
    console.log('Sending message with metrics:', {
        message,
        sessionId,
        title,
        metricsContext
    });

    const validatedMetrics = this.validateMetricsContext(metricsContext);
    console.log('Validated metrics:', validatedMetrics);

    return forkJoin({
        chat: this.sendMessage(message, 'scrum_team_agent', sessionId, title, validatedMetrics),
        diagram: this.generateCausalLoop(message, validatedMetrics)
    }).pipe(
        map(({ chat, diagram }) => [chat, diagram])
    );
}

  // Method to save diagram
  saveCausalLoopDiagram(
    sessionId: number, 
    diagramData: any
): Observable<CausalLoopResponse> {
    return this.http.post<CausalLoopResponse>(
        `${this.apiUrl}/chat/diagram/${sessionId}`,
        { diagram_data: diagramData }
    );
}

  sendMessage(
    message: string,
    agentType: string = 'scrum_team_agent',
    sessionId?: number,
    title?: string,
    metricsContext?: MetricsContext,
    previousResponse?: string
  ): Observable<ChatResponse> {
    const payload: ChatMessagePayload = {
      message,
      session_id: sessionId,
      title: title || message.substring(0, 30),
      metrics_context: metricsContext
    };

    if (agentType === 'different_approach_agent' && previousResponse) {
      payload.previous_response = previousResponse;
    }

    return this.http.post<ChatResponse>(
      `${this.apiUrl}/chat/${agentType}`, 
      payload
    ).pipe(
      tap(response => {
        console.debug('Message sent with metrics:', {
          sessionId,
          metricsContext,
          success: response.success
        });
      }),
      catchError(error => {
        console.error('Error sending message:', error);
        throw error;
      })
    );
  }

  generateCausalLoop(
    message: string, 
    metricsContext?: MetricsContext
  ): Observable<CausalLoopResponse> {
    const payload = {
      message,
      metrics_context: metricsContext
    };

    return this.http.post<CausalLoopResponse>(
      `${this.apiUrl}/chat/causal-loop`, 
      payload
    ).pipe(
      tap(response => {
        console.debug('Causal loop generated with metrics:', {
          success: response.success,
          metricsContext
        });
      }),
      catchError(error => {
        console.error('Error generating causal loop:', error);
        throw error;
      })
    );
  }

  getChatMessages(sessionId: number): Observable<ChatHistoryResponse> {
    return this.http.get<ChatHistoryResponse>(
      `${this.apiUrl}/chat/messages/${sessionId}`
    );
  }

  checkHealth(): Observable<any> {
    return this.http.get(`${this.apiUrl}/health`);
  }

  getCausalLoopDiagram(sessionId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/chat/diagram/${sessionId}`);
  }
}