// src/app/registration/guards/registration.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RegistrationStateService } from '../services/registration-state.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanActivate {
  constructor(
    private registrationStateService: RegistrationStateService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const step = route.data['step'];
    const currentState = this.registrationStateService.getCurrentState();

    switch (step) {
      case 'details':
        if (!currentState.plan) {
          this.router.navigate(['/public/register/package-selection']);
          return false;
        }
        return true;

      case 'verification':
        if (!currentState.userDetails) {
          this.router.navigate(['/public/register/details']);
          return false;
        }
        return true;

      case 'company-details':
        if (!currentState.userDetails || !currentState.isVerified) {
          this.router.navigate(['/public/register/details']);
          return false;
        }
        return true;

      case 'payment':
        if (!currentState.userDetails || !currentState.isVerified) {
          this.router.navigate(['/public/register/details']);
          return false;
        }
        if (currentState.plan === 'enterprise' && !currentState.companyDetails) {
          this.router.navigate(['/public/register/company-details']);
          return false;
        }
        return true;

      case 'success':
        if (!currentState.userDetails || !currentState.isVerified) {
          this.router.navigate(['/public/register/details']);
          return false;
        }
        return true;

      default:
        this.router.navigate(['/public/register/package-selection']);
        return false;
    }
  }
}