// src/app/registration/components/user-details/user-details.component.ts

import { Component, OnInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { RegistrationService } from '../../../services/registration.service';
import { SocialAuthService } from '../../../services/social-auth.service';
import { UserType } from '../../../interfaces/user.interface';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import {
  FormFields,
  RegisterRequest,
  SocialAuthResponse,
  SocialRegistrationData,
  VerificationState,
  RegistrationData,
  RegistrationResponse
} from '../../../interfaces/registration.interface';
import { RegistrationStateService, RegistrationStep } from '../../services/registration-state.service'


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  registrationForm!: FormGroup;
  verificationForm!: FormGroup;
  
  isSubmitting = false;
  errorMessage = '';
  selectedPlan: UserType = 'free';
  showPassword = false;
  showConfirmPassword = false;
  returnUrl: string = '/';
  showVerification = false;
  showVerificationInput: boolean = false;
  verificationCode: string = '';

  verificationState: VerificationState = {
    showVerification: false,
    verificationCode: '',
    isCodeExpired: false,
    remainingTime: 300,
    canResend: false
  };

  private readonly VERIFICATION_TIMEOUT = 300000;
  private verificationTimer: ReturnType<typeof setInterval> | null = null;
  private destroy$ = new Subject<void>();
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private registrationService: RegistrationService,
    private socialAuthService: SocialAuthService,
    private registrationState: RegistrationStateService
  ) {}

  ngOnInit(): void {
    this.initializeForms();
    
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params['plan']) {
          this.selectedPlan = params['plan'] as UserType;
          this.registrationService.storeRegistrationData({
            plan: this.selectedPlan
          } as RegistrationData);
        }
        if (params['returnUrl']) {
          this.returnUrl = params['returnUrl'];
        }
      });

    const currentState = this.registrationState.getCurrentState();
    console.log('UserDetails: Current registration state:', currentState);
    
    if (!currentState.plan) {
      this.router.navigate(['/public/register/package-selection']);
    }
  }

  private initializeForms(): void {
    this.registrationForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      surname: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]],
      confirmPassword: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validators: this.passwordMatchValidator
    });
    this.registrationForm.valueChanges.subscribe(value => {
     
    });

    this.verificationForm = this.fb.group({
      verificationCode: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern(/^\d{6}$/)
      ]]
    });
  }
  
  ngOnDestroy(): void {
    this.clearVerificationTimer();
    this.destroy$.next();
    this.destroy$.complete();
  }

  isFormValid(): boolean {
    return this.registrationForm.valid && !this.isSubmitting;
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.registrationForm.get(fieldName);
    return !!(field && field.invalid && (field.dirty || field.touched));
  }

   getErrorMessage(fieldName: string): string {
    const control = this.registrationForm.get(fieldName);
    if (!control || !control.errors) return '';

    const errors = control.errors;
    
    if (fieldName === 'confirmPassword' && errors['passwordMismatch']) {
      return 'Passwords do not match';
    }

    if (errors['required']) {
      return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`;
    }
    
    if (errors['minlength']) {
      const minLength = errors['minlength'].requiredLength;
      return `${fieldName} must be at least ${minLength} characters`;
    }
    
    if (errors['email']) {
      return 'Please enter a valid email address';
    }
    
    if (errors['pattern']) {
      if (fieldName === 'password') {
        return 'Password must contain uppercase, lowercase, number and special character';
      }
      return 'Invalid format';
    }

    if (errors['requiredTrue']) {
      return 'You must accept the terms and conditions';
    }

    return 'Invalid input';
  }
  
  private getPatternErrorMessage(fieldName: string): string {
    if (fieldName === 'password') {
      return 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character';
    }
    if (fieldName === 'email') {
      return 'Please enter a valid email address';
    }
    return 'Invalid format';
  }

  // Form submission
  onSubmit() {
    console.log('submitForm: Form submitted with values', this.registrationForm.value);

    if (this.registrationForm.valid) {
      console.log('submitForm: Form is valid, proceeding with registration');
      this.isSubmitting = true;
      const formData = this.registrationForm.value;
      
      // First check if email exists
      this.registrationService.checkEmailExists(formData.email).subscribe({
        next: (response) => {
          if (response.success) {
            // Email is available, proceed with registration
            this.registrationService.sendVerificationEmail(
              formData.email, 
              formData.name,
              formData.surname,
              formData.password
            ).subscribe({
              next: (response) => {
                console.log('Verification email sent:', response);
                this.showVerificationInput = true;
                this.startVerificationTimer();
              },
              error: (error) => {
                this.errorMessage = error.message || 'Failed to send verification code';
                console.error('Error sending verification email:', error);
              },
              complete: () => {
                this.isSubmitting = false;
              }
            });
          } else {
            // Email exists, show error message
            this.errorMessage = response.message || 'Email already exists';
            this.isSubmitting = false;
          }
        },
        error: (error) => {
          this.errorMessage = error.message || 'Failed to check email';
          this.isSubmitting = false;
          console.error('Error checking email:', error);
        }
      });
    } else {
      console.log('submitForm: Form is invalid');
    }
  }

  // Form field validation
  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        if (control) control.markAsTouched();
      }
    });
  }

  // Verification methods
  verifyCode(): void {
    if (this.verificationForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      const email = this.registrationForm.get('email')?.value;
      const code = this.verificationForm.get('verificationCode')?.value;

      if (!email || !code) {
        this.errorMessage = 'Missing email or verification code';
        this.isSubmitting = false;
        return;
      }

      this.registrationService.verifyCode(email, code).pipe(
        takeUntil(this.destroy$),
        tap(response => {
          console.log('Verification response:', response);
          if (response.success) {
            this.completeRegistration();
          } else {
            this.errorMessage = response.message || 'Invalid verification code';
          }
        }),
        catchError(error => {
          this.errorMessage = error.message || 'An error occurred';
          console.error('Verification error:', error);
          return of(null);
        }),
        finalize(() => {
          this.isSubmitting = false;
        })
      ).subscribe();
    }
  }

  private startVerificationTimer(): void {
    this.clearVerificationTimer();
    this.verificationState.remainingTime = 300;
    this.verificationState.isCodeExpired = false;
    this.verificationState.canResend = false;

    this.verificationTimer = setInterval(() => {
      this.verificationState.remainingTime--;
      if (this.verificationState.remainingTime <= 0) {
        this.verificationState.isCodeExpired = true;
        this.verificationState.canResend = true;
        this.clearVerificationTimer();
      }
    }, 1000) as ReturnType<typeof setInterval>;
  }

  private clearVerificationTimer(): void {
    if (this.verificationTimer !== null) {
      clearInterval(this.verificationTimer);
      this.verificationTimer = null;
    }
  }

  resendVerificationCode(): void {
    if (!this.verificationState.canResend || this.isSubmitting) return;

    const email = this.registrationForm.get('email')?.value;
    if (!email) {
      this.errorMessage = 'Email is required';
      return;
    }

    this.isSubmitting = true;

    this.registrationService.resendVerificationCode(email).pipe(
      takeUntil(this.destroy$),
      tap(response => {
        if (response.success) {
          this.startVerificationTimer();
          this.verificationState.canResend = false;
          this.errorMessage = '';
        } else {
          this.errorMessage = response.message || 'Failed to resend verification code';
        }
      }),
      catchError(error => {
        this.errorMessage = error.message || 'An error occurred';
        return of(null);
      }),
      finalize(() => {
        this.isSubmitting = false;
      })
    ).subscribe();
  }

  // Registration completion
  private completeRegistration(): void {
    const registrationData = this.registrationService.getRegistrationData();
    const currentState = this.registrationState.getCurrentState();
    
    if (!registrationData) {
      this.errorMessage = 'Registration data not found';
      return;
    }

    // Update registration state with verified user details
    this.registrationState.updateState({
      userDetails: {
        email: registrationData.email,
        name: registrationData.name,
        password: registrationData.password
      },
      isVerified: true,
      step: 'verified' as RegistrationStep,
    });

    // Handle different flows based on plan
    switch (currentState.plan) {
      case 'free':
        // Complete registration and login for free plan
        this.registrationService.completeRegistration(registrationData).pipe(
          takeUntil(this.destroy$),
          tap(async response => {
            if (response.success) {
              try {
                // Add login after successful registration
                const user = await firstValueFrom( 
                  this.authService.login(registrationData.email, registrationData.password)
                );
                if (user) {
                  this.router.navigate(['/public/register/success']);
                } else {
                  console.error('Login failed after registration');
                }
              } catch (error) {
                console.error('Error during login:', error);
              }
            } else {
              this.errorMessage = response.message || 'Registration failed';
            }
          }),
          catchError(error => {
            this.errorMessage = error.message || 'An error occurred during registration';
            return of(null);
          })
        ).subscribe();
        break;

      case 'professional':
        // For professional plan, go directly to payment
        this.router.navigate(['/public/register/payment']);
        break;

      case 'enterprise':
        // For enterprise plan, go to company details first
        this.router.navigate(['/public/register/company-details']);
        break;

      default:
        console.error('Invalid plan type:', currentState.plan);
        this.errorMessage = 'Invalid plan selected';
    }
  }

  private handleRegistrationSuccess(): void {
    const currentState = this.registrationState.getCurrentState();
    this.authService.login(currentState.userDetails.email, currentState.userDetails.password);
    
    switch (currentState.plan) {
      case 'free':
        this.router.navigate(['/public/register/success']);
        break;
      case 'professional':
        this.router.navigate(['/public/register/payment']);
        break;
      case 'enterprise':
        this.router.navigate(['/public/register/company-details']);
        break;
      default:
        console.error('Invalid plan type:', currentState.plan);
        this.router.navigate(['/public/register/package-selection']);
    }
  }

  // Social authentication
  async signInWithGoogle(): Promise<void> {
    try {
      // Instead of making a direct API call, initiate OAuth flow
      this.socialAuthService.initiateGoogleLogin();
    } catch (error) {
      this.errorMessage = 'Failed to sign in with Google';
      console.error('Google sign-in error:', error);
    }
  }

  async signInWithFacebook(): Promise<void> {
    try {
      // Instead of making a direct API call, initiate OAuth flow
      this.socialAuthService.initiateFacebookLogin();
    } catch (error) {
      this.errorMessage = 'Failed to sign in with Facebook';
      console.error('Facebook sign-in error:', error);
    }
  }

  private async handleSocialAuthSuccess(result: SocialAuthResponse): Promise<void> {
    try {
      if (!result.user) {
        throw new Error('No user data received');
      }

      const socialData: SocialRegistrationData = {
        email: result.user.email,
        name: result.user.name,
        plan: this.selectedPlan,
        provider: result.user.provider as 'google' | 'facebook', // Ensure provider is typed correctly
        providerId: result.user.id
      };

      const response = await firstValueFrom(
        this.registrationService.completeSocialRegistration(socialData)
      );

      if (response.success) {
        this.handleRegistrationSuccess();
      } else {
        this.errorMessage = response.message || 'Social registration failed';
      }
    } catch (error) {
      this.errorMessage = 'Failed to process social authentication';
      console.error('Social auth processing error:', error);
    }
  }

  // UI helpers
  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  // Add password match validator
  private passwordMatchValidator(form: FormGroup) {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');

    if (password && confirmPassword && password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    }

    return null;
  }
}