// src/app/services/auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export type UserType = 'free' | 'professional' | 'enterprise';

export interface User {
  id: number;
  email: string;
  name: string;
  token: string;
  userType: UserType;
  permissions?: string[];
  companyDetails?: {
    name: string;
    address?: string;
    city?: string;
    country?: string;
    postalCode?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User | null>(this.getUserFromStorage());
    this.user = this.userSubject.asObservable();
  }

  isPublicRoute(url: string): boolean {
    return url.includes('/public/') || url.includes('/register/');
  }

  // Use this check in your auth interceptor and guards
  requiresAuth(url: string): boolean {
    return !this.isPublicRoute(url);
  }

  public get token(): string | null {
    const user = this.userValue;
    if (!user) {
      console.warn('No user found in storage');
      return null;
    }
    console.log('Retrieved token for user:', user.email);
    return user.token;
  }

  public get userValue(): User | null {
    return this.userSubject.value;
  }

  login(email: string, password: string): Observable<User> {
    console.log('Attempting login for:', email);
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        tap(response => console.log('Login response:', response)),
        map(response => {
          if (response.success && response.access_token) {
            const user: User = {
              id: response.user.id,
              email: response.user.email,
              name: response.user.name,
              token: response.access_token,
              userType: response.user.userType || 'free',
              permissions: response.user.permissions || []
            };
            this.setAuthToken(response.access_token);
            this.storeUser(user);
            return user;
          }
          throw new Error(response.error || 'Login failed');
        })
      );
  }

  // Add methods to check user type and permissions
  hasPermission(feature: string): boolean {
    const user = this.userValue;
    return user?.permissions?.includes(feature) || false;
  }

  getUserType(): UserType | null {
    return this.userValue?.userType || null;
  }

  isEnterprise(): boolean {
    return this.userValue?.userType === 'enterprise';
  }

  isProfessional(): boolean {
    return this.userValue?.userType === 'professional';
  }

  isFree(): boolean {
    return this.userValue?.userType === 'free';
  }

  logout(): void {
    console.log('Logging out user');
    localStorage.removeItem('user');
    localStorage.removeItem('auth_token');
    this.userSubject.next(null);
  }

  private storeUser(user: User): void {
    console.log('Storing user in localStorage:', user.email);
    localStorage.setItem('user', JSON.stringify(user));
    this.userSubject.next(user);
  }

  private getUserFromStorage(): User | null {
    try {
      const userStr = localStorage.getItem('user');
      if (!userStr) {
        console.log('No user found in localStorage');
        return null;
      }
      const user = JSON.parse(userStr);
      console.log('Retrieved user from storage:', user.email);
      return user;
    } catch (e) {
      console.error('Error parsing stored user:', e);
      return null;
    }
  }

  isAuthenticated(): boolean {
    const isAuth = !!this.userValue?.token;
    console.log('Authentication status:', isAuth);
    return isAuth;
  }

  updateUserType(newType: UserType): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/auth/update-plan`, { userType: newType })
      .pipe(
        map(response => {
          if (response.success) {
            const currentUser = this.userValue;
            if (currentUser) {
              const updatedUser = {
                ...currentUser,
                userType: newType
              };
              this.storeUser(updatedUser);
              return updatedUser;
            }
          }
          throw new Error(response.error || 'Failed to update plan');
        })
      );
  }

  // Method to request a password reset
  requestPasswordReset(email: string): Observable<any> {
    return this.http.post('/auth/request-password-reset', { email });
  }

  // Method to reset the password
  resetPassword(token: string, newPassword: string): Observable<any> {
    return this.http.post('/auth/reset-password', { token, newPassword });
  }

  refreshToken(): Observable<string> {
    return this.http.post<any>(
      `${environment.apiUrl}/auth/refresh-token`,
      {},
      { withCredentials: true }
    ).pipe(
      map(response => {
        if (response.token) {
          this.setAuthToken(response.token);
          return response.token;
        }
        throw new Error('No token received');
      })
    );
  }

  getAuthToken(): Observable<string> {
    const token = this.userValue?.token;
    if (!token) {
      return this.refreshToken();
    }
    return of(token);
  }

  setAuthToken(token: string): void {
    localStorage.setItem('auth_token', token);
    
    // Update the user object with the new token
    const currentUser = this.userValue;
    if (currentUser) {
      const updatedUser = {
        ...currentUser,
        token: token
      };
      this.storeUser(updatedUser);
    }
  }

  getUserEmail(): string | null {
    return this.userValue?.email || null;
  }

  verifyUserPassword(enteredPassword: string): Observable<boolean> {
    const email = this.userValue?.email;
    if (!email) {
      return throwError(() => new Error('User email not found'));
    }
  
    return this.http.post<{ isCorrect: boolean }>(`${environment.apiUrl}/auth/verify-password`, { email, enteredPassword })
      .pipe(
        map(response => response.isCorrect),
        catchError(error => {
          console.error('Password verification error:', error);
          // Instead of logging out, return false to indicate failure
          if (error.status === 401) {
            return of(false);
          }
          return throwError(() => new Error('Unable to verify password'));
        })
      );
  }
}