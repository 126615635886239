// src/app/upgrade-plan/upgrade-plan.component.ts

import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SubscriptionService } from '../services/subscription.service';
import { UserType } from '../interfaces/user.interface';
import { finalize } from 'rxjs/operators'; 
import { HeaderComponent } from '../shared/header/header.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { UpgradeResponse } from '../interfaces/upgrade-response.interface';
import { OrderData, PaymentMethodData, SubscriptionStatus } from '../interfaces/payment.interface';

interface PlanFeature {
    name: string;
    free: boolean | number;
    professional: boolean | number;
    enterprise: boolean | number;
  }

type LocalUserType = 'free' | 'professional' | 'enterprise';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.css'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, RouterModule, HeaderComponent, ReactiveFormsModule, FooterComponent]
})
export class UpgradePlanComponent implements OnInit {
  currentPlan: LocalUserType = 'free';
  returnUrl: string = '/';
  isLoading: boolean = false;
  errorMessage: string = '';
  successMessage: string = '';
  billingForm: FormGroup;
  paymentForm: FormGroup;
  selectedBillingCycle: 'monthly' | 'yearly' = 'monthly';
  selectedPlan = {
    price: 29.00,
    type: 'professional'
  };

  planFeatures: PlanFeature[] = [
    { name: 'Causal Loop Diagrams', free: 3, professional: 20, enterprise: true },
    { name: 'Jira Integration', free: false, professional: true, enterprise: true },
    { name: 'Team Support', free: false, professional: true, enterprise: true },
    { name: 'Different Approaches', free: 5, professional: 50, enterprise: true },
    { name: 'Custom Branding', free: false, professional: false, enterprise: true },
    { name: 'Priority Support', free: false, professional: true, enterprise: true },
  ];

  availablePlans: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder
  ) {
    this.billingForm = this.fb.group({
      country: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    });

    this.paymentForm = this.fb.group({
      number: ['', [Validators.required, Validators.pattern('^[0-9]{16}$')]],
      expiryMonth: ['', [Validators.required, Validators.pattern('^(0[1-9]|1[0-2])$')]],
      expiryYear: ['', [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      cvv: ['', [Validators.required, Validators.pattern('^[0-9]{3,4}$')]]
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['current']) {
        this.currentPlan = params['current'] as LocalUserType;
      }
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });

    this.fetchAvailablePlans();
  }

  fetchAvailablePlans(): void {
    this.subscriptionService.getSubscriptionStatus().subscribe((userInfo: SubscriptionStatus) => {
      console.log('User info:', userInfo);
      console.log('User type:', userInfo.userType);
      if (userInfo.userType === 'free') {
        this.availablePlans = ['professional', 'enterprise'];
      } else if (userInfo.userType === 'professional') {
        this.availablePlans = ['enterprise'];
      } else {
        this.availablePlans = [];
      }
      console.log('Available plans:', this.availablePlans);
    });
  }

  private showSuccessMessage(message: string): void {
    this.successMessage = message;
    this.errorMessage = '';
    setTimeout(() => {
      this.successMessage = '';
    }, 5000);
  }

  private showErrorMessage(message: string): void {
    this.errorMessage = message;
    this.successMessage = '';
    setTimeout(() => {
      this.errorMessage = '';
    }, 5000);
  }

  upgradeToPro() {
    const billingCycle = this.selectedBillingCycle;
    console.log('Selected Billing Cycle:', billingCycle);
    this.router.navigate(['/app/payment'], { 
      queryParams: { 
        plan: 'professional', 
        billingCycle 
      } 
    });
  }

  upgradeToEnterprise(): void {
    this.router.navigate(['/app/company-details'], { 
      queryParams: { 
        plan: 'enterprise',
        billingCycle: this.selectedBillingCycle
      } 
    });
  }

  // Helper methods for template
  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  getFeatureDisplay(value: boolean | number): string {
    if (this.isNumber(value)) {
      return `${value}×`;
    }
    return value ? '✓' : '✗';
  }

  goBack(): void {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }

  onTabChange(event: any): void {
    const selectedTabId = event.target.id;
    if (selectedTabId === 'pills-monthly-tab') {
      this.selectedBillingCycle = 'monthly';
    } else if (selectedTabId === 'pills-yearly-tab') {
      this.selectedBillingCycle = 'yearly';
    }
    
    // Store the selection
    sessionStorage.setItem('selectedBillingCycle', this.selectedBillingCycle);
    
    console.log('Billing cycle changed:', {
      cycle: this.selectedBillingCycle,
      selectedTabId
    });
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

}