import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '../services/company.service';
import { Country, countries } from '../shared/data/countries';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../shared/header/header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../shared/footer/footer.component';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent
  ]
})
export class CompanyProfileComponent implements OnInit {
  companyForm: FormGroup;
  filteredCountries: Country[] = countries;
  showCompanyForm = true;
  successMessage: string | null = null;
  errorMessage: string | null = null;

  companySizes: string[] = [
    'Small (1-50 employees)',
    'Medium (51-200 employees)',
    'Large (201-1000 employees)',
    'Enterprise (1000+ employees)'
  ];

  industries = [
    'Technology',
    'Healthcare',
    'Finance',
    'Manufacturing',
    'Retail',
    'Education',
    'Government',
    'Non-profit',
    'Other'
  ];

  constructor(private fb: FormBuilder, private companyService: CompanyService) {
    this.companyForm = this.fb.group({
      company_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: ['', Validators.required],
      country: [''],
      city: [''],
      state: [''],
      address: [''],
      company_size: [''],
      industry: ['']
    });
  }

  ngOnInit(): void {
    this.loadCompanyDetails();
  }

  loadCompanyDetails(): void {
    const companyId = this.getCompanyId();
    this.companyService.getCompanyDetails(companyId).subscribe((response: any) => {
      if (response.success && response.data) {
        this.companyForm.patchValue({
          company_name: response.data.company_name,
          email: response.data.company_email,
          website: response.data.website,
          country: response.data.country,
          city: response.data.city,
          state: response.data.state,
          address: response.data.billing_address,
          company_size: response.data.company_size,
          industry: response.data.industry
        });
      }
    });
  }

  onCountrySearch(event: Event): void {
    const input = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredCountries = countries.filter(country =>
      country.name.toLowerCase().includes(input)
    );
  }

  onNextButtonClick(): void {
    if (this.companyForm.valid) {
      const companyId = this.getCompanyId();
      const details = { ...this.companyForm.value, companyId };

      this.companyService.updateCompanyDetails(details).subscribe({
        next: (response) => {
          this.successMessage = 'Company details updated successfully!';
          this.errorMessage = null; // Clear any previous error message
        },
        error: (error) => {
          this.errorMessage = 'Failed to update company details. Please try again.';
          this.successMessage = null; // Clear any previous success message
        }
      });
    } else {
      this.companyForm.markAllAsTouched();
    }
  }

  isFirstFormValid(): boolean {
    return this.companyForm.valid;
  }

  updateCompanyDetails(): void {
    if (this.companyForm.valid) {
      const companyId = this.getCompanyId();
      const details = { ...this.companyForm.value, companyId };
      console.log('company-profile: Company Details:', details);
      this.companyService.updateCompanyDetails(details).subscribe({
        next: (response) => {
          console.log('Company details updated successfully:', response);
        },
        error: (error) => {
          console.error('Failed to update company details:', error);
        }
      });
    } else {
      this.companyForm.markAllAsTouched();
    }
  }

  getCompanyId(): number {
    // Implement logic to get the company ID from the form or other sources
    return 1; // Replace with actual logic
  }
}