<app-sidebar></app-sidebar>    
<div class="dashboard-main">
    <app-header></app-header>
<div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">

<div class="card basic-data-table">
    <div class="card-header p-0 border-0">
        <div class="responsive-padding-40-150 bg-gradient-start-3">
            <div class="row gy-4 align-items-center">
                <div class="col-xl-7">
                    <h4 class="mb-20">Frequently Asked Questions</h4>
                    <p class="mb-0 text-secondary-light max-w-634-px text-xl">Welcome to the VDF AI FAQ page! Here
                        you’ll find answers to the most common questions about our AI-powered SaaS platform, designed to
                        help individuals and organizations navigate their agility journey. Whether you’re curious about
                        our membership plans, billing process, or platform features, this section provides clear and
                        concise information to ensure you have a seamless experience with VDF AI. If you have additional
                        questions or need further assistance, our dedicated support team is always here to help. Explore
                        the FAQs below to learn more about how VDF AI can empower your team and transform your business
                        operations.</p>
                </div>
                <div class="col-xl-5 d-xl-block d-none">
                    <img src="https://imagedelivery.net/soJx4BiMTBvAFlSCQpzu3A/93ce7229-b138-4881-40a4-896b54062700/public" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="card-body bg-base responsive-padding-40-150">
        <div class="row gy-4">
            <div class="col-lg-4">
                <div class="active-text-tab flex-column nav-pills bg-base shadow py-0 px-24 radius-12 border"
                    id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom active" id="v-pills-about-us-tab" data-bs-toggle="pill" data-bs-target="#v-pills-about-us" type="button" role="tab" aria-controls="v-pills-about-us" aria-selected="true">General Questions</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom" id="v-pills-ux-ui-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ux-ui" type="button" role="tab" aria-controls="v-pills-ux-ui" aria-selected="false">About Us</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom" id="v-pills-development-tab" data-bs-toggle="pill" data-bs-target="#v-pills-development" type="button" role="tab" aria-controls="v-pills-development" aria-selected="false">UX UI Design</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16 border-bottom" id="v-pills-use-case-tab" data-bs-toggle="pill" data-bs-target="#v-pills-use-case" type="button" role="tab" aria-controls="v-pills-use-case" aria-selected="false">Development</button>
                    <button class="nav-link text-secondary-light fw-semibold text-xl px-0 py-16" id="v-pills-use-agency-tab" data-bs-toggle="pill" data-bs-target="#v-pills-use-agency" type="button" role="tab" aria-controls="v-pills-use-agency" aria-selected="false">How to can I use VSF AI</button>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-about-us" role="tabpanel"
                        aria-labelledby="v-pills-about-us-tab" tabindex="0">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Is there a free trial available?
                      </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, VDF AI offers a Free Membership plan designed for individuals to use the
                                        platform within predefined daily usage limits. You can sign up and start using
                                        the platform immediately without any payment.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Can I change my plan later?
                      </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Absolutely! You can upgrade or downgrade your membership plan at any time. For
                                        instance, Free Members can upgrade to Pro or Enterprise, and Pro Members can
                                        switch between monthly and yearly billing options or upgrade to Enterprise.
                                        Visit the “Account Settings” section to change your plan.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What is your cancellation policy?
                      </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        You can cancel your Pro or Enterprise membership at any time. If you cancel a
                                        paid plan, you will continue to have access to the paid features until the end
                                        of your current billing cycle. We do not offer refunds for partial periods
                                        except as required by applicable law.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Can other info be added to an invoice?
                      </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, additional details, such as your company name, tax ID, or address, can be
                                        included in your invoice. You can add this information under the “Billing
                                        Information” section in your account settings.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        How does billing work?
                      </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Billing for Pro Members is done either monthly or yearly, based on your chosen
                                        plan. Enterprise Members are billed according to the terms of their agreement.
                                        Payment methods include credit card, debit card, or any other methods we may
                                        offer.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        How do I change my account email?
                      </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        To change your account email, go to “Account Settings,” select “Profile,” and
                                        update your email address. You will receive a confirmation email at your new
                                        address to complete the change.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-ux-ui" role="tabpanel" aria-labelledby="v-pills-ux-ui-tab"
                        tabindex="0">
                        <div class="accordion" id="accordionExampleTwo">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#c-1" aria-expanded="true" aria-controls="c-1">
                        What is VDF AI?
                      </button>
                                </h2>
                                <div id="c-1" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExampleTwo">
                                    <div class="accordion-body">
                                        VDF AI is a technology company that offers an AI-based SaaS application designed
                                        to support individuals and companies on their agility journey. Our platform
                                        helps enhance team performance, facilitates transformation, provides leadership
                                        guidance, and assists with decision-making.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-2" aria-expanded="false" aria-controls="c-2">
                        Who can use VDF AI?
                      </button>
                                </h2>
                                <div id="c-2" class="accordion-collapse collapse" data-bs-parent="#accordionExampleTwo">
                                    <div class="accordion-body">
                                        VDF AI is designed for a wide range of users, including individual
                                        professionals, team leaders, and organizations seeking to improve their agility,
                                        team performance, and decision-making processes
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-3" aria-expanded="false" aria-controls="c-3">
                         Where is VDF AI based?
                      </button>
                                </h2>
                                <div id="c-3" class="accordion-collapse collapse" data-bs-parent="#accordionExampleTwo">
                                    <div class="accordion-body">
                                        VDF AI operates globally, and our headquarters are located in Sweden.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-4" aria-expanded="false" aria-controls="c-4">
                        How do I contact VDF AI for support or inquiries?
                      </button>
                                </h2>
                                <div id="c-4" class="accordion-collapse collapse" data-bs-parent="#accordionExampleTwo">
                                    <div class="accordion-body">
                                        You can contact our support team through the “Contact Us” page on our website or
                                        email us at info&#64;vdf.ai
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-development" role="tabpanel"
                        aria-labelledby="v-pills-development-tab" tabindex="0">
                        <div class="accordion" id="accordionExampleThree">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#c-7" aria-expanded="true" aria-controls="c-7">
                        Is the VDF AI platform user-friendly?
                      </button>
                                </h2>
                                <div id="c-7" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExampleThree">
                                    <div class="accordion-body">
                                        Yes, our platform is designed with a focus on user experience and simplicity. We
                                        use intuitive design principles to ensure that users can easily navigate and
                                        make the most of the platform’s features.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-8" aria-expanded="false" aria-controls="c-8">
                        How does VDF AI ensure a consistent user experience across devices?
                      </button>
                                </h2>
                                <div id="c-8" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleThree">
                                    <div class="accordion-body">
                                        Our platform is fully responsive and optimized for use across various devices,
                                        including desktops, laptops, tablets, and smartphones, providing a consistent
                                        experience regardless of the device.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-use-case" role="tabpanel"
                        aria-labelledby="v-pills-use-case-tab" tabindex="0">
                        <div class="accordion" id="accordionExampleFour">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#c-13" aria-expanded="true" aria-controls="c-13">
                       How often is the platform updated?
                      </button>
                                </h2>
                                <div id="c-13" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                        We regularly update the platform to add new features, enhance existing
                                        functionalities, and ensure security. Users are notified of significant updates
                                        or changes.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-14" aria-expanded="false" aria-controls="c-14">
                        Is the platform secure?
                      </button>
                                </h2>
                                <div id="c-14" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                        Yes, we prioritize security and use industry-standard encryption and security
                                        protocols to protect your data and ensure the safety of our platform.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-15" aria-expanded="false" aria-controls="c-15">
                        Can the platform be integrated with other tools or systems?
                      </button>
                                </h2>
                                <div id="c-15" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                        Yes, VDF AI offers various integration options to work seamlessly with other
                                        tools and platforms you may be using.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-use-agency" role="tabpanel"
                        aria-labelledby="v-pills-use-agency-tab" tabindex="0">
                        <div class="accordion" id="accordionExampleFIve">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl" type="button" data-bs-toggle="collapse" data-bs-target="#c-19" aria-expanded="true" aria-controls="c-19">
                        How do I get started with VDF AI?
                      </button>
                                </h2>
                                <div id="c-19" class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExampleFIve">
                                    <div class="accordion-body">
                                        To get started, sign up for a Free Membership on our website. Once registered,
                                        you can explore the platform, access features, and decide if you want to upgrade
                                        to a Pro or Enterprise plan.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-20" aria-expanded="false" aria-controls="c-20">
                        What are the key features of the VDF AI platform?
                      </button>
                                </h2>
                                <div id="c-20" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleFIve">
                                    <div class="accordion-body">
                                        The platform includes tools for team performance assessment, transformation
                                        guidance, leadership development, decision-making assistance, and much more.
                                        Depending on your membership plan, you may have access to additional features
                                        and analytics.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-21" aria-expanded="false" aria-controls="c-21">
                        What is your cancellation policy?
                      </button>
                                </h2>
                                <div id="c-21" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleFIve">
                                    <div class="accordion-body">
                                        Yes, you can try us for free for 30 days. If you want, we’ll provide you with a
                                        free, personalized
                                        30-minute onboarding call to get you up and running as soon as possible.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-22" aria-expanded="false" aria-controls="c-22">
                        Can I use VDF AI for team collaboration?
                      </button>
                                </h2>
                                <div id="c-22" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleFIve">
                                    <div class="accordion-body">
                                        Yes, VDF AI is designed for team collaboration, especially for Enterprise
                                        Members.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button text-primary-light text-xl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#c-23" aria-expanded="false" aria-controls="c-23">
                        Can I use VDF AI offline?
                      </button>
                                </h2>
                                <div id="c-23" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExampleFIve">
                                    <div class="accordion-body">
                                        No, VDF AI requires an internet connection to access and utilize its cloud-based
                                        features and services.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer> 
</div>
