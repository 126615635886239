// src/app/services/social-auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export interface SocialAuthResponse {
  success: boolean;
  user?: {
    id: string;
    email: string;
    name: string;
    token: string;
  };
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SocialAuthService {
  private apiUrl = `${environment.apiUrl}/auth`;

  constructor(private http: HttpClient) {}

  initiateGoogleLogin(): void {
    const clientId = environment.socialAuth.google.clientId;
    const redirectUri = environment.socialAuth.google.redirectUri;
    
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&response_type=code` +
      `&scope=email profile` +
      `&access_type=offline`;

    window.location.href = googleAuthUrl;
  }

  handleGoogleCallback(code: string): Observable<SocialAuthResponse> {
    return this.http.post<SocialAuthResponse>(
      `${this.apiUrl}/google/callback`,
      { code }
    );
  }

  initiateFacebookLogin(): void {
    const clientId = environment.socialAuth.facebook.clientId;
    const redirectUri = window.location.origin + '/auth/facebook/callback';
    
    const facebookAuthUrl = `https://www.facebook.com/v18.0/dialog/oauth?` +
      `client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=email public_profile`;

    window.location.href = facebookAuthUrl;
  }

  handleFacebookCallback(code: string): Observable<SocialAuthResponse> {
    return this.http.post<SocialAuthResponse>(
      `${this.apiUrl}/facebook/callback`,
      { code }
    );
  }
}