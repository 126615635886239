// src/app/services/jira.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { 
  SprintInfo, 
  SprintResponse, 
  TeamMetrics, 
  SprintMetrics 
} from '../interfaces/sprint.interface';
import {  JiraBoard, JiraBoardResponse, VelocityResponse } from '../interfaces/jira.interfaces'; 

export interface JiraTokenResponse {
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

export interface JiraConnectionStatus {
  success: boolean;
  isConnected: boolean;
  cloudId?: string;
  message?: string;
}

export interface JiraAuthResponse {
  success: boolean;
  message?: string;
  error?: string;
  cloudId?: string;
  accessToken?: string;
}

export interface JiraBasicAuth {
  authType: 'basic';
  url: string;
  username: string;
  password: string;
}

export interface JiraOAuth {
  authType: 'oauth';
  url: string;
  accessToken: string;
  accessTokenSecret: string;
  consumerKey: string;
  keyCert: string;
}

export interface JiraOAuth2 {
  authType: 'oauth2';
  url: string;
  clientId: string;
  accessToken: string;
}

export type JiraAuthConfig = JiraBasicAuth | JiraOAuth | JiraOAuth2;

export interface JiraConnectResponse {
  success: boolean;
  isConnected: boolean;
  message?: string;
  error?: string;
}

export interface JiraProjectResponse {
  success: boolean;
  projects: Array<{
    id: string;
    key: string;
    name: string;
    projectTypeKey: string;
  }>;
  error?: string;
}

export interface JiraBoardsByProjectResponse {
  success: boolean;
  boards: JiraBoard[];
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class JiraService {
  private apiUrl = `${environment.apiUrl}/jira`;
  private readonly OAUTH_POPUP_WIDTH = 600;
  private readonly OAUTH_POPUP_HEIGHT = 700;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

    generateState(): string {
    return Math.random().toString(36).substring(2);
  }
    state_generated = this.generateState();


  // Connection Management
  checkConnection(): Observable<JiraConnectResponse> {
    console.log('Checking Jira connection status...');
    return this.http.get<JiraConnectResponse>(
      `${this.apiUrl}/status`,
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(response => {
        console.log('Jira connection check response:', response);
        if (!response.isConnected) {
          console.warn('Jira connection check failed:', response.message);
        }
      }),
      catchError(error => {
        console.error('Jira connection check error:', error);
        return throwError(() => ({
          success: false,
          isConnected: false,
          error: error.error?.message || 'Failed to check Jira connection'
        }));
      })
    );
  }

  saveConnection(cloudId: string): Observable<JiraAuthResponse> {
    console.log('Saving Jira connection...', { cloudId });
    return this.http.post<JiraAuthResponse>(
      `${this.apiUrl}/save-credentials`,
      { cloudId },
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(response => {
        if (response.success) {
          localStorage.setItem('jira_connection', JSON.stringify({ cloudId }));
          console.log('Jira connection saved successfully');
        }
      }),
      catchError(this.handleRequestError)
    );
  }

  disconnect(): Observable<JiraAuthResponse> {
    console.log('Disconnecting from Jira...');
    return this.http.delete<JiraAuthResponse>(
      `${this.apiUrl}/connection`,
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(() => {
        localStorage.removeItem('jira_connection');
        localStorage.removeItem('jira_token_info');
        console.log('Jira connection removed');
      }),
      catchError(this.handleRequestError)
    );
  }

  // Board Management
  getBoards(): Observable<JiraBoardResponse> {
    return this.http.get<JiraBoardResponse>(
      `${this.apiUrl}/boards`,
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(response => {
        if (response.success) {
          console.log(`Retrieved ${response.boards.length} Jira boards`);
        } else {
          console.warn('Failed to retrieve Jira boards:', response.error);
        }
      }),
      catchError(error => {
        console.error('Error fetching Jira boards:', error);
        return of({
          success: false,
          boards: [],
          error: error.error?.error || 'Failed to fetch Jira boards'
        });
      })
    );
  }

  getBoardsByProject(projectKey: string): Observable<JiraBoardsByProjectResponse> {
    return this.http.get<JiraBoardsByProjectResponse>(
      `${this.apiUrl}/boards/${projectKey}`,
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(response => {
        if (response.success) {
          console.log(`Retrieved ${response.boards.length} boards for project ${projectKey}`);
        }
      }),
      catchError(error => {
        console.error('Error fetching project boards:', error);
        return of({
          success: false,
          boards: [],
          error: error.error?.error || 'Failed to fetch project boards'
        });
      })
    );
  }

  // Helper Methods
  private getAuthHeaders(): HttpHeaders {
    const token = localStorage.getItem('auth_token');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }

  private handleRequestError = (error: HttpErrorResponse): Observable<never> => {
    console.error('Jira API error:', error);

    if (error.status === 401) {
      this.handleUnauthorized();
    }

    const errorMessage = error.error?.message || 'An error occurred with the Jira integration';
    return throwError(() => new Error(errorMessage));
  }

  private handleUnauthorized(): void {
    console.log('Handling unauthorized error...');
    localStorage.removeItem('jira_connection');
    localStorage.removeItem('jira_token_info');
    this.router.navigate(['/login']);
  }

  // Public Utility Methods
  isConnected(): boolean {
    const connection = localStorage.getItem('jira_connection');
    return connection !== null;
  }

  clearJiraState(): void {
    localStorage.removeItem('jiraAuthState');
    localStorage.removeItem('jiraReturnUrl');
    localStorage.removeItem('jira_connection');
    localStorage.removeItem('jira_token_info');
  }

  connectJira(config: JiraAuthConfig): Observable<JiraConnectResponse> {
    return this.http.post<JiraConnectResponse>(
      `${this.apiUrl}/connect`,
      config,
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(response => {
        if (response.success) {
          localStorage.setItem('jira_connection', JSON.stringify(config));
        }
      }),
      catchError(this.handleRequestError)
    );
  }

  getProjects(): Observable<JiraProjectResponse> {
    return this.http.get<JiraProjectResponse>(
      `${this.apiUrl}/projects`,
      { headers: this.getAuthHeaders() }
    ).pipe(
      tap(response => {
        if (response.success) {
          console.log(`Retrieved ${response.projects.length} Jira projects`);
        } else {
          console.warn('Failed to retrieve Jira projects:', response.error);
        }
      }),
      catchError(error => {
        console.error('Error fetching Jira projects:', error);
        return of({
          success: false,
          projects: [],
          error: error.error?.error || 'Failed to fetch Jira projects'
        });
      })
    );
  }

  getBoardMetrics(boardId: string, projectKey: string): Observable<VelocityResponse> {
    return this.http.get<VelocityResponse>(
      `${this.apiUrl}/metrics/velocity/${boardId}`,
      {
        headers: this.getAuthHeaders(),
        params: { projectKey }
      }
    ).pipe(
      tap(response => {
        if (response.success) {
          console.log('Retrieved board metrics successfully');
        } else {
          console.warn('Failed to retrieve board metrics:', response.error);
        }
      }),
      catchError(error => {
        console.error('Error fetching board metrics:', error);
        return of({
          success: false,
          velocityData: [],
          teamMemberCount: 0,
          averagePlannedVsCompleted: 0,
          error: error.error?.error || 'Failed to fetch board metrics'
        });
      })
    );
  }
}