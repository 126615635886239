<!-- integrations.component.html -->

<main class="dashboard-main" role="main">
  <app-header></app-header>
  <div class="container" >
    <div class="dashboard-main-body">
      <div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
        <h6 class="fw-semibold mb-0">Integrations</h6>
        <div class="d-flex align-items-center gap-1">
          <a routerLink="/dashboard" class="hover-text-primary">Dashboard</a> - Integrations
        </div>
      </div>
  
      <div class="row gy-4">
        <div class="col-xxl-4 col-sm-6" *ngFor="let integration of integrations">
          <div class="card h-100 radius-12">
            <div class="card-body p-24">
              <!-- Integration Icon -->
              <div [class]="'w-64-px h-64-px d-inline-flex align-items-center justify-content-center ' + integration.gradientClass + ' ' + integration.colorClass + ' mb-16 radius-12'">
                <iconify-icon [icon]="integration.icon" width="32" height="32"></iconify-icon>
              </div>
  
              <!-- Integration Details -->
              <h6 class="mb-8">{{integration.name}}</h6>
              <p class="mb-16">{{integration.description}}</p>
  
              <!-- Error Message -->
              <div *ngIf="integration.error" class="alert alert-danger mb-3">
                {{integration.error}}
              </div>
  
              <!-- Connect/Disconnect Button -->
              <button 
                class="btn" 
                [class.btn-primary]="integration.status === 'disconnected'"
                [class.btn-outline-danger]="integration.status === 'connected'"
                [disabled]="integration.isLoading"
                (click)="handleButtonClick(integration)">
                {{getButtonText(integration)}}
              </button>
  
              <!-- Status Badge -->
              <div class="mt-2">
                <span class="badge" [ngClass]="{
                  'bg-success-focus text-success-main': integration.status === 'connected',
                  'bg-danger-focus text-danger-main': integration.status === 'disconnected'
                }">
                  {{integration.status | titlecase}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </main>

<!-- Authentication Modal -->
<div class="modal fade" id="jiraAuthModal" tabindex="-1" aria-labelledby="jiraAuthModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="jiraAuthModalLabel">Connect to Jira</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Basic Auth Form -->
        <form [formGroup]="basicAuthForm" (ngSubmit)="connectBasicAuth()">
          <div class="mb-3">
            <label class="form-label">Jira URL</label>
            <input type="url" class="form-control" formControlName="url" placeholder="https://your-domain.atlassian.net">
          </div>
          <div class="mb-3">
            <label class="form-label">Username</label>
            <input type="text" class="form-control" formControlName="username">
          </div>
          <div class="mb-3">
            <label class="form-label">Password/API Token</label>
            <input type="password" class="form-control" formControlName="password">
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="!basicAuthForm.valid || isLoading">
            Connect
          </button>
        </form>
      </div>
    </div>
  </div>
</div>