<div class="dashboard-main-body">
    <div class="card h-100 p-0 radius-12 overflow-hidden">
        <div class="card-body p-40">
            <div class="row justify-content-center">
                <div class="col-xxl-10">
                    <div class="text-center">   
                        <h4 class="mb-16">Simple, Transparent Pricing</h4>
                        <p class="mb-0 text-lg text-secondary-light">No surprise fees. No contracts.</p>
                    </div>
                    <ul class="nav nav-pills button-tab mt-32 pricing-tab justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link px-24 py-10 text-md rounded-pill text-secondary-light fw-medium active" id="pills-monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-monthly" type="button" role="tab" aria-controls="pills-monthly" aria-selected="true">
                            Monthly
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link px-24 py-10 text-md rounded-pill text-secondary-light fw-medium" id="pills-yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-yearly" type="button" role="tab" aria-controls="pills-yearly" aria-selected="false" tabindex="-1">
                            Yearly
                          </button>
                        </li>   
                    </ul>

                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab" tabindex="0">
                            <div class="row gy-4">
                                <div class="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                    <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-lilac-100">
                                        <div class="d-flex align-items-center gap-16">
                                            <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                <img src="assets/images/pricing/price-icon4.png" alt="">
                                            </span>
                                            <div class="">
                                                <span class="fw-medium text-md text-secondary-light">For individuals</span>
                                                <h6 class="mb-0">Free Trial</h6>
                                            </div>
                                        </div>
                                        <p class="mt-16 mb-0 text-secondary-light mb-28">Explore VDF AI's core features with a limited usage trial. Perfect for individuals and small teams.</p>
                                        <h3 class="mb-24 text-secondary-light">Free <span class="fw-medium text-md text-secondary-light">/ No Credit Card Required</span></h3>
                                        <span class="mb-20 fw-medium">What's included</span>
                                        <ul>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Team Enhancement Suggestion & AI Chat Box</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Scrum Metrics Evaluation</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Casual Loop Diagram Generation</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Different Perspective Option</span>
                                            </li>
                                        </ul>
                                        <button (click)="selectPlan('free')" type="button" class="bg-lilac-600 bg-hover-lilac-700 text-white text-center border border-lilac-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">Get started</button>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                    <div class="pricing-plan scale-item position-relative radius-24 overflow-hidden border bg-primary-600 text-white">
                                        <span class="bg-white bg-opacity-25 text-white radius-24 py-8 px-24 text-sm position-absolute end-0 top-0 z-1 rounded-start-top-0 rounded-end-bottom-0">Popular</span>
                                        <div class="d-flex align-items-center gap-16">
                                            <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                <img src="assets/images/pricing/price-icon2.png" alt="">
                                            </span>
                                            <div class="">
                                                <span class="fw-medium text-md text-white">For Individuals & Growing Teams</span>
                                                <h6 class="mb-0 text-white">Professional</h6>
                                            </div>
                                        </div>
                                        <p class="mt-16 mb-0 text-white mb-28">For growing teams and organizations ready for full integration with VDF AI. Includes advanced features and priority support.</p>
                                        <h3 class="mb-24 text-white">$29 <span class="fw-medium text-md text-white">/monthly</span></h3>
                                        <span class="mb-20 fw-medium">What's included</span>
                                        <ul>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">Everything in Free Trial</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">Limitless Usage</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">DevOps Metrics Evaluation</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">Priority Support</span>
                                            </li>
                                        </ul>
                                        <button (click)="selectPlan('professional')" type="button" class="bg-white text-primary-600 text-white text-center border border-white text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">Get started</button>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                    <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                                        <div class="d-flex align-items-center gap-16">
                                            <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                <img src="assets/images/pricing/price-icon5.png" alt="">
                                            </span>
                                            <div class="">
                                                <span class="fw-medium text-md text-secondary-light">For big companies</span>
                                                <h6 class="mb-0">Enterprise</h6>
                                            </div>
                                        </div>
                                        <p class="mt-16 mb-0 text-secondary-light mb-28">Ideal for organizations needing advanced features and integrations. Includes dedicated support and custom training.</p>
                                        <h3 class="mb-24">$99 <span class="fw-medium text-md text-secondary-light">/monthly</span></h3>
                                        <span class="mb-20 fw-medium">What's included</span>
                                        <ul>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Everything in Professional Plan</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Enterprise Integrations (Azure, Jira, Git)</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Assessment Document Analysis</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Dedicated Account Manager</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Custom Training and Onboarding</span>
                                            </li>
                                        </ul>
                                        <button (click)="selectPlan('enterprise')" type="button" class="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">Get started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-yearly" role="tabpanel" aria-labelledby="pills-yearly-tab" tabindex="0">
                            <div class="row gy-4">
                                <div class="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                    <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-lilac-100">
                                        <div class="d-flex align-items-center gap-16">
                                            <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                <img src="assets/images/pricing/price-icon4.png" alt="">
                                            </span>
                                            <div class="">
                                                <span class="fw-medium text-md text-secondary-light">For individuals</span>
                                                <h6 class="mb-0">Free Trial</h6>
                                            </div>
                                        </div>
                                        <p class="mt-16 mb-0 text-secondary-light mb-28">Explore VDF AI's core features with a limited usage trial. Perfect for individuals and small teams.</p>
                                        <h3 class="mb-24">$999 <span class="fw-medium text-md text-secondary-light">/yearly</span></h3>
                                        <span class="mb-20 fw-medium">What's included</span>
                                        <ul>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Team Enhancement Suggestion & AI Chat Box</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Scrum Metrics Evaluation</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Casual Loop Diagram Generation</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Different Perspective Option</span>
                                            </li>
                                        </ul>
                                        <button (click)="selectPlan('free')" type="button" class="bg-lilac-600 bg-hover-lilac-700 text-white text-center border border-lilac-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">Get started</button>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                    <div class="pricing-plan scale-item position-relative radius-24 overflow-hidden border bg-primary-600 text-white">
                                        <span class="bg-white bg-opacity-25 text-white radius-24 py-8 px-24 text-sm position-absolute end-0 top-0 z-1 rounded-start-top-0 rounded-end-bottom-0">Popular</span>
                                        <div class="d-flex align-items-center gap-16">
                                            <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                <img src="assets/images/pricing/price-icon2.png" alt="">
                                            </span>
                                            <div class="">
                                                <span class="fw-medium text-md text-white">For Individuals & Growing Teams</span>
                                                <h6 class="mb-0 text-white">Professional</h6>
                                            </div>
                                        </div>
                                        <p class="mt-16 mb-0 text-white mb-28">For growing teams and organizations ready for full integration with VDF AI. Includes advanced features and priority support.</p>
                                        <h3 class="mb-24 text-white">$290 <span class="fw-medium text-md text-white">/yearly</span></h3>
                                        <span class="mb-20 fw-medium">What's included</span>
                                        <ul>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">Everything in Free Trial</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">Limitless Usage</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">DevOps Metrics Evaluation</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><iconify-icon icon="iconamoon:check-light" class="text-lg"></iconify-icon></span>
                                                <span class="text-white text-lg">Priority Support</span>
                                            </li>
                                        </ul>
                                        <button (click)="selectPlan('professional')" type="button" class="bg-white text-primary-600 text-white text-center border border-white text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">Get started</button>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                    <div class="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                                        <div class="d-flex align-items-center gap-16">
                                            <span class="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                <img src="assets/images/pricing/price-icon5.png" alt="">
                                            </span>
                                            <div class="">
                                                <span class="fw-medium text-md text-secondary-light">For big companies</span>
                                                <h6 class="mb-0">Enterprise</h6>
                                            </div>
                                        </div>
                                        <p class="mt-16 mb-0 text-secondary-light mb-28">Ideal for organizations needing advanced features and integrations. Includes dedicated support and custom training.</p>
                                        <h3 class="mb-24">$999 <span class="fw-medium text-md text-secondary-light">/yearly</span></h3>
                                        <span class="mb-20 fw-medium">What's included</span>
                                        <ul>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Everything in Professional Plan</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Enterprise Integrations (Azure, Jira, Git)</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Assessment Document Analysis</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16 mb-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Dedicated Account Manager</span>
                                            </li>
                                            <li class="d-flex align-items-center gap-16">
                                                <span class="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><iconify-icon icon="iconamoon:check-light" class="text-white text-lg "></iconify-icon></span>
                                                <span class="text-secondary-light text-lg">Custom Training and Onboarding</span>
                                            </li>
                                        </ul>
                                        <button (click)="selectPlan('enterprise')" type="button" class="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28">Get started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>