// src/app/registration/components/package-selection/package-selection.component.ts

import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserType } from '../../../interfaces/user.interface';
import { RegistrationStateService } from '../../services/registration-state.service';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-package-selection',
  templateUrl: './package-selection.component.html',
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PackageSelectionComponent {
    selectedBillingCycle: 'monthly' | 'yearly' = 'monthly';

    constructor(
      private router: Router,
      private registrationState: RegistrationStateService
    ) {
      console.log('PackageSelectionComponent: Initialized');
    }
  
    selectPlan(plan: UserType): void {
      // Get the active tab to determine billing cycle
      const yearlyTab = document.getElementById('pills-yearly-tab');
      this.selectedBillingCycle = yearlyTab?.classList.contains('active') ? 'yearly' : 'monthly';

      console.log('PackageSelectionComponent: Selected plan:', plan);
      console.log('PackageSelectionComponent: Selected billing cycle:', this.selectedBillingCycle);
      
      // Store in session storage immediately
      sessionStorage.setItem('selectedBillingCycle', this.selectedBillingCycle);
      
      this.registrationState.updateState({
        plan: plan,
        billingCycle: this.selectedBillingCycle,
        step: 'details'
      });
      
      const queryParams = {
        plan: plan,
        billingCycle: this.selectedBillingCycle
      };

      console.log('PackageSelectionComponent: Navigation query params:', queryParams);
      
      this.router.navigate(['/public/register/details'], { 
        queryParams,
        queryParamsHandling: 'merge' // Preserve other query params
      });
    }

    // This method will be called when tabs change
    updateBillingCycle(cycle: 'monthly' | 'yearly'): void {
      this.selectedBillingCycle = cycle;
      console.log('PackageSelectionComponent: Billing cycle updated to:', cycle);
    }
}