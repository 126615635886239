<app-sidebar></app-sidebar>    
<div class="dashboard-main">
    <app-header></app-header>
    <div class="container mt-50 mb-50">
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Privacy Policy</h5>
          <p class="f-m-light mt-1">Updated at 2024-09-01</p>
        </div>
        <div class="card-body">
          <p><strong>Introduction: </strong>VDF AI (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by VDF AI.</p>
          <p>This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application, VDF AI. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</p>
          
          <h6>Compliance with EU General Data Protection Regulation (GDPR)</h6>
          <p>VDF AI is fully committed to complying with the <strong>EU General Data Protection Regulation (GDPR)</strong>, which provides protection and rights to individuals with respect to their personal data. GDPR applies to VDF AI users and customers located within the European Economic Area (EEA). Below, we outline how VDF AI adheres to GDPR principles and protects the personal data of our users.</p>
          
          <h6>Your Rights Under GDPR</h6>
          <p>If you are a resident of the European Union (EU), you have certain data protection rights, including the right to:</p>
          <ul>
            <li><strong>Access</strong> your personal data that we hold</li>
            <li><strong>Rectify</strong> any inaccurate or incomplete personal data</li>
            <li><strong>Erasure</strong> (right to be forgotten) if the data is no longer necessary for the purpose for which it was collected, or you withdraw consent</li>
            <li><strong>Restrict</strong> or object to the processing of your data</li>
            <li><strong>Data portability</strong>, allowing you to receive your personal data in a structured, commonly used, and machine-readable format, and to transfer that data to another controller</li>
            <li><strong>Withdraw consent</strong> at any time where we rely on your consent to process your personal data</li>
            <li><strong>Lodge a complaint</strong> with a supervisory authority in the EU if you believe your rights are being violated</li>
          </ul>
          
          <h6>Definitions and Key Terms</h6>
          <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, they are strictly defined as:</p>
          <ul>
            <li><strong>Cookie</strong>: A small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, and remember information such as your language preference or login information.</li>
            <li><strong>Company</strong>: When this policy mentions “Company,” “we,” “us,” or “our,” it refers to VDF AI AB, responsible for your information under this Privacy Policy.</li>
            <li><strong>Country</strong>: Refers to the country where VDF AI is based, in this case, Sweden.</li>
            <li><strong>Customer</strong>: Refers to the company, organization, or person that signs up to use the VDF AI Service to manage relationships with consumers or service users.</li>
            <li><strong>Device</strong>: Any internet-connected device such as a phone, tablet, computer, or any other device that can be used to visit VDF AI and use the services.</li>
            <li><strong>IP Address</strong>: Every device connected to the Internet is assigned a number known as an Internet Protocol (IP) address, which can be used to identify the location of the device connecting to the Internet.</li>
            <li><strong>Personnel</strong>: Refers to individuals employed by VDF AI or under contract to perform a service on behalf of one of the parties.</li>
            <li><strong>Personal Data</strong>: Any information that directly, indirectly, or in connection with other information allows for the identification of a natural person.</li>
            <li><strong>Service</strong>: Refers to the service provided by VDF AI as described in the relative terms on this platform.</li>
            <li><strong>Third-Party Service</strong>: Refers to advertisers, promotional partners, and others who provide content or whose products or services may interest you.</li>
            <li><strong>Website</strong>: VDF AI's website, which can be accessed via this URL: <a href="https://vdfai.com">vdfai.com</a></li>
            <li><strong>You</strong>: A person or entity that is registered with VDF AI to use the Services.</li>
          </ul>
          
          <h6>What Information Do We Collect?</h6>
          <p>We collect information from you when you visit our website/app, register on our site, subscribe to our newsletter, respond to a survey, or fill out a form. The information collected includes:</p>
          <ul>
            <li>Name / Username</li>
            <li>Phone Numbers</li>
            <li>Email Addresses</li>
            <li>Mailing Addresses</li>
            <li>Job Titles</li>
            <li>Billing Addresses</li>
            <li>Debit/Credit Card Numbers</li>
            <li>Age</li>
            <li>Password</li>
          </ul>
          
          <h6>How Do We Use the Information We Collect?</h6>
          <p>The information we collect may be used in the following ways:</p>
          <ul>
            <li>To personalize your experience</li>
            <li>To improve our website/app</li>
            <li>To improve customer service</li>
            <li>To process transactions</li>
            <li>To administer promotions, surveys, or other features</li>
            <li>To send periodic emails</li>
          </ul>
          
          <h6>Do We Share the Information We Collect with Third Parties?</h6>
          <p>We may share the information we collect with third parties such as advertisers, marketing partners, and business affiliates. We may also share data with trusted third-party service providers to perform functions such as hosting, payment processing, and customer service.</p>
          <p>We may disclose personal and non-personal information to law enforcement or as required by law to comply with legal processes or to protect our rights, property, or safety.</p>
          
          <h6>How Do We Use Your Email Address?</h6>
          <p>By submitting your email address on this website/app, you agree to receive emails from us. You may unsubscribe from these emails at any time by clicking the opt-out link in each email.</p>
          
          <h6>How Long Do We Keep Your Information?</h6>
          <p>We retain your information as long as it is necessary to provide our services or as required by law. Once we no longer need the information, we will securely delete or anonymize it.</p>
          
          <h6>How Do We Protect Your Information?</h6>
          <p>We implement security measures such as SSL encryption and secure storage to protect your personal data. However, no method of transmission or storage is 100% secure.</p>
          
          <h6>Transfer of Data to Other Countries</h6>
          <p>If you are located outside of the European Economic Area (EEA), please be aware that your information may be transferred to, processed, and stored in a country different from your own. We ensure that appropriate safeguards are in place to protect your data in accordance with the applicable data protection laws, including <strong>GDPR</strong>.</p>
          
          <h6>Children’s Privacy</h6>
          <p>We do not knowingly collect personal information from anyone under the age of 13. If you believe your child has provided us with personal data, please contact us to have it removed.</p>
          
          <h6>Changes to Our Privacy Policy</h6>
          <p>We may update this Privacy Policy periodically. If any significant changes are made, we will notify you via email or a prominent notice on our website.</p>
          
          <h6>Contact Us</h6>
          <p>For any questions regarding this Privacy Policy, or if you wish to exercise your rights under <strong>GDPR</strong>, you may contact us</p>
          <ul>
            <li><strong>Via Email: </strong> info&#64;vdf.ai</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<app-footer></app-footer> 
</div>
