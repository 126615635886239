<body>

  <section class="auth bg-base d-flex flex-wrap">  
      <div class="auth-left d-lg-block d-none">
          <div class="d-flex align-items-center flex-column h-100 justify-content-center">
              <img src="https://imagedelivery.net/soJx4BiMTBvAFlSCQpzu3A/560d5b09-635d-41e6-ad5f-ec46f8f7c300/public" alt="">
          </div>
      </div>
      <div class="auth-right py-32 px-24 d-flex flex-column justify-content-center">
          <div class="max-w-464-px mx-auto w-100">
              <div>
                  <a href="index.html" class="mb-40 max-w-290-px">
                      <img src="/assets/images/scrum/theme/logo/light_168x44.png" alt="">
                  </a>
                  <h4 class="mb-12">{{ !showVerificationInput ? 'Sign Up to your Account' : 'Verify Your Email' }}</h4>
                  <p class="mb-32 text-secondary-light text-lg">
                      {{ !showVerificationInput ? 'Welcome back! please enter your detail' : 'Please enter the verification code sent to your email' }}
                  </p>
              </div>
              <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()" *ngIf="!showVerificationInput">
                 <!-- Name field -->
                 <div class="row">
                   <div class="col-sm-6">
                     <div class="mb-20">
                       <label for="name" class="form-label fw-semibold text-primary-light text-sm mb-8">
                         Name <span class="text-danger-600">*</span>
                       </label>
                       <input type="text" class="form-control radius-8" id="name" 
                              formControlName="name" placeholder="Enter Name">
                       <div *ngIf="isFieldInvalid('name')" class="text-danger">
                         {{ getErrorMessage('name') }}
                       </div>
                     </div>
                   </div>
                   <div class="col-sm-6">
                     <div class="mb-20">
                       <label for="surname" class="form-label fw-semibold text-primary-light text-sm mb-8">
                         Surname <span class="text-danger-600">*</span>
                       </label>
                       <input type="text" class="form-control radius-8" id="surname" 
                              formControlName="surname" placeholder="Enter Surname">
                       <div *ngIf="isFieldInvalid('surname')" class="text-danger">
                         {{ getErrorMessage('surname') }}
                       </div>
                     </div>
                   </div>
                 </div>

                    <!-- Email field -->
                    <div class="icon-field mb-16">
                        <span class="icon top-50 translate-middle-y">
                            <iconify-icon icon="mage:email"></iconify-icon>
                        </span>
                        <input type="email" 
                            formControlName="email"
                            class="form-control h-56-px bg-neutral-50 radius-12" 
                            [class.is-invalid]="isFieldInvalid('email')"
                            placeholder="Email">
                        <div class="invalid-feedback" *ngIf="isFieldInvalid('email')">
                            {{ getErrorMessage('email') }}
                        </div>
                    </div>

                    <!-- Password field -->
                    <div class="mb-20">
                        <div class="position-relative">
                            <div class="icon-field">
                                <span class="icon top-50 translate-middle-y">
                                    <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
                                </span>
                                <input [type]="showPassword ? 'text' : 'password'"
                                    formControlName="password"
                                    class="form-control h-56-px bg-neutral-50 radius-12" 
                                    [class.is-invalid]="isFieldInvalid('password')"
                                    placeholder="Password">
                                <span class="toggle-password cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light" 
                                    [class]="showPassword ? 'ri-eye-off-line' : 'ri-eye-line'"
                                    (click)="togglePassword()">
                                </span>
                            </div>
                            <div class="invalid-feedback" *ngIf="isFieldInvalid('password')">
                                {{ getErrorMessage('password') }}
                            </div>
                        </div>
                        <span class="mt-12 text-sm text-secondary-light">Your password must have at least 8 characters</span>
                    </div>

                    <!-- Confirm Password field -->
                    <div class="mb-20">
                      <label for="confirmPassword" class="form-label fw-semibold text-primary-light text-sm mb-8">
                        Confirm Password <span class="text-danger-600">*</span>
                      </label>
                      <div class="position-relative">
                        <input [type]="showConfirmPassword ? 'text' : 'password'" 
                               class="form-control radius-8" 
                               id="confirmPassword" 
                               formControlName="confirmPassword" 
                               placeholder="Confirm your password">
                        <button type="button" 
                                class="btn btn-link position-absolute top-50 end-0 translate-middle-y text-gray-600" 
                                (click)="toggleConfirmPassword()">
                          <iconify-icon [icon]="showConfirmPassword ? 'solar:eye-closed-linear' : 'solar:eye-linear'" 
                                        class="icon"></iconify-icon>
                        </button>
                      </div>
                      <div *ngIf="isFieldInvalid('confirmPassword')" class="text-danger">
                        {{ getErrorMessage('confirmPassword') }}
                      </div>
                    </div>

                    <!-- Terms checkbox -->
                    <div class="form-check style-check d-flex align-items-start">
                        <input class="form-check-input border border-neutral-300 mt-4" 
                            type="checkbox" 
                            formControlName="acceptTerms"
                            id="condition">
                        <label class="form-check-label text-sm" for="condition">
                            By creating an account means you agree to the 
                            <a href="javascript:void(0)" class="text-primary-600 fw-semibold">Terms & Conditions</a> and our 
                            <a href="javascript:void(0)" class="text-primary-600 fw-semibold">Privacy Policy</a>
                        </label>
                    </div>
                    <div class="invalid-feedback" *ngIf="isFieldInvalid('acceptTerms')">
                        {{ getErrorMessage('acceptTerms') }}
                    </div>
            
                <!-- Error Message -->
                <div class="alert alert-danger mt-3" *ngIf="errorMessage">
                    {{ errorMessage }}
                </div>
            
                <!-- Submit Button -->
                <button type="submit" 
                    class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
                      [disabled]="!registrationForm.valid || isSubmitting">
                    {{ isSubmitting ? 'Processing...' : 'Sign Up' }}
                </button>
            
                <!-- Social Sign Up -->
                <div class="mt-32 center-border-horizontal text-center">
                    <span class="bg-base z-1 px-4">Or sign up with</span>
                </div>
            
                <div class="mt-32 d-flex align-items-center gap-3">
                    <button type="button" 
                            (click)="signInWithFacebook()"
                            class="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50">
                        <iconify-icon icon="ic:baseline-facebook" class="text-primary-600 text-xl line-height-1"></iconify-icon>
                        Facebook
                    </button>
                    <button type="button"
                            (click)="signInWithGoogle()" 
                            class="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50">
                        <iconify-icon icon="logos:google-icon" class="text-primary-600 text-xl line-height-1"></iconify-icon>
                        Google
                    </button>
                </div>
            
                <!-- Sign In Link -->
                <div class="mt-32 text-center text-sm">
                    <p class="mb-0">Already have an account? <a routerLink="/public/login" class="text-primary-600 fw-semibold">Sign In</a></p>
                </div>
            </form>

            <!-- Verification Form -->
            <form [formGroup]="verificationForm" (ngSubmit)="verifyCode()" *ngIf="showVerificationInput">
                <div class="icon-field mb-16">
                    <span class="icon top-50 translate-middle-y">
                        <iconify-icon icon="mdi:password-outline"></iconify-icon>
                    </span>
                    <input type="text" 
                        formControlName="verificationCode"
                        class="form-control h-56-px bg-neutral-50 radius-12 text-center letter-spacing-2" 
                        [class.is-invalid]="verificationForm.get('verificationCode')?.invalid && verificationForm.get('verificationCode')?.touched"
                        placeholder="Enter 6-digit code"
                        maxlength="6">
                    <div class="invalid-feedback" *ngIf="verificationForm.get('verificationCode')?.invalid && verificationForm.get('verificationCode')?.touched">
                        Please enter a valid verification code
                    </div>
                </div>

                <!-- Timer -->
                <div class="text-center text-sm text-secondary-light mb-32" *ngIf="verificationState.remainingTime > 0">
                    Code expires in: {{ verificationState.remainingTime }} seconds
                </div>

                <!-- Error Message -->
                <div class="alert alert-danger mt-3" *ngIf="errorMessage">
                    {{ errorMessage }}
                </div>

                <!-- Verify Button -->
                <button type="submit" 
                    class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
                    [disabled]="!verificationForm.valid || isSubmitting">
                    {{ isSubmitting ? 'Verifying...' : 'Verify Code' }}
                </button>

                <!-- Resend Code -->
                <div class="mt-32 text-center">
                    <button type="button" 
                        class="btn btn-link text-primary-600 fw-semibold"
                        (click)="resendVerificationCode()"
                        [disabled]="!verificationState.canResend || isSubmitting">
                        Resend verification code
                    </button>
                </div>
            </form>
          </div>
      </div>
  </section>
  
    <!-- jQuery library js -->
    <script src="assets/js/lib/jquery-3.7.1.min.js"></script>
    <!-- Bootstrap js -->
    <script src="assets/js/lib/bootstrap.bundle.min.js"></script>
    <!-- Apex Chart js -->
    <!-- <script src="assets/js/lib/apexcharts.min.js"></script> -->
    <script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
    <!-- Data Table js -->
    <script src="assets/js/lib/dataTables.min.js"></script>
    <!-- Iconify Font js -->
    <script src="assets/js/lib/iconify-icon.min.js"></script>
    <!-- jQuery UI js -->
    <script src="assets/js/lib/jquery-ui.min.js"></script>
    <!-- Vector Map js -->
    <script src="assets/js/lib/jquery-jvectormap-2.0.5.min.js"></script>
    <script src="assets/js/lib/jquery-jvectormap-world-mill-en.js"></script>
    <!-- Popup js -->
    <script src="assets/js/lib/magnifc-popup.min.js"></script>
    <!-- Slick Slider js -->
    <script src="assets/js/lib/slick.min.js"></script>
    <!-- prism js -->
    <script src="assets/js/lib/prism.js"></script>
    <!-- file upload js -->
    <script src="assets/js/lib/file-upload.js"></script>
    <!-- audioplayer -->
    <script src="assets/js/lib/audioplayer.js"></script>
    
    <!-- main js -->
    <script src="assets/js/app.js"></script>
  
  <script>
        // ================== Password Show Hide Js Start ==========
        function initializePasswordToggle(toggleSelector) {
          $(toggleSelector).on('click', function() {
              $(this).toggleClass("ri-eye-off-line");
              var input = $($(this).attr("data-toggle"));
              if (input.attr("type") === "password") {
                  input.attr("type", "text");
              } else {
                  input.attr("type", "password");
              }
          });
      }
      // Call the function
      initializePasswordToggle('.toggle-password');
    // ========================= Password Show Hide Js End ===========================
  </script>
  
  
  
  </body>