// src/app/auth/login/login.component.ts
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SocialAuthService } from '../../services/social-auth.service';
import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';  // Add CUSTOM_ELEMENTS_SCHEMA import


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <section class="auth bg-base d-flex flex-wrap">  
      <div class="auth-left d-lg-block d-none">
        <div class="d-flex align-items-center flex-column h-100 justify-content-center">
          <img src="https://imagedelivery.net/soJx4BiMTBvAFlSCQpzu3A/b2ee7753-3ba0-4fae-8642-a614920ccc00/public" alt="">
        </div>
      </div>
      <div class="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div class="max-w-464-px mx-auto w-100">
          <div>
            <a routerLink="/" class="mb-40 max-w-290-px">
              <img src="/assets/images/scrum/theme/logo/light_168x44.png" alt="">
            </a>
            <h4 class="mb-12">Sign In to your Account</h4>
            <p class="mb-32 text-secondary-light text-lg">Welcome back! please enter your detail</p>
          </div>
          <form (ngSubmit)="onSubmit()">
            <div class="icon-field mb-16">
              <span class="icon top-50 translate-middle-y">
                <iconify-icon icon="mage:email"></iconify-icon>
              </span>
              <input 
                type="email" 
                class="form-control h-56-px bg-neutral-50 radius-12" 
                placeholder="Email"
                [(ngModel)]="email"
                name="email">
            </div>
            <div class="position-relative mb-20">
              <div class="icon-field">
                <span class="icon top-50 translate-middle-y">
                  <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
                </span> 
                <input 
                  [type]="showPassword ? 'text' : 'password'" 
                  class="form-control h-56-px bg-neutral-50 radius-12" 
                  id="your-password" 
                  placeholder="Password"
                  [(ngModel)]="password"
                  name="password">
              </div>
              <span 
                class="toggle-password cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light" 
                [class]="showPassword ? 'ri-eye-off-line' : 'ri-eye-line'"
                (click)="togglePassword()">
              </span>
            </div>
            <div class="">
              <div class="d-flex justify-content-between gap-2">
                <div class="form-check style-check d-flex align-items-center">
                  <input 
                    class="form-check-input border border-neutral-300" 
                    type="checkbox" 
                    id="remember"
                    [(ngModel)]="rememberMe"
                    name="rememberMe">
                  <label class="form-check-label" for="remember">Remember me</label>
                </div>
                <a href="/auth/forgot-password" class="text-primary-600 fw-medium">Forgot Password?</a>
              </div>
            </div>

            <button 
              type="submit" 
              class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
              [disabled]="isLoading"> 
              {{ isLoading ? 'Signing in...' : 'Sign In' }}
            </button>

            <!-- Error message -->
            <div *ngIf="error" class="alert alert-danger mt-3">
              {{ error }}
            </div>

            <div class="mt-32 center-border-horizontal text-center">
              <span class="bg-base z-1 px-4">Or sign in with</span>
            </div>

            <div class="mt-32 d-flex align-items-center gap-3">
              <button 
                type="button" 
                class="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
                (click)="onFacebookLogin()"
              > 
                <iconify-icon 
                  icon="ic:baseline-facebook" 
                  class="text-primary-600 text-xl line-height-1"
                ></iconify-icon>
                Facebook
              </button>
              <button 
                type="button" 
                class="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
                (click)="onGoogleLogin()"
              > 
                <iconify-icon 
                  icon="logos:google-icon" 
                  class="text-primary-600 text-xl line-height-1"
                ></iconify-icon>
                Google
              </button>
            </div>

            <div class="mt-32 text-center text-sm">
              <p class="mb-0">
                Don't have an account? 
                <a href="/public/register" class="text-primary-600 fw-medium">Sign Up</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  `
})
export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';
  rememberMe: boolean = false;
  showPassword: boolean = false;
  isLoading: boolean = false;
  error: string = '';

  constructor(
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Check if already logged in
    if (this.authService.userValue) {
      this.router.navigate(['/app/dashboard']);
    }
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  onSubmit(): void {
    if (!this.email || !this.password) {
      this.error = 'Please enter both email and password';
      return;
    }

    this.isLoading = true;
    this.error = '';

    this.authService.login(this.email, this.password)
      .subscribe({
        next: () => {
          this.router.navigate(['/app/dashboard']);
        },
        error: (error) => {
          this.error = error?.error?.message || 'Login failed. Please try again.';
          this.isLoading = false;
        }
      });
  }

  onGoogleLogin(): void {
    this.socialAuthService.initiateGoogleLogin();
  }

  onFacebookLogin(): void {
    this.socialAuthService.initiateFacebookLogin();
  }
}