<section class="auth bg-base d-flex flex-wrap">
    <div class="auth-right py-32 px-24 d-flex flex-column justify-content-center">
      <div class="max-w-464-px mx-auto w-100">
        <div class="mb-32">
          <a routerLink="/" class="mb-40 max-w-290-px d-block">
            <img src="assets/images/logo.png" alt="VDF.AI">
          </a>
          <h4 class="mb-12">Verify Your Email</h4>
          <p class="mb-32 text-secondary-light text-lg">
            We've sent a verification code to {{userEmail}}
          </p>
        </div>

        <form [formGroup]="verificationForm" (ngSubmit)="verifyCode()">
          <div class="form-group">
            <input 
              type="text" 
              class="form-control"
              formControlName="code"
              placeholder="Enter verification code"
              [maxLength]="6"
              [class.is-invalid]="isFieldInvalid('code')">
            <div class="invalid-feedback" *ngIf="isFieldInvalid('code')">
              Please enter a valid 6-digit code
            </div>
          </div>

          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <button 
            type="submit" 
            class="btn btn-primary w-100"
            [disabled]="isSubmitting || !verificationForm.valid">
            {{ isSubmitting ? 'Verifying...' : 'Verify' }}
          </button>

          <div *ngIf="isSubmitting" style="color: red; margin-top: 10px;">
            Debug: Submission in progress
          </div>

          <div class="resend-section">
            <a 
              href="javascript:void(0)" 
              (click)="resendCode()" 
              [class.disabled]="resendDisabled"
              class="resend-link">
              {{ resendDisabled ? 'Resend code in ' + resendTimer + 's' : 'Resend verification code' }}
            </a>
          </div>

          <div class="mt-4 text-center">
            <button 
              type="button"
              (click)="goBack()" 
              class="btn btn-link text-primary">
              ← Back to User Details
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>