// src/app/app.routes.ts
import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScrumTeamAgentComponent } from './scrum-team-agent/scrum-team-agent.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { JiraCallbackComponent } from './integrations/jira-callback.component';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';
import { AuthGuard } from './guards/auth.guard';
import { PublicAppComponent } from './public-app.component';
import { AuthenticatedAppComponent } from './authenticated-app/authenticated-app.component';
import { LoginComponent } from './auth/login/login.component';
import { PackageSelectionComponent } from './registration/components/package-selection/package-selection.component';
import { UserDetailsComponent } from './registration/components/user-details/user-details.component';
import { VerificationComponent } from './registration/components/verification/verification.component';
import { CompanyDetailsComponent } from './shared/company-details/company-details.component';
import { PaymentComponent } from './shared/payment/payment.component';
import { RegistrationGuard } from './registration/guards/registration.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PaymentSuccessComponent } from './shared/payment/payment-success/payment-success.component';
import { PaymentRegistrationComponent } from './registration/components/payment-registration/payment-registration.component';
import { CompanyDetailsRegistrationComponent } from './registration/components/company-details-registration/company-details-registration.component';
import { RegistrationSuccessComponent } from './registration/components/registration-success/registration-success.component';
import { FlowGuard } from './registration/guards/flow.guard';
import { PasswordSetupComponent } from './registration/components/password-setup/password-setup.component';
import { SocialAuthCallbackComponent } from './auth/social-auth-callback/social-auth-callback.component'; 
import { ProfileComponent } from './profile/profile.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { VdfHelpComponent } from './vdf-help/vdf-help.component';
import { FaqComponent } from './faq/faq.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'app/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'public',
    component: PublicAppComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { 
        path: 'auth/google/callback',
        component: SocialAuthCallbackComponent,
        data: { provider: 'google' }
      },
      { 
        path: 'auth/facebook/callback',
        component: SocialAuthCallbackComponent,
        data: { provider: 'facebook' }
      },
      {
        path: 'register',
        children: [
          { 
            path: '', 
            redirectTo: 'package-selection', 
            pathMatch: 'full' 
          },
          {
            path: 'package-selection',
            component: PackageSelectionComponent
          },
          { 
            path: 'details', 
            component: UserDetailsComponent,
            data: { step: 'details' }
          },
          { 
            path: 'verify', 
            component: VerificationComponent,
            data: { step: 'verification' }
          },
          {
            path: 'company-details',
            component: CompanyDetailsRegistrationComponent,
            data: { step: 'company-details' }
          },
          {
            path: 'payment',
            component: PaymentRegistrationComponent,
            data: { step: 'payment' }
          },
          { 
            path: 'success', 
            component: RegistrationSuccessComponent,
            canActivate: [RegistrationGuard],
            data: { step: 'success' }
          },
          { 
            path: 'activate/:token',
            component: PasswordSetupComponent,
            data: { redirectTo: '/public/login' }
          }
        ]
      }
    ]
  },
  {
    path: 'app',
    component: AuthenticatedAppComponent,
    canActivate: [AuthGuard],
    children: [
      { 
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      { 
        path: 'dashboard', 
        component: DashboardComponent 
      },
      { 
        path: 'scrum-team-agent', 
        component: ScrumTeamAgentComponent 
      },
      { 
        path: 'integrations', 
        component: IntegrationsComponent 
      },
      { 
        path: 'integrations/jira/callback', 
        component: JiraCallbackComponent 
      },
      { 
        path: 'profile',
        component: ProfileComponent
      },
      { 
        path: 'upgrade-plan', 
        component: UpgradePlanComponent 
      },
      { 
        path: 'payment', 
        children: [
          {
            path: '',
            component: PaymentComponent
          },
          {
            path: 'success',
            component: PaymentSuccessComponent
          }
        ]
      },
      { 
        path: 'company-details', 
        component: CompanyDetailsComponent 
      },
      { 
        path: 'company-profile', 
        component: CompanyProfileComponent 
      },
      { 
        path: 'privacy-policy', 
        component: PrivacyPolicyComponent 
      },
      { 
        path: 'terms-conditions', 
        component: TermsConditionsComponent 
      },
      { 
        path: 'vdf-help', 
        component: VdfHelpComponent 
      },
      { 
        path: 'faq', 
        component: FaqComponent 
      }
    ]
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'auth/reset-password/:token',
    component: ResetPasswordComponent
  },
  { 
    path: '**', 
    redirectTo: 'public/' 
  }
];