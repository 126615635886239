// src/app/registration/services/registration-state.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type RegistrationStep = 
  | 'initial' 
  | 'details' 
  | 'verification' 
  | 'verified'
  | 'company-details'
  | 'payment'
  | 'complete';

export type UserType = 'free' | 'professional' | 'enterprise'; // Define possible user types

export interface CompanyDetails {
  name: string;
  size: string;
  userEmails: string[];
}

export interface RegistrationState {
  step: RegistrationStep;
  plan: UserType;
  billingCycle: 'monthly' | 'yearly';
  userDetails?: any;
  companyDetails?: any;
  isVerified: boolean;
  paymentCompleted?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationStateService {
  private initialState: RegistrationState = {
    step: 'initial',
    plan: 'free',
    isVerified: false,
    userDetails: undefined,
    companyDetails: undefined,
    paymentCompleted: false,
    billingCycle: 'monthly'
  };

  private stateSubject = new BehaviorSubject<RegistrationState>(this.initialState);

  // Observable for components to subscribe to
  state$ = this.stateSubject.asObservable();

  getCurrentState(): RegistrationState {
    return this.stateSubject.getValue();
  }

  updateState(newState: Partial<RegistrationState>): void {
    const updatedState = { ...this.stateSubject.getValue(), ...newState };
    this.stateSubject.next(updatedState);
    console.log('RegistrationStateService: State updated', updatedState);
  }

  setPlan(plan: UserType): void {
    this.updateState({ plan });
    console.log('RegistrationStateService: Plan set to', plan);
  }

  setCompanyDetails(companyDetails: CompanyDetails): void {
    this.updateState({ companyDetails });
    console.log('RegistrationStateService: Company details set', companyDetails);
  }

  completeVerification(): void {
    this.updateState({
      isVerified: true,
      step: 'complete' // or the next appropriate step
    });
    console.log('RegistrationStateService: Verification completed');
  }

  getNextStep(): string {
    const state = this.getCurrentState();
    
    if (!state.userDetails) return 'details';
    if (!state.isVerified) return 'verify';
    
    switch (state.plan) {
      case 'free':
        return 'success';
      case 'professional':
        return state.paymentCompleted ? 'success' : 'payment';
      case 'enterprise':
        if (!state.companyDetails) return 'company-details';
        if (!state.paymentCompleted) return 'payment';
        return 'success';
      default:
        return 'details';
    }
  }

  resetState(): void {
    this.stateSubject.next(this.initialState);
    console.log('RegistrationState: State reset to initial state');
  }
}