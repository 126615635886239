// src/app/services/chat-history.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

export interface ChatSession {
  id: number;
  agent_type: string;
  created_at: string;
  last_message?: string;
  user_id?: string;
  title: string;
}

export interface ChatSessionResponse {
  success: boolean;
  sessions: ChatSession[];
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatHistoryService {
  private apiUrl = `${environment.apiUrl}`;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getChatSessions(): Observable<ChatSession[]> {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.authService.token}`);

    return this.http.get<ChatSessionResponse>(
      `${this.apiUrl}/chat-sessions`,
      { headers }
    ).pipe(
      map(response => {
        if (response.success) {
          return response.sessions;
        }
        throw new Error(response.error || 'Failed to fetch chat sessions');
      }),
      catchError(error => {
        console.error('Chat history error:', error);
        return throwError(() => new Error('Failed to load chat history'));
      })
    );
  }
}