<!-- shared/velocity-tags/velocity-tags.component.html -->
<div class="velocity-tags-container">
    <label class="form-label">Sprint Velocity (Last 6 Sprints)</label>
    
    <div class="tags-input-container form-control">
      <ng-container *ngFor="let velocity of velocities; let i = index">
        <div class="velocity-tag">
          {{ velocity }}
          <button type="button" class="remove-tag" (click)="removeVelocity(i)">×</button>
        </div>
      </ng-container>
      
      <input *ngIf="velocities.length < 6"
             type="number"
             [(ngModel)]="inputValue"
             (keydown)="onKeyDown($event)"
             (blur)="addVelocity()"
             placeholder="Enter velocity"
             class="tag-input">
    </div>
    
    <small class="text-secondary-light mt-1">
      {{velocities.length}}/6 sprints added
    </small>
  </div>