<!-- Company Information Form -->
<div class="dashboard-main">
    <app-header></app-header>
    <div class="dashboard-main-body">
      <div class="container mt-50 mb-50">
        <div class="card h-100 p-0 radius-12 overflow-hidden" *ngIf="showCompanyForm">
          <div class="card-header">
            <h5 class="card-title mb-0">Company Information</h5>
          
          <div class="card-body p-40">
            <form [formGroup]="companyForm">
              <div class="row">
          <!-- Company Name -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="company_name" class="form-label fw-semibold text-primary-light text-sm mb-8">Company Name <span class="text-danger-600">*</span></label>
              <input type="text" class="form-control radius-8" id="company_name" formControlName="company_name" placeholder="Enter Company Name">
              <div *ngIf="companyForm.get('company_name')!.touched && companyForm.get('name')!.invalid" class="text-danger">
                Company Name is required.
              </div>
            </div>
          </div>
          <!-- Email -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="email" class="form-label fw-semibold text-primary-light text-sm mb-8">Email <span class="text-danger-600">*</span></label>
              <input type="email" class="form-control radius-8" id="email" formControlName="email" placeholder="Enter email address">
              <div *ngIf="companyForm.get('email')!.touched && companyForm.get('email')!.invalid" class="text-danger">
                Valid email is required.
              </div>
            </div>
          </div>
          <!-- Website -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="Website" class="form-label fw-semibold text-primary-light text-sm mb-8">Website <span class="text-danger-600">*</span></label>
              <input type="url" class="form-control radius-8" id="Website" formControlName="website" placeholder="Website URL">
              <div *ngIf="companyForm.get('website')!.touched && companyForm.get('website')!.invalid" class="text-danger">
                Valid website URL is required.
              </div>
            </div>
          </div>
          <!-- Country -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="country" class="form-label fw-semibold text-primary-light text-sm mb-8">Country</label>
              <div class="position-relative">
                <input type="text" class="form-control radius-8" formControlName="country" (input)="onCountrySearch($event)" placeholder="Search country" list="countryList">
                <datalist id="countryList">
                  <option *ngFor="let country of filteredCountries" [value]="country.name">{{country.name}}</option>
                </datalist>
              </div>
            </div>
          </div>
          <!-- City -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="city" class="form-label fw-semibold text-primary-light text-sm mb-8">City</label>
              <input type="text" id="city" class="form-control radius-8" formControlName="city" placeholder="Enter city">
            </div>
          </div>
          <!-- State/Province -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="state" class="form-label fw-semibold text-primary-light text-sm mb-8">State/Province</label>
              <input type="text" id="state" class="form-control radius-8" formControlName="state" placeholder="Enter state or province">
            </div>
          </div>
          <!-- Company Size -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="company_size" class="form-label fw-semibold text-primary-light text-sm mb-8">Company Size</label>
              <select class="form-control radius-8" id="company_size" formControlName="company_size">
                <option *ngFor="let company_size of companySizes" [value]="company_size">
                  {{ company_size }}
                </option>
              </select>
            </div>
          </div>
          <!-- Industry -->
          <div class="col-sm-6">
            <div class="mb-20">
              <label for="industry" class="form-label fw-semibold text-primary-light text-sm mb-8">Industry</label>
              <select class="form-control radius-8" id="industry" formControlName="industry">
                <option *ngFor="let industry of industries" [value]="industry">
                  {{ industry }}
                </option>
              </select>
            </div>
          </div>
          <!-- Address -->
          <div class="col-sm-12">
            <div class="mb-20">
              <label for="address" class="form-label fw-semibold text-primary-light text-sm mb-8">Address</label>
              <input type="text" class="form-control radius-8" id="address" formControlName="address" placeholder="Enter Your Address">
            </div>
          </div>
          <!-- Inline Success and Error Messages -->
          <div *ngIf="successMessage" class="alert alert-success mb-20">
            {{ successMessage }}
          </div>
            <div *ngIf="errorMessage" class="alert alert-danger mb-20">
              {{ errorMessage }}
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-24">
          <button type="button" [disabled]="!isFirstFormValid()" (click)="onNextButtonClick()" class="btn btn-primary text-md px-24 py-12 radius-8">Save</button>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
  
</div>
<app-footer></app-footer> 
</div>

